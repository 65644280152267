define("megaportal-frontend-uq-components/components/assessment-chart", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AssessmentChart = Ember.Component.extend({
    assessmentChart: '',
    //this will hold the assessment Chart object
    changeSegments: Ember.observer('results.@each.percentage', function () {
      //observes changes in results array being bubbled down from panel
      var assessmentChart = this.assessmentChart,
          //this points to the Chartjs objects
      segments = AssessmentChart.createSegments(this.results); //there will only ever be one dataset. select the first

      segments.datasets[0].data.forEach(function (segment, index) {
        assessmentChart.data.datasets[0].data[index] = segment; //Chartjs doesn't like you removing and adding datarows. Flip old data with new data sequentially
      });
      assessmentChart.update(); //updates values, reruns animation
    }),
    reDrawChart: Ember.observer('examdata', function () {
      //NOTE externalise this function, we call it more than once
      var APIData = this.results,
          //gets assessment being bubbled down from panel
      context = (0, _jquery.default)(".assessment-chart").get(0).getContext("2d"),
          //instanciates canvas API
      segments = AssessmentChart.createSegments(APIData); //uses the createSegments method to turn the APIdata into segmented data

      AssessmentChart.updateCenterPercentage('', this.percentage); //sets percentage for the next time canvas is rendered

      this.set('assessmentChart', AssessmentChart.createChart(context, segments)); //create chart object
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var APIData = this.results,
          //gets assessment being bubbled down from panel
      context = (0, _jquery.default)(".assessment-chart").get(0).getContext("2d"),
          //instanciates canvas API
      segments = AssessmentChart.createSegments(APIData); //uses the createSegments method to turn the APIdata into segmented data

      AssessmentChart.updateCenterPercentage('', this.percentage); //sets percentage for the next time canvas is rendered

      this.set('assessmentChart', AssessmentChart.createChart(context, segments)); //create chart object
    },
    percentageDidChange: Ember.observer('percentage', function () {
      AssessmentChart.updateCenterPercentage(this.assessmentChart, this.percentage); //updateCenterPercentage method from the Class extension

      this.assessmentChart.update(); //updates values, reruns animation
    })
  });
  AssessmentChart.reopenClass({
    //extend
    createChart: function createChart(context, segments) {
      //creates chart, is only called once on initial render
      Chart.defaults.global.legend.display = false; //disable legend

      var chartInstance = new Chart(context, {
        //new Chart object from Chart js
        type: 'doughnut',
        data: segments
      });
      return chartInstance;
    },
    updateCenterPercentage: function updateCenterPercentage(assessChart, percentage) {
      Chart.pluginService.register({
        //extend Chart properties
        beforeDraw: function beforeDraw(chart) {
          //beforeDraw is called before update method when canvas renders
          var width = chart.chart.width,
              height = chart.chart.height,
              ctx = chart.chart.ctx,
              //properties setup
          fontSize = (height / 114).toFixed(2),
              //get value in em
          textX = Math.round((width - ctx.measureText(percentage).width) / 2),
              textY = height / 2;
          ctx.font = fontSize + "em sans-serif"; //set default font properties

          ctx.textBaseline = "middle"; //centre value is based on exact centre posityion of canvas. Saves uneccessary maths using this
          //clear circle in doughnut

          ctx.beginPath();
          ctx.arc(width / 2, height / 2, assessChart.innerRadius, 0, 2 * Math.PI, false);
          ctx.fillStyle = '#fff';
          ctx.fill(); //filltext

          ctx.fillStyle = "#646464";
          ctx.fillText(percentage, textX, textY);
        }
      });
    },
    pushNewSegmentData: function pushNewSegmentData(assessmentData, label, value, bc, hbc) {
      //add a new entry to dataset
      var dataset = assessmentData.datasets[0];
      assessmentData.labels.push(label); //push into the segment data

      dataset.data.push(value);
      dataset.backgroundColor.push(bc);
      dataset.hoverBackgroundColor.push(hbc);
    },
    createSegments: function createSegments(data) {
      var assessmentData = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: []
        }]
      }; //this is what holds the final segments for the pie chart

      var marksLostTotal = 0,
          marksNotComplete = 0; //these are used to create a marks lost and incomplete assessment segment

      data.forEach(function (assessment) {
        //iterate over the JSON data from the API endpoint
        var marksLost, marksGained; //these modify the values for the marksLostTotal and marksNotComplete vars

        if (!assessment.percentage) {
          //checks for a null value in result. If there is one, add this assessments weighting to incomplete
          marksNotComplete += parseInt(assessment.weighting); //if the weighting is not null NOTE this is weird, getting some nullvalues for weightin

          AssessmentChart.pushNewSegmentData(assessmentData, assessment.assess_title, 0, '#49075e', '#FFA200');
        } else {
          if (assessment.weighting) {
            //NOTE this currently safeguards null values for weighting and ignores the assessment item, however why do we get null values?
            marksLost = (100 - assessment.percentage) * (parseInt(assessment.weighting) / 100); //calculates how much the student lost on this assess

            marksGained = assessment.percentage * (parseInt(assessment.weighting) / 100); //how much does this piece of assess contribute to the grade?

            marksLostTotal += marksLost; //add the marks Lost to the total

            AssessmentChart.pushNewSegmentData(assessmentData, assessment.assess_title, marksGained, '#49075e', '#FFA200');
          }
        }
      }); //create the lost and incomplete segments

      AssessmentChart.pushNewSegmentData(assessmentData, 'Marks Lost', marksLostTotal, '#eee', '#eee');
      AssessmentChart.pushNewSegmentData(assessmentData, 'Incomplete Assessment', marksNotComplete, '#fff', '#fff');
      return assessmentData;
    }
  });
  var _default = AssessmentChart;
  _exports.default = _default;
});