define("megaportal-frontend-uq-components/models/notifications", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Notifications = Ember.Object.extend({});
  Notifications.reopenClass({
    fetch: function fetch() {
      return _jquery.default.ajax('/api/notifications', {
        dataType: 'json'
      }).then(function (resp) {
        return resp.notifications;
      });
    },
    fetchById: function fetchById(id) {
      return _jquery.default.ajax('/api/notifications/' + id, {
        dataType: 'json'
      }).then(function (resp) {
        // Multiple attachments are presented as a comma separated string of file names. Convert this to an array of file names.
        var attachments = resp.attachment;

        if (attachments) {
          if (!Ember.isArray(attachments)) {
            attachments = attachments.split(',');
          } // For each attachment, trim whitespace and find the filename itself


          var attachmentHash;
          attachments = attachments.map(function (item) {
            attachmentHash = {};
            attachmentHash.pathFilename = item.trim();
            attachmentHash.filename = attachmentHash.pathFilename.split('/').pop();
            attachmentHash.filenameShort = attachmentHash.filename;

            if (attachmentHash.filenameShort.length > 26) {
              attachmentHash.filenameShort = attachmentHash.filenameShort.slice(0, 15) + '...' + attachmentHash.filenameShort.slice(-8);
            }

            return attachmentHash;
          });
          resp.attachment = attachments;
        }

        return resp;
      });
    }
  });
  var _default = Notifications;
  _exports.default = _default;
});