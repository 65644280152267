define("megaportal-frontend-uq-components/routes/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    notificationService: Ember.inject.service(),
    setupController: function setupController(controller, error) {
      //if we get a 401 or a 403, log user out of application
      if (error.status === 401 || error.status === 403) {
        try {
          this.session.invalidate({
            reason: 'expired'
          });
        } catch (e) {
          /**
           * Dodgy fix to ensure page gets reloaded when unauthenticated
           * correctly, otherwise news etc doesn't show.
           */
          window.location.reload(true);
        }
      }

      this.set('notificationService.loading', false);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});