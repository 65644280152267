define("megaportal-frontend-uq-components/templates/components/notification-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CiokBXdP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"panel \",[23,\"type\"],\" \",[25,[\"notification\",\"extraClass\"]]]]],[11,\"role\",\"alertdialog\"],[12,\"aria-live\",[30,[[23,\"ariaLive\"]]]],[12,\"aria-labelledby\",[30,[[23,\"alertDescription\"]]]],[12,\"aria-describedby\",[30,[[23,\"alertDescription\"]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[11,\"role\",\"document\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel__content\"],[12,\"id\",[30,[[23,\"alertDescription\"]]]],[9],[0,\"\\n      \"],[1,[25,[\"notification\",\"body\"]],false],[0,\"\\n\"],[4,\"unless\",[[25,[\"notification\",\"hideClose\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"class\",\"close-message icon icon-close\"],[11,\"title\",\"Close dialog\"],[11,\"aria-label\",\"Close dialog\"],[9],[3,\"action\",[[24,0,[]],\"close\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/notification-message.hbs"
    }
  });

  _exports.default = _default;
});