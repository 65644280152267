define("megaportal-frontend-uq-components/controllers/apps/course-cuttoffs/list", ["exports", "megaportal-frontend-uq-components/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    featureService: Ember.inject.service(),
    iframeUrl: "/travel-affected-students/list#".concat(_environment.default.JWT_TOKEN)
  });

  _exports.default = _default;
});