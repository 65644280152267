define("megaportal-frontend-uq-components/models/ims", ["exports", "jquery", "fetch", "megaportal-frontend-uq-components/config/environment"], function (_exports, _jquery, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Ims = Ember.Object.extend({});
  Ims.reopenClass({
    fetchImsToken: function fetchImsToken() {
      return _jquery.default.ajax('/api/ims/token', {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    },
    fetchActiveSessions: function fetchActiveSessions(token) {
      return (0, _fetch.default)("".concat(_environment.default.IMS_API_URL, "/sessions"), {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(function (response) {
        return response.json();
      }).catch(function () {
        return [];
      });
    },
    fetchSessionDurationPreference: function fetchSessionDurationPreference(token) {
      return (0, _fetch.default)("".concat(_environment.default.IMS_API_URL, "/pref"), {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(function (response) {
        return response.json();
      });
    },
    changeSessionDurationPreference: function changeSessionDurationPreference(token, duration) {
      // nasty code because the endpoint doesn't use the uid from the token
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace('-', '+').replace('_', '/');
      var payload = JSON.parse(window.atob(base64));
      return (0, _fetch.default)("".concat(_environment.default.IMS_API_URL, "/pref"), {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          uid: payload.uid,
          session_duration: duration
        })
      });
    },
    logoutOfActiveSession: function logoutOfActiveSession(token, sessionId) {
      return (0, _fetch.default)("".concat(_environment.default.IMS_API_URL, "/session/").concat(encodeURIComponent(sessionId)), {
        headers: {
          'Authorization': 'Bearer ' + token
        },
        method: 'DELETE'
      });
    }
  });
  var _default = Ims;
  _exports.default = _default;
});