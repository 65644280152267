define("megaportal-frontend-uq-components/routes/apps/my-requests/index", ["exports", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin", "megaportal-frontend-uq-components/models/unitask"], function (_exports, _myuqAuthenticatedRouteMixin, _unitask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    authManager: Ember.inject.service(),
    model: function model() {
      return this.authManager.waitForAccount().then(function () {
        return Ember.RSVP.hash({
          activeAccount: _unitask.default.checkActiveAccount()
        });
      });
    },
    getInactiveMailLink: function getInactiveMailLink() {
      var address = 'help@its.uq.edu.au';
      var subject = "Enable My Requests for ".concat(this.authManager.username, " ").concat(this.authManager.firstName, " ").concat(this.authManager.lastName);
      var body = "Hi IT Support,%0A" + "%0A" + "Please enable My Requests (UniTask) for my account: ".concat(this.authManager.username, ".%0A") + "%0A" + "Regards,%0A" + "%0A" + "".concat(this.authManager.firstName, " ").concat(this.authManager.lastName);
      return "mailto:".concat(address, "?subject=").concat(subject, "&body=").concat(body);
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);

      if (model.activeAccount.exception) {
        var status = model.activeAccount.exception;
        var exceptionObject = {
          status: status
        }; // Inactive account exception

        if (status === 'account-inactive') {
          exceptionObject['mailto'] = this.getInactiveMailLink();
        }

        throw exceptionObject;
      }
    }
  });

  _exports.default = _default;
});