define("megaportal-frontend-uq-components/models/exam-timetable", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ExamTimetable = Ember.Object.extend({});
  ExamTimetable.reopenClass({
    fetch: function fetch() {
      return _jquery.default.ajax('/api/exam_timetable', {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    }
  });
  var _default = ExamTimetable;
  _exports.default = _default;
});