define("megaportal-frontend-uq-components/routes/apps/my-requests/process/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var processName = _ref.processName;
      this.transitionTo('apps.my-requests.make-request.view', processName);
    }
  });

  _exports.default = _default;
});