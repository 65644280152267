define("megaportal-frontend-uq-components/models/favourite", ["exports", "jquery", "megaportal-frontend-uq-components/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Favourite = Ember.Object.extend({});
  Favourite.reopenClass({
    fetch: function fetch() {
      return _jquery.default.ajax(_environment.default.FAVOURITES_URL, {
        dataType: 'json'
      });
    },
    store: function store(data) {
      return _jquery.default.ajax({
        type: 'POST',
        url: _environment.default.FAVOURITES_URL,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: data
      });
    }
  });
  var _default = Favourite;
  _exports.default = _default;
});