define("megaportal-frontend-uq-components/templates/components/prompt-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cRaspi4f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"panel  panel--alert-error\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"panel__content\"],[9],[0,\"\\n          \"],[15,1],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/prompt-message.hbs"
    }
  });

  _exports.default = _default;
});