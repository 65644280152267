define("megaportal-frontend-uq-components/components/ftp-client", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ftpService: Ember.inject.service(),
    notificationService: Ember.inject.service(),
    //DOM setup
    tagName: 'div',
    classNames: ['ftp-client', 'ftp-client-light', 'row', 'unselectable'],
    classNameBindings: [],
    newDirectoryName: '',
    actions: {
      mkdir: function mkdir() {
        //we check here to make sure the user has actually set a foldername.
        if (this.newDirectoryName.length) {
          this.ftpService.mkdir(this.newDirectoryName);
        }
      },
      hideMkdir: function hideMkdir() {
        //changes prop of ftpService to hide mkdir entry
        this.ftpService.set('mkdirEntry', false);
      }
    },
    didRender: function didRender() {
      Ember.run(function () {
        if ((0, _jquery.default)(document).foundation) {
          (0, _jquery.default)(document).foundation('equalizer', 'reflow');
        }
      });
    }
  });

  _exports.default = _default;
});