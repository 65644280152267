define("megaportal-frontend-uq-components/controllers/apps/my-requests/index", ["exports", "jquery", "megaportal-frontend-uq-components/models/unitask"], function (_exports, _jquery, _unitask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    _cases: [],
    _lastViewFilter: null,
    viewFilter: 'active',
    relatedTo: 'all',
    itemsPerPage: 50,
    // Number of cases to display per page
    pageIndex: 1,
    // Number of pages to skip, changed by back/next buttons
    totalCases: 0,
    itemsPerPageOptions: [5, 20, 50, 100],
    viewFilterOptions: {
      'active': 'active',
      'completed': 'completed'
    },
    relatedToOptions: {
      'anyone': 'all',
      'me': 'me',
      'others': 'others'
    },
    authManager: Ember.inject.service(),
    notificationService: Ember.inject.service(),
    quickCache: {},
    init: function init() {
      // Initialise cases
      this.onViewChange();

      this._super();
    },
    onViewChange: Ember.observer('viewFilter', 'pageIndex', 'itemsPerPage', 'model', function () {
      var _this = this;

      if (!this.model) {
        // Cases should only be loaded once page is opened
        return;
      } // Set view to loading state and start retrieving cases


      if (this._lastViewFilter !== this.viewFilter) {
        this._lastViewFilter = this.viewFilter;

        if (this.pageIndex !== 1) {
          this.set('pageIndex', 1);
          return;
        }
      } else if (this.viewFilter === 'active') {
        return;
      }

      this.set('loading', true);
      this.set('_cases', []);
      var casesPromise;

      if (this.viewFilter === 'active') {
        casesPromise = _unitask.default.fetchActiveCases();
      } else if (this.viewFilter === 'completed') {
        casesPromise = _unitask.default.fetchArchivedCases(this.pageIndex, this.itemsPerPage);
      }

      casesPromise.then(function (cases) {
        var fullName = _this.get("authManager.fullName") || ''; // Change 'Related to' to "Me" when appropriate

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = cases.cases[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var currentCase = _step.value;
            var relatedTo = currentCase.relatedTo || '';

            if (fullName && relatedTo && (fullName.endsWith(relatedTo) || relatedTo.endsWith(fullName))) {
              currentCase.relatedTo = 'Me';
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        Ember.trySet(_this, '_cases', cases.cases);
        Ember.trySet(_this, 'totalCases', cases.totalCases);
        Ember.trySet(_this, 'loading', false);
      }).catch(function () {
        Ember.trySet(_this, '_cases', []);
        Ember.trySet(_this, 'totalCases', 0);
        Ember.trySet(_this, 'loading', false);
      }).finally(function () {
        // Reflow foundation after laypout changes
        setTimeout(function () {
          if ((0, _jquery.default)(document).foundation) {
            (0, _jquery.default)(document).foundation('equalizer', 'reflow');
          }
        }, 200);
      });
    }),
    sortedCases: Ember.computed('_cases', 'relatedTo', 'pageIndex', function () {
      //Filter related-to me/others/all
      var filteredCases;

      switch (this.relatedTo) {
        case 'me':
          filteredCases = this._cases.filterBy('relatedTo', 'Me');
          break;

        case 'others':
          filteredCases = this._cases.filter(function (item) {
            return item['relatedTo'] !== 'Me';
          });
          break;

        case 'all':
          filteredCases = this._cases;
          break;
      }
      /*
       -List actionable tasks first (where the link is "Update required").
       --Within this, sort with closest due date at the top.
       --Within this, if due date is the same, or if there is no due date, sort by requested date (including time stamp, not just the date that is displayed), oldest at the top.
       -List non-actionable tasks next
       --Within this, sort by requested date (including time stamp, not just the date that is displayed), newest at the top
       */


      var sortedCases = filteredCases.sort(function (a, b) {
        //If no tasks, sort by requested date
        if (Ember.isEmpty(a.task) && Ember.isEmpty(b.task)) {
          return Date.parse(Ember.get(b, "requestedDate")) - Date.parse(Ember.get(a, "requestedDate"));
        } else if (a.task === null) {
          return 1;
        } else if (b.task === null) {
          return -1;
        } //If both have tasks, sort by task due date if we have one, otherwise case due date.
        else if (!Ember.isEmpty(a.task) && !Ember.isEmpty(b.task)) {
            var aDueDate = Ember.isEmpty(a.task.dueDate) ? Ember.get(a, "dueDate") : Ember.get(a.task, "dueDate");
            var bDueDate = Ember.isEmpty(b.task.dueDate) ? Ember.get(b, "dueDate") : Ember.get(b.task, "dueDate"); //If due dates are the same, then fallback to requested dates..

            return Date.parse(aDueDate) - Date.parse(bDueDate) || Date.parse(Ember.get(a, "requestedDate")) - Date.parse(Ember.get(b, "requestedDate"));
          }
      });
      return sortedCases;
    }),
    pagedCases: Ember.computed('sortedCases', 'itemsPerPage', 'pageIndex', function () {
      if (!this.sortedCases) {
        return [];
      }

      if (this.viewFilter === 'completed') {
        return this.sortedCases;
      } // parseInt() required as the value is a string coming from an <option/>


      var itemsPerPage = parseInt(this.itemsPerPage, 10);
      var offset = itemsPerPage * (this.pageIndex - 1);

      if (!Array.isArray(this.sortedCases)) {
        return [];
      }

      return this.sortedCases.slice(offset, offset + itemsPerPage);
    }),
    sortedProcesses: Ember.computed('model.processes', function () {
      var processes = this.get("model.processes");

      if (_jquery.default.isEmptyObject(processes)) {
        return [];
      }

      var sortedProcesses = processes.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      return sortedProcesses;
    }),
    // Pagination: number of pages in total (1-based)
    pageCount: Ember.computed('totalCases', 'itemsPerPage', function () {
      var itemsPerPage = this.itemsPerPage;

      if (this.totalCases % itemsPerPage === 0 && this.totalCases !== 0) {
        return this.totalCases / itemsPerPage;
      }

      return Math.floor(this.totalCases / itemsPerPage) + 1;
    }),
    // Pagination: array of valid page numbers (1-based)
    // This is because the template can't do a for loop, only iterate over an
    // array, so we'll supply an array of valid page numbers.
    // The returned array is a list of boolean, true if that index is the current
    // page.
    allPageNumbers: Ember.computed('pageCount', 'pageIndex', function () {
      var pageIndex = this.pageIndex;
      var pageCount = this.pageCount;
      var pages = {};

      for (var i = 1; i <= pageCount; i++) {
        pages[i] = i === pageIndex;
      }

      return pages;
    }),
    // Pagination: true if this is the first page (to disable 'prev' link)
    isFirstPage: Ember.computed('pageIndex', function () {
      return this.pageIndex <= 1;
    }),
    // Pagination: true if this is the last page (to disable 'next' link)
    isLastPage: Ember.computed('pageIndex', 'pageCount', '_cases', function () {
      return this.pageIndex >= this.pageCount;
    }),
    actions: {
      setViewFilter: function setViewFilter(newViewFilter) {
        this.set('viewFilter', newViewFilter);
        this.send('jumpPage', 1);
      },
      setRelatedTo: function setRelatedTo(newRelatedTo) {
        this.set('relatedTo', newRelatedTo);
        this.send('jumpPage', 1);
      },
      // Change the number of items shown on each page
      setItemsPerPage: function setItemsPerPage(newValue) {
        this.set('itemsPerPage', parseInt(newValue, 10));
        this.send('jumpPage', 1);
      },
      // Show the next page of cases
      nextPage: function nextPage() {
        if (!this.isLastPage) {
          var offset = parseInt(this.pageIndex);
          this.send('jumpPage', offset + 1);
        }
      },
      // Show the previous page of cases
      prevPage: function prevPage() {
        var offset = parseInt(this.pageIndex);
        this.send('jumpPage', offset - 1);
      },
      // Go to a specific page
      jumpPage: function jumpPage(destPage) {
        destPage = Math.max(1, destPage);
        var limit = this.pageCount;
        destPage = Math.min(destPage, limit);
        this.set('pageIndex', destPage); // Adjust page height now there are more/fewer elements, but do it
        // 'next', after the DOM has finished being changed.

        Ember.run.next(function () {
          if ((0, _jquery.default)(document).foundation) {
            (0, _jquery.default)(document).foundation('equalizer', 'reflow');
          }
        });
      }
    }
  });

  _exports.default = _default;
});