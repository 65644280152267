define("megaportal-frontend-uq-components/helpers/years-and-months-from-now", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.yearsAndMonthsFromNow = yearsAndMonthsFromNow;
  _exports.default = void 0;

  function yearsAndMonthsFromNow(params
  /*, hash*/
  ) {
    var date = params[0];
    var years = -(0, _moment.default)(date).diff((0, _moment.default)(), 'years');
    var months = -(0, _moment.default)(date).add(years, 'years').diff((0, _moment.default)(), 'months');
    var returnString = years + " years";

    if (months === 1) {
      returnString += " and " + months + " month";
    } else if (months > 1) {
      returnString += " and " + months + " months";
    }

    return returnString;
  }

  var _default = Ember.Helper.helper(yearsAndMonthsFromNow);

  _exports.default = _default;
});