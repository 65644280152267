define("megaportal-frontend-uq-components/initializers/add-to-homescreen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /*application*/
  {
    /*
    window.addToHomescreen(
     {
       debug: false,
       startDelay: 1,
       //displayPace: 1440,
       lifespan:0,
       maxDisplayCount: 1,
       skipFirstVisit: true
     }
    );
    */
  }

  var _default = {
    name: 'add-to-homescreen',
    initialize: initialize
  };
  _exports.default = _default;
});