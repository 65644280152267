define("megaportal-frontend-uq-components/services/identity-service", ["exports", "fetch", "megaportal-frontend-uq-components/domain/MultiFactorAuthenticationStatus"], function (_exports, _fetch, _MultiFactorAuthenticationStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authManager: Ember.inject.service(),
    configService: Ember.inject.service(),
    session: Ember.inject.service(),
    multiFactorAuthenticationStatus: null,
    maskEmail: function maskEmail(value) {
      var local = value.split('@')[0];
      var host = value.split('@')[1];
      var masked = local.slice(0, 3) + new Array(Math.max(0, local.length - 3 + 1)).join('*') + "@".concat(host);
      return masked;
    },
    maskMobile: function maskMobile(value) {
      var masked = new Array(Math.max(0, value.length - 4 + 1)).join('*') + value.slice(-4);
      return masked;
    },
    getUserDetails: function getUserDetails() {
      var _this = this;

      return this.authManager.waitForAccount().then(function () {
        if (_this.userDetails) {
          return _this.userDetails;
        }

        var detailsPromise;
        var preferredNamesPromise;

        if (_this.authManager.isStaff) {
          detailsPromise = (0, _fetch.default)(_this.configService.IDENTITY_URL + "/staff", {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer ".concat(_this.session.session.content.authenticated.token)
            }
          }).then(function (response) {
            // Could not find staff user (this is a hack to get required details [couldn't find DOB])
            if (response.status !== 200) {
              return {
                staff_email_address: _this.authManager.emailAddress,
                display_name: "".concat(_this.authManager.firstName, " ").concat(_this.authManager.lastName),
                first_name: _this.authManager.firstName,
                last_name: _this.authManager.lastName,
                date_of_birth: null
              };
            }

            return response.json();
          });
        } else if (_this.authManager.isStudent) {
          detailsPromise = (0, _fetch.default)(_this.configService.IDENTITY_URL + "/student", {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer ".concat(_this.session.session.content.authenticated.token)
            }
          }).then(function (response) {
            // Couldn't find student user (hack to get details [couldn't find DOB or is_international_student])
            if (response.status !== 200) {
              return {
                student_email_address: _this.authManager.emailAddress,
                display_name: "".concat(_this.authManager.firstName, " ").concat(_this.authManager.lastName),
                first_name: _this.authManager.firstName,
                last_name: _this.authManager.lastName,
                date_of_birth: null,
                student_id: _this.authManager.employeeNumber,
                is_international_student: null
              };
            }

            return response.json();
          });
          preferredNamesPromise = (0, _fetch.default)(_this.configService.IDENTITY_URL + "/names/".concat(_this.authManager.employeeNumber), {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer ".concat(_this.session.session.content.authenticated.token)
            }
          }).then(function (nameResponse) {
            if (nameResponse.status !== 200) {
              return null;
            }

            return nameResponse.json().then(function (nameData) {
              var _iteratorNormalCompletion = true;
              var _didIteratorError = false;
              var _iteratorError = undefined;

              try {
                for (var _iterator = nameData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                  var name = _step.value;

                  if (name.name_type_code === 'PRF') {
                    return name;
                  }
                }
              } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                  }
                } finally {
                  if (_didIteratorError) {
                    throw _iteratorError;
                  }
                }
              }

              return null;
            });
          });
        }

        return detailsPromise.then(function (userDetails) {
          if (preferredNamesPromise) {
            return preferredNamesPromise.then(function (nameData) {
              if (nameData) {
                Object.assign(userDetails, nameData);
              }

              _this.set('userDetails', userDetails);

              return userDetails;
            });
          }

          _this.set('userDetails', userDetails);

          return userDetails;
        });
      });
    },
    getContactDetails: function getContactDetails() {
      var _this2 = this;

      if (this.contactDetails) {
        return this.contactDetails;
      }

      var contactDetailsPromise = (0, _fetch.default)(this.configService.IDENTITY_URL + "/contact-details", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.session.session.content.authenticated.token)
        }
      }).then(function (response) {
        return response.json();
      });
      return contactDetailsPromise.then(function (contactDetails) {
        _this2.set('contactDetails', contactDetails);

        return contactDetails;
      });
    },
    getQuestionGroups: function getQuestionGroups() {
      var _this3 = this;

      if (this.questionGroups) {
        return this.questionGroups;
      }

      var questionGroupsPromise = (0, _fetch.default)(this.configService.IDENTITY_URL + "/security-questions/get-questions", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.session.session.content.authenticated.token)
        }
      }).then(function (response) {
        return response.json();
      });
      return questionGroupsPromise.then(function (questionGroups) {
        _this3.set('questionGroups', questionGroups);

        return questionGroups;
      });
    },
    getPasswordDetails: function getPasswordDetails() {
      var _this4 = this;

      if (this.passwordDetails) {
        return this.passwordDetails;
      }

      var passwordDetailsPromise = (0, _fetch.default)(this.configService.IDENTITY_URL + "/password-details", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.session.session.content.authenticated.token)
        }
      }).then(function (response) {
        return response.json();
      });
      return passwordDetailsPromise.then(function (passwordDetails) {
        _this4.set('passwordDetails', passwordDetails);

        return passwordDetails;
      });
    },
    getQuestionDetails: function getQuestionDetails() {
      var _this5 = this;

      if (this.questionDetails) {
        return this.questionDetails;
      }

      var questionDetailsPromise = (0, _fetch.default)(this.configService.IDENTITY_URL + "/security-questions/details", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.session.session.content.authenticated.token)
        }
      }).then(function (response) {
        return response.json();
      });
      return questionDetailsPromise.then(function (questionDetails) {
        _this5.set('questionDetails', questionDetails);

        return questionDetails;
      });
    },
    getMultiFactorAuthenticationStatus: function getMultiFactorAuthenticationStatus() {
      var _this6 = this;

      if (this.multiFactorAuthenticationStatus) {
        return this.multiFactorAuthenticationStatus;
      }

      var multiFactorAuthenticationStatusPromise = (0, _fetch.default)(this.configService.IDENTITY_URL + "/multi-factor-authentication/status", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.session.session.content.authenticated.token)
        }
      }).then(function (response) {
        return response.json();
      });
      return multiFactorAuthenticationStatusPromise.then(function (responseData) {
        var multiFactorAuthenticationStatus = _MultiFactorAuthenticationStatus.default.create(responseData);

        _this6.set('multiFactorAuthenticationStatus', multiFactorAuthenticationStatus);

        return multiFactorAuthenticationStatus;
      });
    },
    // Get important information from contactDetails
    // First saved email address
    emailDetail: Ember.computed('contactDetails', function () {
      if (!this.contactDetails) {
        return false;
      }

      return this.contactDetails // Sort newest contact details to top of array
      .sort(function (contactA, contactB) {
        var timeA = contactA.verification_timestamp;
        var timeB = contactB.verification_timestamp;
        return timeA === timeB ? 0 : timeA > timeB ? -1 : 1;
      }) // Find first verified contact
      .find(function (contactDetail) {
        return contactDetail.contact_detail_type === 'Email' && contactDetail.contact_detail_subtype === 'Home' && contactDetail.is_verified === true && contactDetail.is_active === true;
      });
    }),
    emailAddress: Ember.computed('emailDetail', 'newEmailAddress', {
      get: function get() {
        if (!this.emailDetail) {
          return false;
        }

        var value = this.newEmailAddress ? this.newEmailAddress : this.emailDetail.contact_detail_value;
        return this.maskEmail(value);
      },
      set: function set(key, value) {
        this.set('newEmailAddress', value);
        return this.maskEmail(value);
      }
    }),
    emailVerified: Ember.computed('emailDetail', function () {
      if (!this.emailDetail) {
        return false;
      }

      return true;
    }),
    mobileDetail: Ember.computed('contactDetails', function () {
      return this.contactDetails // Sort newest contact details to top of array
      .sort(function (contactA, contactB) {
        var timeA = contactA.verification_timestamp;
        var timeB = contactB.verification_timestamp;
        return timeA === timeB ? 0 : timeA > timeB ? -1 : 1;
      }).find(function (contactDetail) {
        return contactDetail.contact_detail_type === 'Phone' && contactDetail.contact_detail_subtype === 'Mobile' && contactDetail.is_verified === true && contactDetail.is_active === true;
      });
    }),
    mobileNumber: Ember.computed('mobileDetail', 'newMobileNumber', {
      get: function get() {
        if (!this.mobileDetail) {
          return false;
        }

        var value = this.newMobileNumber ? this.newMobileNumber : this.mobileDetail.contact_detail_value;
        return this.maskMobile(value);
      },
      set: function set(key, value) {
        this.set('newMobileNumber', value);
        return this.maskMobile(value);
      }
    }),
    mobileVerified: Ember.computed('mobileDetail', function () {
      if (!this.mobileDetail) {
        return false;
      }

      return true;
    }),
    newContactVerificationRequest: function newContactVerificationRequest(type, subtype, value) {
      return (0, _fetch.default)(this.configService.IDENTITY_URL + "/contact-details-verification/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.session.session.content.authenticated.token)
        },
        body: JSON.stringify({
          contact_detail_type: type,
          contact_detail_subtype: subtype,
          contact_detail_value: value
        })
      }).then(function (response) {
        return response.json();
      }).then(function (response) {
        var detailedMessage = '';

        if (response.data && response.data[0] && response.data[0].messages && response.data[0].messages[0]) {
          // This is just for the verificationCode error, if you need to include other types of errors pls feel free
          // to refactor
          if (response.data[0].name = 'verificationCode') {
            detailedMessage = response.data[0].messages[0];
          }
        }

        return {
          status: response.status === true,
          message: response.message,
          detailedMessage: detailedMessage
        };
      });
    },
    confirmContactVerificationRequest: function confirmContactVerificationRequest(type, subtype, value, verificationCode) {
      return (0, _fetch.default)(this.configService.IDENTITY_URL + "/contact-details/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.session.session.content.authenticated.token)
        },
        body: JSON.stringify({
          contact_detail_type: type,
          contact_detail_subtype: subtype,
          contact_detail_value: value,
          verification_code: verificationCode,
          source_system: 'MY_UQ',
          request_type: 'MYUQ_WITH_VERIFICATION'
        })
      }).then(function (response) {
        return response.json();
      }).then(function (response) {
        return response.code === 201;
      });
    },
    newSecurityAnswers: function newSecurityAnswers(answers) {
      var _this7 = this;

      return (0, _fetch.default)(this.configService.IDENTITY_URL + "/security-questions/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.session.session.content.authenticated.token)
        },
        body: JSON.stringify(answers)
      }).then(function (response) {
        return response.json();
      }).then(function (response) {
        // Cache new answers (so my profile page shows correct info before API cache is updated)
        _this7.set('questionDetails', {
          answered_security_questions_date: Date.now(),
          has_answered_security_questions: true
        });

        return response.status === true && !response.errorCode && !response.code;
      });
    }
  });

  _exports.default = _default;
});