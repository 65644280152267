define("megaportal-frontend-uq-components/helpers/format-short-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatShortCurrency = formatShortCurrency;
  _exports.default = void 0;

  function formatShortCurrency(params
  /*, hash*/
  ) {
    var amount = params[0];

    if (amount % 1 === 0) {
      return Math.floor(amount);
    } else {
      return amount;
    }
  }

  var _default = Ember.Helper.helper(formatShortCurrency);

  _exports.default = _default;
});