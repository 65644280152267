define("megaportal-frontend-uq-components/routes/apps/my-requests/make-request/index", ["exports", "jquery", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin", "megaportal-frontend-uq-components/models/unitask", "megaportal-frontend-uq-components/config/environment"], function (_exports, _jquery, _myuqAuthenticatedRouteMixin, _unitask, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    authManager: Ember.inject.service(),
    featureService: Ember.inject.service(),
    model: function model() {
      return this.authManager.waitForAccount().then(function () {
        return Ember.RSVP.hash({
          processes: _unitask.default.fetchProcesses()
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);

      var processes = model.processes; // Encode process url

      if (Array.isArray(processes)) {
        processes.forEach(function (process) {
          process.encodedName = encodeURI(process.name);
        });
      }

      if (this.get('authManager.isStudent') && _environment.default.SINET_URL && !_environment.default.SINET_URL.startsWith('{{')) {
        processes.push({
          id: "external-request",
          name: "Deferred examinations request in mySI-net. This request will not be visible in My Requests",
          title: "Deferred examinations request in mySI-net. This request will not be visible in My Requests",
          // formUrl: config.SINET_URL + '?page=deferredExamRequest'
          formUrl: _environment.default.SINET_URL + '/psc/ps/EMPLOYEE/SA/c/NUI_FRAMEWORK.PT_LANDINGPAGE.GBL?targetid=deferredExamRequest'
        });
      }

      if (this.get('authManager.isStudent') && _environment.default.SINET_URL && !_environment.default.SINET_URL.startsWith('{{')) {
        processes.push({
          id: "external-request",
          name: "Supplementary assessment request in mySI-net. This request will not be visible in My Requests",
          title: "Supplementary assessment request in mySI-net. This request will not be visible in My Requests",
          // formUrl: config.SINET_URL + '?page=suppAssessment'
          formUrl: _environment.default.SINET_URL + '/psc/ps/EMPLOYEE/SA/c/NUI_FRAMEWORK.PT_LANDINGPAGE.GBL?targetid=suppAssessment'
        });
      }

      if (this.get('authManager.isStudent') && this.get('authManager.enrolmentType') === 'PGRS' && _environment.default.WORKDAY_URL && !_environment.default.WORKDAY_URL.startsWith('{{')) {
        processes.push({
          id: "external-request",
          name: "HDR Request for Leave in Workday. This request will not be visible in My Requests",
          title: "HDR Request for Leave in Workday. This request will not be visible in My Requests",
          formUrl: _environment.default.WORKDAY_URL
        });
      }

      processes = processes.sort(function (a, b) {
        return a.title.localeCompare(b.title);
      });
    },
    //  Activate is called each time the router lands on this route.
    //  We schedule once to focus the search input afterRender
    //  because sometimes the renderer hasn't rendered the
    //  element yet.
    activate: function activate() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        return (0, _jquery.default)('div.make-request__search input').focus();
      });
    }
  });

  _exports.default = _default;
});