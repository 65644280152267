define("megaportal-frontend-uq-components/models/semester", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    beginDate: _emberData.default.attr('string'),
    endDate: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    termCategoryCode: _emberData.default.attr('string'),
    termCategoryTitle: _emberData.default.attr('string'),
    isCurrentTerm: _emberData.default.attr('boolean'),
    hasCourses: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});