define("megaportal-frontend-uq-components/templates/components/ftp-client-delete-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xjyrGurM",
    "block": "{\"symbols\":[\"entry\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"delete-panel\"],[9],[0,\"\\n\\t\"],[7,\"a\"],[11,\"class\",\"close icon icon-close\"],[9],[3,\"action\",[[24,0,[]],\"cancel\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n\\t\"],[7,\"h3\"],[9],[0,\"Delete entries?\"],[10],[0,\"\\n\\t\"],[7,\"p\"],[9],[0,\"\\n\\t\"],[1,[23,\"promptTitle\"],false],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"ftpService\",\"currentSelectedEntries\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"p\"],[9],[7,\"a\"],[12,\"class\",[30,[\" successful-entry icon icon-\",[24,1,[\"entryType\"]],\" icon--linked\"]]],[9],[10],[1,[24,1,[\"entryName\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[7,\"button\"],[11,\"class\",\"button button--tertiary\"],[9],[0,\"Delete\"],[3,\"action\",[[24,0,[]],\"delete\"]],[10],[7,\"button\"],[11,\"class\",\"button button-secondary\"],[9],[0,\"Cancel\"],[3,\"action\",[[24,0,[]],\"cancel\"]],[10],[0,\"\\n\\t\\n\\t\\n\"],[10],[0,\"\\n\\n\"],[15,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/ftp-client-delete-modal.hbs"
    }
  });

  _exports.default = _default;
});