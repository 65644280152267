define("megaportal-frontend-uq-components/controllers/apps/staff-courses/index", ["exports", "jquery", "d3-collection"], function (_exports, _jquery, _d3Collection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    groupedCourses: [],
    actions: {
      selectCourse: function selectCourse(termCode, courseCode) {
        var selectedCourse = this.groupedCourses.find(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 1),
              course = _ref2[0];

          return course.term_code === termCode;
        }).find(function (course) {
          return course.course_code === courseCode;
        });
        Ember.set(selectedCourse, 'isSelected', !selectedCourse.isSelected);
      }
    },
    updateGroupedCourses: Ember.observer('model.courses', function () {
      var modelCourses = this.get("model.courses");

      if (!Array.isArray(modelCourses.courses)) {
        return [];
      }

      var ungroupedCourses = modelCourses.courses;
      var nested = (0, _d3Collection.nest)() // group by term (e.g. 'Semester 1, 2021')
      .key(function (course) {
        return course.term_title;
      }) // There may also be duplicated listings for course modes (e.g. 'internal' vs 'external')
      .key(function (course) {
        return course.course_code;
      }) // Since there may be duplicated courses if the user has multiple roles in a course.
      .key(function (course) {
        return course.scheduled_course_id;
      }) // add data
      .object(_toConsumableArray(ungroupedCourses));

      var combineCourses = function combineCourses(course1, course2) {
        var onlyUnique = function onlyUnique(myArr) {
          return Array.from(new Set(myArr)).filter(function (x) {
            return x !== undefined;
          });
        };

        return Object.assign({}, course1, course2, {
          course_contributor_ids: onlyUnique((course1.course_contributor_ids || [course1.course_contributor_id]).concat(course2.course_contributor_ids || [course2.course_contributor_id])),
          course_contributor_id: undefined,
          staff_type_titles: onlyUnique((course1.staff_type_titles || [course1.staff_type_title]).concat(course2.staff_type_titles || [course2.staff_type_title])),
          staff_type_title: undefined,
          contributor_types: onlyUnique((course1.contributor_types || [course1.contributor_type]).concat(course2.contributor_types || [course2.contributor_type])),
          contributor_type: undefined,
          scheduled_course_ids: onlyUnique((course1.scheduled_course_ids || [course1.scheduled_course_id]).concat(course2.scheduled_course_ids || [course2.scheduled_course_id])),
          scheduled_course_id: undefined
        });
      };

      var rollupUp = Object.values(nested).map(function (terms) {
        return Object.values(terms).map(function (courseCodes) {
          return Object.values(courseCodes).map(function (scheduledCourses) {
            return scheduledCourses.reduce(combineCourses, {});
          }).reduce(combineCourses, {});
        });
      }).sort(function (a, b) {
        return b[0].term_code - a[0].term_code;
      }); // enable preloading for the first term

      var _rollupUp = _slicedToArray(rollupUp, 1),
          firstTerm = _rollupUp[0];

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = firstTerm[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var course = _step.value;
          course.preLoad = true;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      Ember.set(this, "groupedCourses", rollupUp); //Do a reflow

      Ember.run.scheduleOnce('afterRender', this, function () {
        if ((0, _jquery.default)(document).foundation) {
          (0, _jquery.default)(document).foundation('equalizer', 'reflow');
        }
      });
    })
  });

  _exports.default = _default;
});