define("megaportal-frontend-uq-components/routes/apps/myfiles/index", ["exports", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin"], function (_exports, _myuqAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    ftpService: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var currentToken = localStorage.getItem('token');

      if (currentToken) {
        if (currentToken.token && currentToken.exp > Date.now() / 1000) {
          this.ftpService.setToken(currentToken.token);
        }
      }

      if (!this.ftpService.get('token')) {
        this.transitionTo('apps.myfiles.login');
      }
    },
    actions: {
      didTransition: function didTransition() {
        this.ftpService.fetchByPath();
      }
    }
  });

  _exports.default = _default;
});