define("megaportal-frontend-uq-components/components/calendar-view/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.viewTypes = _exports.dayTitles = _exports.timeGutterIndictators = void 0;
  var timeGutterIndictators = [{
    label: "7:00AM",
    value: 700
  }, {
    label: "8:00AM",
    value: 800
  }, {
    label: "9:00AM",
    value: 900
  }, {
    label: "10:00AM",
    value: 1000
  }, {
    label: "11:00AM",
    value: 1100
  }, {
    label: "12:00PM",
    value: 1200
  }, {
    label: "1:00PM",
    value: 1300
  }, {
    label: "2:00PM",
    value: 1400
  }, {
    label: "3:00PM",
    value: 1500
  }, {
    label: "4:00PM",
    value: 1600
  }, {
    label: "5:00PM",
    value: 1700
  }, {
    label: "6:00PM",
    value: 1800
  }, {
    label: "7:00PM",
    value: 1900
  }];
  _exports.timeGutterIndictators = timeGutterIndictators;
  var dayTitles = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  _exports.dayTitles = dayTitles;
  var viewTypes = [{
    type: "desktop",
    flexSpacer: true,
    className: "month-header"
  }, {
    type: "tablet",
    flexSpacer: true,
    className: "month-header--tablet"
  }, {
    type: "mobile",
    flexSpacer: false,
    className: "month-header--mobile"
  }];
  _exports.viewTypes = viewTypes;
});