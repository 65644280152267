define("megaportal-frontend-uq-components/routes/apps/settings/features", ["exports", "jquery", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin", "megaportal-frontend-uq-components/models/version"], function (_exports, _jquery, _myuqAuthenticatedRouteMixin, _version) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    authManager: Ember.inject.service(),
    featureService: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return this.authManager.waitForAccount().then(function () {
        return Ember.RSVP.hash({
          version: _version.default.fetch(),
          account: _this.authManager.accountData,
          features: _this.featureService.features,
          availableFeatures: _this.featureService.availableFeatures
        });
      });
    },
    availableFeaturesChangeObserver: Ember.observer('model.availableFeatures', function () {
      this.reflow();
    }),
    afterModel: function afterModel() {
      this.reflow();
    },
    reflow: function reflow() {
      var _this2 = this;

      setTimeout(function () {
        Ember.run.scheduleOnce('afterRender', _this2, function () {
          if ((0, _jquery.default)(document).foundation) {
            (0, _jquery.default)(document).foundation('equalizer', 'reflow');
          }
        });
      }, 1000);
    },
    actions: {
      doReflow: function doReflow() {
        this.reflow();
      }
    }
  });

  _exports.default = _default;
});