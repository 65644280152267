define("megaportal-frontend-uq-components/services/favourite-service", ["exports", "jquery", "megaportal-frontend-uq-components/models/favourite", "megaportal-frontend-uq-components/links"], function (_exports, _jquery, _favourite, _links) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    authManager: Ember.inject.service(),
    featureService: Ember.inject.service(),
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),

    /**
     * Is fetching favourites from API endpoint?
     */
    isFetching: false,

    /**
     * A serialized array of favourite link keys
     */
    serialized: null,

    /**
     * Display email landing page for Microsoft 365 login issue
     */
    useNewOffice365Login: Ember.computed('authManager.mailService', function () {
      var authManager = this.authManager;
      return authManager.get('mailService') === 'EL';
    }),

    /**
     * An array of favourite link objects
     */
    favourites: Ember.computed('links.@each', function () {
      var _this = this;

      var links = this.links;
      var serialized = this.serialized;
      var arr = []; // Display saved favourites

      if (serialized && serialized.length > 0) {
        // remove the ones moved to my.UQ
        serialized = this.removeMovedToMyUQ(serialized);
        serialized.forEach(function (key) {
          if (typeof _links.Links[key] !== 'undefined') {
            // Don't reload a favourite if it has since been hidden by a feature flag
            if (_links.Links[key].featureFlag) {
              var feature = _this.featureService.availableFeatures[_links.Links[key].featureFlag];

              if (typeof feature === 'undefined' || feature.enabled !== true) {
                // do not include link if not enabled in feature service
                return;
              }
            }

            arr.push(_links.Links[key]);
          }
        }); // Else, display default favourite links
      } else {
        var linkKeys = [];
        links.forEach(function (link) {
          if (link.isFavourite) {
            arr.push(link);
            linkKeys.push(link.key);
          }
        });

        if (linkKeys.length > 0) {
          this.set('serialized', linkKeys);
        }
      }

      this.reflow();
      return arr;
    }),

    /**
     * An array of all link objects
     */
    links: Ember.computed('serialized.@each', 'isFetching', 'authManager.accountData', function () {
      var _this2 = this;

      var featureService = this.featureService;
      var serialized = this.serialized;

      var _this$getDefaultLinkK = this.getDefaultLinkKeys(),
          keys = _this$getDefaultLinkK.keys,
          defaultName = _this$getDefaultLinkK.defaultName;

      var arr = [];
      keys.forEach(function (key) {
        // create Ember object for link so isFavourite can be observed
        var link = Ember.Object.create(_links.Links[key]);
        link.key = key;
        /**
         * Only display features that have been enabled
         */

        if (link.featureFlag) {
          var feature = featureService.availableFeatures[link.featureFlag];

          if (typeof feature === 'undefined' || feature.enabled !== true) {
            // do not include link if not enabled in feature service
            return;
          }
        }
        /**
         * Only display features that have been enabled
         */


        if (link.featureFlag) {
          var _feature = featureService.availableFeatures[link.featureFlag];

          if (typeof _feature === 'undefined' || _feature.enabled !== true) {
            // do not include link if not enabled in feature service
            return;
          }
        }
        /**
         * Set favourite links only if finished fetching
         */


        var session = _this2.session.session;

        if (!_this2.isFetching && (_this2.authManager.accountData || !session || !session.isAuthenticated)) {
          // saved favourites
          if (serialized && serialized instanceof Array) {
            link.set('isFavourite', serialized.indexOf(link.key) > -1);
          } else if (typeof link !== 'undefined' && (typeof link[defaultName] !== 'undefined' || link.roleDefault)) {
            // default favourites
            link.set('isFavourite', link[defaultName] || link.roleDefault && _this2.authManager.hasRole(link.roleDefault));
          }
        }

        arr.push(link);
      });
      return arr;
    }),

    /**
     * Checks if the favourite has been moved to the main list of apps under the
     * my.UQ section in the sidebar.
     *
     */
    removeMovedToMyUQ: function removeMovedToMyUQ(serialized) {
      var _this3 = this;

      var updatedSerialized = serialized.filter(function (key) {
        // console.log("### movedToMyUQ: ", key);
        var hasBeenRemovedFromStaffLinks = key === 'email-staff' || key === 'office365';

        if (_this3.authManager.isStaff && hasBeenRemovedFromStaffLinks) {
          _this3.toggleFavourite(key); //remove from favourites and do not return the key, filter it out.

        } else {
          return key;
        }
      });
      return updatedSerialized;
    },

    /**
     * An alphabetically sorted array of all link objects
     */
    sortedLinks: Ember.computed('links', function () {
      // Return sorted copy of links array (copy first so we don't mutate original array)
      return this.links.slice(0).sort(function (linkA, linkB) {
        return linkA.name.localeCompare(linkB.name);
      });
    }),

    /**
     * Get a default list of link keys
     * @returns {*}
     */
    getDefaultLinkKeys: function getDefaultLinkKeys() {
      var authManager = this.authManager;
      var session = this.session.session;
      var keys, defaultName; // Select list of links to display

      if (!session || !session.isAuthenticated) {
        keys = _links.UnauthenticatedLinks;
        defaultName = 'isUnauthenticatedDefault';
      } else if (authManager.isStudent) {
        keys = _links.StudentLinks;
        keys = this.isHDRStudent() ? keys.concat(_links.HDRStudentLinks) : keys;
        defaultName = 'isStudentDefault';
      } else {
        keys = _links.StaffLinks;
        defaultName = 'isStaffDefault';
      } // Get default links for user's roles


      for (var linkName in _links.Links) {
        var link = _links.Links[linkName];

        if (link.roleDefault && this.authManager.hasRole(link.roleDefault)) {
          if (!keys.includes(linkName)) {
            keys.push(linkName);
          }
        }
      }

      return {
        keys: keys,
        defaultName: defaultName
      };
    },
    isHDRStudent: function isHDRStudent() {
      var enrolmentType = this.authManager.enrolmentType;
      return enrolmentType && enrolmentType === this.authManager.HDRStudentProgramCareerCode;
    },

    /**
     * Backward compatibility load for old serialization
     * @param data
     * @returns {*}
     */
    loadBackwardCompatibility: function loadBackwardCompatibility(data) {
      if (data instanceof Array && data.length > 0 && typeof data[0].name !== 'undefined') {
        var links = [];
        data.forEach(function (item) {
          if (item instanceof Object && typeof item.name !== 'undefined') {
            for (var property in _links.Links) {
              if (_links.Links.hasOwnProperty(property)) {
                if (_links.Links[property].name === item.name) {
                  links.push(property);
                  break;
                }
              }
            }
          }
        });
        return links;
      }
    },
    newDefaultKeys: function newDefaultKeys(savedTimestamp) {
      var _this4 = this;

      // Link was added after saved timestamp
      // and link should be default for the given user
      return Object.keys(_links.Links).filter(function (linkName) {
        var link = _links.Links[linkName];
        return link.addedOn && link.addedOn * 1000 > savedTimestamp && ( // Convert addedOn from Unix timestamp to JS
        link.isStudentDefault && _this4.authManager.isStudent || link.isStaffDefault && _this4.authManager.isStaff || link.roleDefault && _this4.authManager.hasRole(link.roleDefault));
      });
    },

    /**
     * Load favourites, triggered when authManager.authenticate() is successful
     */
    load: function load() {
      var _this5 = this;

      this.authManager.waitForAccount().then(function () {
        if (_this5.isAuthenticated && _this5.isFetching === false) {
          _this5.set('isFetching', true);

          _favourite.default.fetch().then(function (response) {
            var serialized = _this5.serialized;

            if (serialized && serialized.length > 0) {
              serialized.clear();
            }

            if (response['favourites']) {
              var data = JSON.parse(response['favourites']);

              if (data && data instanceof Array) {
                try {
                  data = _this5.loadBackwardCompatibility(data) || data; // Add newly added favourites

                  var savedTimestamp = response['timestamp'] || 0;
                  data = data.concat(_this5.newDefaultKeys(savedTimestamp));
                } catch (e) {
                  // if error with backward compatibility load
                  var _this5$getDefaultLink = _this5.getDefaultLinkKeys(),
                      keys = _this5$getDefaultLink.keys;

                  data = keys;
                }

                _this5.set('serialized', data);
              }
            }

            _this5.set('isFetching', false);
          }).fail(function () {
            _this5.set('isFetching', false);
          });
        }
      });
    },

    /**
     * Add or remove (toggle) favourites by link key
     * @param key
     */
    toggleFavourite: function toggleFavourite(key) {
      var serialized = this.serialized; // if not returning valid data, do not save and try to load again

      if (serialized instanceof Array) {
        if (serialized.indexOf(key) > -1) {
          serialized.removeObject(key);
        } else {
          serialized.unshiftObject(key);
        }

        _favourite.default.store(JSON.stringify(serialized));
      } else {
        if (!this.isFetching) {
          this.load();
        }
      }
    },

    /**
     * Reflow equalizer when adding/removing favourites
     */
    reflow: function reflow() {
      Ember.run.scheduleOnce("afterRender", function () {
        if ((0, _jquery.default)(document).foundation) {
          (0, _jquery.default)(document).foundation('equalizer', 'reflow');
        }
      });
    }
  });

  _exports.default = _default;
});