define("megaportal-frontend-uq-components/components/apps-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authManager: Ember.inject.service(),
    transitionHistoryService: Ember.inject.service(),
    iconClasses: '',
    headlinePage: false,
    actions: {
      historyBack: function historyBack() {
        this.transitionHistoryService.transitionToPreviousRoute();
      },
      login: function login() {
        if (typeof Storage !== 'undefined') {
          sessionStorage.removeItem('routeName');
        }

        this.authManager.login();
      }
    }
  });

  _exports.default = _default;
});