define("megaportal-frontend-uq-components/templates/apps/course-cuttoffs/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NiDVzQxp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"apps-header\",null,[[\"iconClasses\"],[\"icon icon-courses icon--prefix\"]],{\"statements\":[[0,\"Courses\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row small-collapse medium-collapse large-collapse\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"large-12 xlarge-8 column\"],[9],[0,\"\\n  \\t\"],[7,\"div\"],[11,\"class\",\"section\"],[9],[0,\"\\n  \\t\\t\"],[7,\"div\"],[11,\"class\",\"section__header\"],[9],[0,\"\\n  \\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"column large-12\"],[9],[0,\"\\n  \\t\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"breadcrumb-trail\"],[9],[0,\"\\n  \\t\\t\\t\\t\\t\\t\"],[7,\"span\"],[11,\"class\",\"breadcrumb\"],[9],[4,\"link-to\",[\"apps.courses\"],[[\"class\"],[\"breadcrumb__link\"]],{\"statements\":[[0,\"My courses\"]],\"parameters\":[]},null],[10],[0,\"\\n  \\t\\t\\t\\t\\t\"],[10],[0,\"\\n  \\t\\t\\t\\t\"],[10],[0,\"\\n  \\t\\t\\t\"],[10],[0,\"\\n  \\t\\t\"],[10],[0,\"\\n  \\t\\t\"],[7,\"div\"],[11,\"class\",\"section__content\"],[9],[0,\"\\n  \\t\\t\\t\"],[1,[29,\"course-profile\",null,[[\"course\"],[[25,[\"model\"]]]]],false],[0,\"\\n  \\t\\t\"],[10],[0,\"\\n  \\t\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/apps/course-cuttoffs/view.hbs"
    }
  });

  _exports.default = _default;
});