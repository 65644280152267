define("megaportal-frontend-uq-components/routes/apps/student-analytics/index", ["exports", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin", "megaportal-frontend-uq-components/models/account"], function (_exports, _myuqAuthenticatedRouteMixin, _account) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    // Before the model is generated, get and store personal info and the currently enrolled course list
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      /*var self = this;
       // Get my account data
      self.set('accountDetails', {});
      Account.fetch().then(function(data){
          self.set('accountDetails', data);
      });
       let studentCourses = Account.fetchCipCourses();
      let cipPrograms = Account.fetchCipPrograms();
       // Fire two promises to get studentCourses (and programs) from ES and this students programs from CIP
      return Promise.all([studentCourses, cipPrograms]).then(responses => {
           let studentCourses = responses[0];
          let cipPrograms = responses[1];
           // Get my enrolled courses and programs
          this.processStudentCoursesAndPrograms(studentCourses, cipPrograms);
           return;
      });*/

    },
    // Process the datahub data (from ES) to a more usable format. Use the CIP data to select only active programs

    /*processStudentCoursesAndPrograms (studentCourses, cipPrograms) {
        console.log(studentCourses, cipPrograms);
        var
            self = this,
            courseDetails = [],
            programDetails,
            activeProgramDetails,
            course,
            program,
            semestersStarted = [];
         if (studentCourses.enrolments) {
           courseDetails = studentCourses.enrolments.filterBy('is_current_term',true);
        }
         // Store our course list
        self.set('enrolledCourseDetails', courseDetails);
         // Store our programs list
        programDetails = [];
        activeProgramDetails = [];
         if (studentCourses.user && studentCourses.user.programs) {
            programDetails = studentCourses.user.programs;
            // Supplement the data for each program
            Ember.$.each(studentCourses.user.programs, function (index, data) {
                 // Check whether this program (that can have any status) is found in the cipPrograms (only the active programs)
                let isActive = cipPrograms.find(function(cipProgram) {
                    return cipProgram.program_code === data.acad_prog;
                });
                 if (isActive) {
                    program = data;
                     // Use the code and class_nbr fields to hold the program name (=title) and code (=acad_prog)
                    program.code = data.title;
                    program.class_nbr = data.acad_prog;
                     // Round the GPA to 2 decimal places
                    program.cum_gpa = (Math.round(parseFloat(program.cum_gpa) * 100) / 100).toString();
                     // Store this active program
                    activeProgramDetails.push(program);
                }
            });
        }
        self.set('hasProgramDetails', activeProgramDetails.length === 0 ? false : true);
        self.set('enrolledProgramDetails', activeProgramDetails);
    },*/
    actions: {
      didTransition: function didTransition() {// After template render, force the first graph in the list of programs and courses to render
        //let controller = this.controller;
        //let model = controller.model;
        // Look at the courses

        /*let courseDetails = Ember.get(model, 'courses.enrolments');
        if (courseDetails && courseDetails.length > 0) {
            // Set the data source for the first course
            Ember.set(controller, 'dataSource', [
                'course',
                courseDetails[0]
            ]);
        }*/

        /*// Look at the programs
        if (Ember.get(model, 'hasPrograms')) {
            // Set the first program with a slight delay to allow the course event to fire
            setTimeout(function(){
                let programDetails = Ember.get(model, 'programDetails');
                Ember.set(controller, 'dataSource', [
                    'program',
                    programDetails[0]
                ]);
            }, 100);
        }*/
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        programs: _account.default.fetchCipPrograms(),
        courses: _account.default.fetchCipCourses(),
        gpas: _account.default.fetchGpaInfo(),
        account: _account.default.fetch()
      });
    },
    afterModel: function afterModel(resolvedModel) {
      if (Array.isArray(resolvedModel.programs) && Array.isArray(resolvedModel.gpas)) {
        resolvedModel.programs.forEach(function (program) {
          resolvedModel.gpas.forEach(function (gpaRecord) {
            //if we have a gpa matching an active program
            if (program.program_code === gpaRecord.acad_prog && program.career_code === gpaRecord.career && program.student_career_number === gpaRecord.career_number) {
              Ember.set(program, "cum_gpa", gpaRecord.cum_gpa);
            }
          });
        });
      }
    }
    /*
     var self = this;
    return {
          account: Ember.computed('accountDetails', function() {
             return self.get('accountDetails');
         }),
          courseDetails:  Ember.computed('enrolledCourseDetails', function() {
             return self.get('enrolledCourseDetails');
         }),
          programDetails:  Ember.computed('enrolledProgramDetails', function() {
             return self.get('enrolledProgramDetails');
         }),
          hasPrograms:  Ember.computed('hasProgramDetails', function() {
             return self.get('hasProgramDetails');
         }),
          activityOptions: Ember.computed('dailyActivityOptions', function() {
             return Ember.get(this, 'dailyActivityOptions');
         }),
              gpaData: {},
          gpaOptions: {},
          gpaCourseData: [],
          gpaCourseOptions: {
             title: 'GPAs vs % student in this Course',
             height: 500,
             animation: {
                 startup: true,
                 duration: 1000,
                 easing: 'out'
             },
             isStacked: true,
             colors: ['#6F96CB', '#FF0000'],
             bar: {
                 groupWidth: '50%'
             },
             vAxis: {
                 title: 'GPA',
                 direction: -1
             },
             hAxis: {
                 title: 'Percentage of students',
                 format: '##',
                 maxValue: 50,
                 gridlines: {
                     count: 6
                 }
             },
             annotations: {
                 alwaysOutside: true,
                 textStyle: {
                     fontSize: 14,
                     color: 'black',
                     highContrast: false
                 },
                 style: 'point',
                 stem: {
                     length: 10
                 }
             },
             tooltip: {
                 trigger: 'none'
             },
             legend: {
                 position: 'none'
             }
         },
          gpaProgramData: [],
          gpaProgramOptions: {
             title: 'GPAs vs % student in this Program',
             height: 500,
             animation: {
                 startup: true,
                 duration: 1000,
                 easing: 'out'
             },
             isStacked: true,
             colors: ['#17becf', '#ff0000'],
             bar: {
                 groupWidth: '50%'
             },
             vAxis: {
                 title: 'GPA',
                 direction: -1
             },
             hAxis: {
                 title: 'Percentage of students',
                 format: '##',
                 maxValue: 50,
                 gridlines: {
                     count: 6
                 }
             },
             annotations: {
                 alwaysOutside: true,
                 textStyle: {
                     fontSize: 14,
                     color: 'black',
                     highContrast: false
                 },
                 style: 'point',
                 stem: {
                     length: 10
                 }
             },
             tooltip: {
                 trigger: 'none'
             },
             legend: {
                 position: 'none'
             }
         },
          correlationData: [],
          correlationOptions: {
             title: 'GPA vs Activity for students in this course',
             height: 500,
             animation: {
                 startup: true,
                 duration: 1000,
                 easing: 'out'
             },
             isStacked: true,
             colors: ['#17becf', '#ff0000'],
             vAxis: {
                 title: 'GPA',
                 direction: 1,
                 ticks: [1,2,3,4,5,6,7]
             },
             hAxis: {
                 title: 'Learn.UQ Activity',
                 format: '##',
                 gridlines: {
                     count: 6
                 }
             },
             annotations: {
                 alwaysOutside: true,
                 textStyle: {
                     fontSize: 14,
                     color: 'black',
                     highContrast: false
                 },
                 style: 'point',
                 stem: {
                     length: 10
                 }
             },
             legend: {
                 position: 'none'
             }
         }
     };
    }*/

  });

  _exports.default = _default;
});