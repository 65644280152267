define("megaportal-frontend-uq-components/templates/components/logout-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1iqerVbX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\"],[11,\"class\",\"action action--circle action--logout right\"],[11,\"aria-label\",\"Log out\"],[9],[7,\"span\"],[11,\"class\",\"icon icon-lock\"],[9],[10],[3,\"action\",[[24,0,[]],\"showLogoutDialog\"]],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"logoutDialogModal\"],[11,\"class\",\"reveal-modal full logout\"],[11,\"data-reveal\",\"\"],[11,\"aria-labelledby\",\"logoutDialogModal\"],[11,\"aria-hidden\",\"true\"],[11,\"role\",\"dialog\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"action action--circle action--logout\"],[9],[7,\"span\"],[11,\"class\",\"icon icon-lock\"],[9],[10],[10],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"logout__title\"],[9],[0,\"Are you sure you want to log out of myUQ?\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"logout__actions\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"action action--circle action--reject\"],[11,\"aria-label\",\"Close\"],[9],[7,\"span\"],[11,\"class\",\"icon icon-cancel\"],[9],[10],[3,\"action\",[[24,0,[]],\"closeLogoutDialog\"]],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"action action--circle action--accept\"],[9],[7,\"span\"],[11,\"class\",\"icon icon-tick\"],[9],[10],[3,\"action\",[[24,0,[]],\"logoutPressed\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/logout-dialog.hbs"
    }
  });

  _exports.default = _default;
});