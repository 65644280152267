define("megaportal-frontend-uq-components/templates/apps/myfiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7dXFt3XJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"loading\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"spinner-overlay\"],[11,\"role\",\"presentation\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"loader__container\"],[9],[7,\"div\"],[11,\"class\",\"loader loader--light\"],[9],[0,\"Loading...\"],[10],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/apps/myfiles.hbs"
    }
  });

  _exports.default = _default;
});