define("megaportal-frontend-uq-components/components/ims-logout-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    // TODO: add ims-logout-modal class
    classNames: 'modal',
    imsService: Ember.inject.service(),
    actions: {
      close: function close() {
        var imsService = this.imsService;
        imsService.closeLogoutDialog();
      },
      logout: function logout() {
        var imsService = this.imsService;
        imsService.logout();
      }
    }
  });

  _exports.default = _default;
});