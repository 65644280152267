define("megaportal-frontend-uq-components/helpers/math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.math = math;
  _exports.default = void 0;

  function math(params) {
    var result;

    switch (params[1]) {
      case 'multiply':
        result = params[0] * params[2];
        break;

      case 'divide':
        result = params[0] / params[2];
        break;
    }

    return result;
  }

  var _default = Ember.Helper.helper(math);

  _exports.default = _default;
});