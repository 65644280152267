define("megaportal-frontend-uq-components/models/timetable", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Timetable = Ember.Object.extend({});
  Timetable.reopenClass({
    fetchWeek: function fetchWeek(startDate) {
      return _jquery.default.ajax('/api/timetable/week/' + startDate, {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    },
    fetchMonth: function fetchMonth(startDate) {
      return _jquery.default.ajax('/api/timetable/month/' + startDate, {
        dataType: 'json'
      }).then(function (resp) {
        // Promise resolved, just return the data
        return resp;
      }, function (resp) {
        // Promise rejected, the data will be different and is passed to an error handler on the caller
        return resp;
      });
    },
    fetchDay: function fetchDay(day) {
      return _jquery.default.ajax('/api/timetable/day/' + day, {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    },
    fetchNowAndNext: function fetchNowAndNext() {
      return _jquery.default.ajax('/api/timetable/short', {
        dataType: 'json'
      }).then(function (resp) {
        return {
          now: resp.now,
          next: resp.next
        };
      });
    },
    fetchEventById: function fetchEventById(id) {
      return _jquery.default.ajax('/api/timetable/event/' + id, {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    }
  });
  var _default = Timetable;
  _exports.default = _default;
});