define("megaportal-frontend-uq-components/helpers/capitalize-first-letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalizeFirstLetter = capitalizeFirstLetter;
  _exports.default = void 0;

  function capitalizeFirstLetter(params
  /*, hash*/
  ) {
    var string = params[0];
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  var _default = Ember.Helper.helper(capitalizeFirstLetter);

  _exports.default = _default;
});