define("megaportal-frontend-uq-components/helpers/friendly-due-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.friendlyDueDate = friendlyDueDate;
  _exports.default = void 0;

  function friendlyDueDate(params
  /*, hash*/
  ) {
    var date = params[0];
    var className, message, numDays;
    numDays = (0, _moment.default)(date).startOf('day').diff((0, _moment.default)().startOf('day'), 'days');
    message = '';
    className = '';

    if (numDays === 0) {
      message = 'Due today';
      className = 'due-today';
    }

    if (numDays > 0) {
      message = 'Return by ' + (0, _moment.default)(date).format('Do MMMM YYYY');
      className = 'due-later';
    }

    if (numDays < 0) {
      message = numDays * -1 + ' days overdue';
      className = 'panel__content--alert';
    }

    return Ember.String.htmlSafe('<span class="' + className + '">' + message + '</span>');
  }

  var _default = Ember.Helper.helper(friendlyDueDate);

  _exports.default = _default;
});