define("megaportal-frontend-uq-components/components/ftp-client-file-toolbar", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ftp-client-file-toolbar', 'row'],
    ftpService: Ember.inject.service(),
    actions: {
      download: function download() {
        //all this does is call a redirect to a download link
        this.ftpService.download();
      },
      delete: function _delete() {
        this.ftpService.set('showDeletePanel', true);
      },
      //NOTE these features are currently disabled. for any non-functioning features, for now,
      //you can just inject the dialog service in, and use the newWarning method to let the user
      //know that the feature is currently disabled
      rename: function rename() {
        //this lets the service know that we want to rename 'something'. The entries in the directory
        //observe this from the ftpService. They will check over themselves to see if they are selected.
        //if they are, they will switch their title to instead be an input.
        ftpService.set('renameEntry', true); //another 'not so clean' hack to ensure jQuery correctly selects the contents of this input when
        //it is appended to the DOM. Perhaps one could look into improving this by using the component
        //lifecycle or something.

        setTimeout(function () {
          (0, _jquery.default)('input.rename-input').focus();
        }, 10);
      },
      move: function move() {
        this.ftpService.getTreeView();
      }
    }
  });

  _exports.default = _default;
});