define("megaportal-frontend-uq-components/services/feature-service", ["exports", "megaportal-frontend-uq-components/config/environment", "megaportal-frontend-uq-components/models/feature", "megaportal-frontend-uq-components/features"], function (_exports, _environment, _feature, _features) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var deleteProperties = function deleteProperties(obj) {
    if (!obj) {
      return;
    }

    Object.keys(obj).forEach(function (key) {
      delete obj[key];
    });
  };

  var _default = Ember.Service.extend({
    authManager: Ember.inject.service(),
    _isAdmin: null,
    availableFeatures: {},
    hasRestrictedFeatures: false,
    hasPublicFeatures: false,
    features: {
      restrictedFeatures: {},
      unrestrictedFeatures: {},
      releasedFeatures: {},
      unreleasedFeatures: {}
    },
    featureUsers: {},
    featureUsersCount: {},
    loadData: {
      username: null,
      featuresPromise: null,
      userFeaturesPromise: null
    },
    init: function init() {
      this._super();

      this.loadFeatures();
    },
    loadIsAdmin: function loadIsAdmin() {
      var _this = this;

      return _feature.default.fetchIsFeaturesAdmin().then(function (data) {
        if (_this._isAdmin === null) {
          _this.set('_isAdmin', data.isAdmin);
        }

        return data.isAdmin;
      });
    },
    isAdmin: Ember.computed('_isAdmin', {
      get: function get() {
        if (this._isAdmin === null) {
          return this.loadIsAdmin();
        }

        return this._isAdmin;
      },
      set: function set(key, value) {
        this.set('_isAdmin', value);
        return value;
      }
    }),
    loadFeatureUsersCount: function loadFeatureUsersCount() {
      var _this2 = this;

      deleteProperties(this.featureUsersCount);
      return _feature.default.fetchUsersOfFeaturesCount(_environment.default.STAGE).then(function (data) {
        Object.keys(_this2.features.unrestrictedFeatures).forEach(function (featureId) {
          var count = data[featureId] || {};
          count.test = count.test || 0;
          count.staging = count.staging || 0;
          count.prod = count.prod || 0;

          _this2.set("featureUsersCount.".concat(featureId), count);
        });
      });
    },
    findFeatureUsers: function findFeatureUsers(featureId) {
      var _this3 = this;

      return _feature.default.fetchAllUsersFeatures(featureId).then(function (usersFeatures) {
        deleteProperties(_this3.featureUsers[featureId]);

        _this3.set("featureUsers.".concat(featureId), usersFeatures);
      });
    },
    loadFeatures: function loadFeatures() {
      this.set('loadData.featuresPromise', _feature.default.fetchAllFeatures(_environment.default.STAGE));
      return this.loadAllPromises();
    },

    /**
     * Async fetch data, execute in order
     */
    loadAllPromises: function loadAllPromises() {
      var _this4 = this;

      return this.authManager.waitForAccount().then(function () {
        var loadData = _this4.loadData;

        if (!(loadData.featuresPromise && loadData.userFeaturesPromise)) {
          return Promise.resolve();
        }

        var prm = Promise.all([loadData.featuresPromise, loadData.userFeaturesPromise]).then(function (response) {
          _this4.processFeatures(response[0]);

          _this4.processUserFeatures(response[1], loadData.username);
        });
        loadData.featuresPromise = _feature.default.fetchAllFeatures(_environment.default.STAGE);
        loadData.userFeaturesPromise = _feature.default.fetchUserFeatures(_environment.default.STAGE, loadData.username);
        return prm;
      });
    },
    processFeatures: function processFeatures(features) {
      var _this5 = this;

      this.set('features.restrictedFeatures', {});
      this.set('features.unrestrictedFeatures', {});
      this.set('features.releasedFeatures', {});
      this.set('features.unreleasedFeatures', {});
      features.forEach(function (feature) {
        if (_features.allFeatures[feature.featureId]) {
          _features.allFeatures[feature.featureId].restricted = feature.restricted;
        }

        if (feature.restricted) {
          _this5.set("features.restrictedFeatures.".concat(feature.featureId), feature);
        } else {
          _this5.set("features.unrestrictedFeatures.".concat(feature.featureId), feature);
        }
      });
      var merged = Object.assign({}, this.get('features.restrictedFeatures'), this.get('features.unrestrictedFeatures'));
      Object.keys(merged).forEach(function (featureId) {
        if (typeof _features.allFeatures[featureId] === 'undefined') {
          _this5.set("features.releasedFeatures.".concat(featureId), merged[featureId]); // remove from available features


          if (merged[featureId].restricted) {
            delete _this5.get("features.restrictedFeatures")[featureId];
          } else {
            delete _this5.get("features.unrestrictedFeatures")[featureId];
          }
        }
      });
      Object.keys(_features.allFeatures).forEach(function (featureId) {
        if (typeof merged[featureId] === 'undefined') {
          _this5.set("features.unreleasedFeatures.".concat(featureId), _features.allFeatures[featureId]);
        }
      });
    },

    /**
     * Loads the enabled feature IDs of the application for the current user
     * @param username
     */
    loadUserFeatures: function loadUserFeatures(username) {
      this.set('loadData.userFeaturesPromise', _feature.default.fetchUserFeatures(_environment.default.STAGE, username));
      this.set('loadData.username', username);
      return this.loadAllPromises();
    },
    processUserFeatures: function processUserFeatures(userFeatures, username) {
      var _this6 = this;

      // clear all available features
      Object.keys(this.availableFeatures).forEach(function (key) {
        if (_this6.availableFeatures.hasOwnProperty(key)) {
          delete _this6.availableFeatures[key];
        }
      });
      Object.keys(_features.allFeatures).forEach(function (featureId) {
        var feature = _features.allFeatures[featureId]; // set all features available

        _this6.set("availableFeatures.".concat(featureId), {
          featureId: featureId,
          restricted: feature.restricted || false,
          stage: _environment.default.STAGE,
          username: username,
          enabled: false
        });

        if (feature.roles) {
          var foundRole = null;
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = feature.roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var role = _step.value;

              if (role === 'staff' && _this6.authManager.isStaff) {
                foundRole = role;
                break;
              }

              if (role === 'student' && _this6.authManager.isStudent) {
                foundRole = role;
                break;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          if (!foundRole) {
            // remove available feature if this user doesn't have the right role
            delete _this6.availableFeatures[featureId];
          }
        }
      });

      if (userFeatures && userFeatures instanceof Array) {
        // filter out users who do not have access to this feature
        // because it is restricted and they don't have a user feature.
        var userFeatureIds = [];
        userFeatures.forEach(function (userFeature) {
          // set the enabled toggle flag of this feature for this user
          userFeatureIds.push(userFeature.featureId);

          if (_this6.availableFeatures.hasOwnProperty(userFeature.featureId)) {
            var feature = _this6.get("availableFeatures.".concat(userFeature.featureId));

            Ember.set(feature, 'enabled', userFeature.enabled);
          }
        });
        var availableFeatures = this.availableFeatures;

        for (var _i = 0, _Object$keys = Object.keys(availableFeatures); _i < _Object$keys.length; _i++) {
          var featureId = _Object$keys[_i];

          if (availableFeatures[featureId].restricted && userFeatureIds.indexOf(featureId) === -1) {
            // remove the available feature.
            delete this.availableFeatures[featureId];
          }
        }

        var unreleasedFeatures = this.features.unreleasedFeatures;

        if (unreleasedFeatures) {
          Object.keys(unreleasedFeatures).forEach(function (featureId) {
            if (_this6.availableFeatures.hasOwnProperty(featureId)) {
              // remove unreleased features from available ones
              delete _this6.availableFeatures[featureId];
            }
          });
        } // finally loop through all features and set has public/restricted flags


        Object.keys(this.availableFeatures).forEach(function (featureId) {
          var feature = _this6.availableFeatures[featureId];

          if (feature.restricted && _this6.hasRestrictedFeatures === false) {
            _this6.set('hasRestrictedFeatures', true);
          }

          if (feature.restricted === false && _this6.hasPublicFeatures === false) {
            _this6.set('hasPublicFeatures', true);
          }
        });
      }
    },
    addUserFeature: function addUserFeature(featureId, username) {
      return _feature.default.createUserFeature(_environment.default.STAGE, username, featureId);
    }
  });

  _exports.default = _default;
});