define("megaportal-frontend-uq-components/routes/logout", ["exports", "megaportal-frontend-uq-components/authenticators/sso"], function (_exports, _sso) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      didTransition: function didTransition() {
        Ember.run.scheduleOnce('afterRender', this, function () {
          window.location.replace(_sso.default.serverLogoutEndpoint);
        });
      }
    }
  });

  _exports.default = _default;
});