define("megaportal-frontend-uq-components/services/geolocation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    currentPosition: function currentPosition() {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if ('geolocation' in window.navigator) {
          setTimeout(function () {
            reject("Geolocation acquisition timed out");
          }, 5000);
          return window.navigator.geolocation.getCurrentPosition(function (position) {
            Ember.run(function () {
              return resolve(position);
            });
          }, function (error) {
            var msg = function () {
              switch (error.code) {
                case 1:
                  return 'Geolocation permissions denied';

                case 2:
                  return 'Failed to acquire geolocation';

                case 3:
                  return 'Geolocation acquisition timed out';

                default:
                  return 'Geolocation services are required, but currently not available';
              }
            }();

            return Ember.run(function () {
              return reject(new Error(msg));
            });
          }, {
            timeout: 5000,
            enableHighAccuracy: true
          });
        } else {
          return Ember.run(function () {
            return reject(new Error('Geolocation services unsupported by browser, location-aware features are not available'));
          });
        }
      });
    }
  });

  _exports.default = _default;
});