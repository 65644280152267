define("megaportal-frontend-uq-components/templates/components/calendar-view/mobile/dashboard-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RP+m17Ee",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section__header\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"column large-6 small-6\"],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"section__title\"],[9],[0,\"My Timetable\"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"column large-6 small-6 text-right dashboard-widget__all-link\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"https://my.uq.edu.au/student-timetable\"],[9],[0,\" View all \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section__content dashboard-widget__timetable\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"day-view\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"loading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"column-day\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"day-events-container-mobile\"],[9],[0,\"\\n           \"],[7,\"div\"],[11,\"class\",\"panel\"],[9],[0,\"\\n              \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"panel__content\"],[9],[0,\"\\n                  \"],[7,\"div\"],[11,\"class\",\"spinner-in-document\"],[9],[0,\"\\n                    \"],[7,\"div\"],[11,\"class\",\"spinner-contents\"],[9],[10],[0,\"\\n                  \"],[10],[0,\"\\n                \"],[10],[0,\"\\n              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[29,\"calendar-view/mobile/dashboard-column\",null,[[\"firstEvent\",\"otherEvents\"],[[25,[\"firstEvent\"]],[25,[\"otherEvents\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/calendar-view/mobile/dashboard-widget.hbs"
    }
  });

  _exports.default = _default;
});