define("megaportal-frontend-uq-components/routes/apps/library", ["exports", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin", "megaportal-frontend-uq-components/models/library"], function (_exports, _myuqAuthenticatedRouteMixin, _library) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        library: _library.default.fetch()
      });
    }
  });

  _exports.default = _default;
});