define("megaportal-frontend-uq-components/services/browser-support-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NOT_SPECIFIED_KEYWORD = "Not Specified"; // Internet Explorer Specific Regexes

  var TridentRegEx = /Trident\/\d+.?\d+/;
  var VersionRegExAlpha = /MSIE \d+.?\d+/;
  var VersionRegExBravo = /rv:\d+.?\d+/; // Edge Specific Regexes

  var EdgeRegEx = /Edge/; // Chrome Specific Regexes

  var ChromeRegEx = /Chrome/; // Safari Specific Regexes

  var SafariRegEx = /Safari/; // Firefopx Specific Regexes

  var FirefoxRegEx = /Firefox/; // Mozilla Specific Regexes

  var MozillaRegEx = /Mozilla/; // WebKit Specific Regexes

  var WebKitRegEx = /WebKit/; // Opera Specific Regexes

  var OperaRegEx = /Opera/;
  /**
   * Browser Support service is used for providing information about the user's browser
   * And also relevant routes and minimum supported browser capabilities.
   */

  var _default = Ember.Service.extend({
    // config: service('config-service'),
    browserSupportMatrix: null,
    browserInformation: null,
    relevantRoutes: null,
    displayOptions: null,
    enabled: false,
    isInternetExplorer: function isInternetExplorer() {
      return navigator && navigator.userAgent && navigator.userAgent.search(TridentRegEx) !== -1;
    },
    isEdge: function isEdge() {
      return navigator && navigator.userAgent && navigator.userAgent.search(EdgeRegEx) !== -1;
    },
    isChrome: function isChrome() {
      return navigator && navigator.userAgent && navigator.userAgent.search(ChromeRegEx) !== -1;
    },
    isSafari: function isSafari() {
      return navigator && navigator.userAgent && navigator.userAgent.search(SafariRegEx) !== -1;
    },
    isFireFox: function isFireFox() {
      return navigator && navigator.userAgent && navigator.userAgent.search(FirefoxRegEx) !== -1;
    },
    isMozilla: function isMozilla() {
      return navigator && navigator.userAgent && navigator.userAgent.search(MozillaRegEx) !== -1;
    },
    isWebKit: function isWebKit() {
      return navigator && navigator.userAgent && navigator.userAgent.search(WebKitRegEx) !== -1;
    },
    isOpera: function isOpera() {
      return navigator && navigator.userAgent && navigator.userAgent.search(OperaRegEx) !== -1;
    },
    majorVersion: Ember.computed("browserInformation", function () {
      return !!this.browserInformation;
    }),
    minorVersion: Ember.computed("browserInformation", function () {
      return !!this.browserInformation;
    }),
    init: function init() {
      this.enabled = true;
      this.relevantRoutes = [{
        Name: "my-requests",
        BrowserSupportMatrix: [{
          Name: "InternetExplorer",
          Allowed: false
        }]
      }];
      this.displayOptions = {
        Message: "Your browser (Internet Explorer) is not supported and you may experience problems while using My requests. Please try again in a different browser (Chrome, Firefox, Safari, Microsoft Edge)"
      };
      this.browserSupportMatrix = [{
        Name: "InternetExplorer",
        Allowed: true,
        FromVersion: 9
      }, {
        Name: "Chrome",
        Allowed: true,
        FromVersion: 57
      }, {
        Name: "Safari",
        Allowed: true,
        FromVersion: 10
      }]; // this.browserInformation = { ApplicationName: this.getApplicationName(),
      //   ApplicationCodeName: this.getApplicationCodeName(),
      //   ApplicationVersion: this.getApplicationVersion(),
      //   ApplicationMinorVersion: this.getApplicationMinorVersion(),
      //   CookiesEnabled: this.getCookiesEnabled(),
      //   RegularExpressionsSupported: this.getRegularExpressionsSupported(),
      //   AnchorsSupported: this.getAnchorsSupported(),
      //   ImagesSupported: this.getImagesSupported(),
      //   FormsSupported: this.getFormsSupported(),
      //   LinksSupported: this.getLinksSupported(),
      //   FramesSupported: this.getFramesSupported(),
      //   JavaEnabled: this.getJavaEnabled(),
      //   UserAgentString: this.getUserAgentString(),
      //   BrowserLanguage: this.getBrowserLanguage(),
      //   CpuClass: this.getCPUClass(),
      //   ConnectionSpeed: this.getConnectionSpeed(),
      //   IsInCompatibilityMode: this.isInCompatibilityMode()
      // };

      this._super.apply(this, arguments);
    },
    //   getApplicationName: function () {
    //     return navigator.appName? navigator.appName.toString() : NOT_SPECIFIED_KEYWORD;
    //   },
    //
    //   getApplicationCodeName: function () {
    //     return navigator.appCodeName ? navigator.appCodeName.toString() : NOT_SPECIFIED_KEYWORD;
    //   },
    //
    //   getApplicationVersion: function () {
    //     if (this.isInternetExplorer()) {
    //       let applicationVersion = navigator.userAgent.match(VersionRegExAlpha);
    //       if (this.isArray(applicationVersion)) {
    //           return applicationVersion[0] &&
    //           applicationVersion[0].length > 6 ? applicationVersion[0].substring(6)
    //             : NOT_SPECIFIED_KEYWORD;
    //       }
    //
    //       applicationVersion = navigator.userAgent.match(VersionRegExBravo);
    //       if (this.isArray(applicationVersion)) {
    //         return applicationVersion[0] &&
    //         applicationVersion[0].length > 3 ? applicationVersion
    //           : NOT_SPECIFIED_KEYWORD;
    //       }
    //     }
    //
    //       return navigator.appVersion ? navigator.appVersion.toString() : NOT_SPECIFIED_KEYWORD;
    //   },
    //
    //   getApplicationMinorVersion: function () {
    //       return navigator.appMinorVersion ? navigator.appMinorVersion.toString() : NOT_SPECIFIED_KEYWORD;
    //   },
    //
    //   getCookiesEnabled: function () {
    //       return navigator.cookieEnabled;
    //   },
    //
    //   getRegularExpressionsSupported: function () {
    //       return !!window.RegExp;
    //   },
    //
    //   getAnchorsSupported: function () {
    //       return !!document.anchors;
    //   },
    //
    //   getImagesSupported: function () {
    //       return !!document.images;
    //   },
    //
    //   getFormsSupported: function () {
    //       return !!document.forms;
    //   },
    //
    //   getLinksSupported: function () {
    //       return !!document.links;
    //   },
    //
    //   getFramesSupported: function () {
    //       return !!window.frames;
    //   },
    //
    //   getJavaEnabled: function () {
    //       return navigator.javaEnabled && navigator.javaEnabled();
    //   },
    //
    //   getUserAgentString: function () {
    //       return navigator.userAgent ? navigator.userAgent.toString() : NOT_SPECIFIED_KEYWORD;
    //   },
    //
    //   getBrowserLanguage: function () {
    //     return navigator.browserLanguage ? navigator.browserLanguage.toString() : NOT_SPECIFIED_KEYWORD;
    //   },
    //
    //   getConnectionSpeed: function () {
    //     return navigator.connectionSpeed ? navigator.connectionSpeed : NOT_SPECIFIED_KEYWORD;
    //   },
    //
    //   getCPUClass: function () {
    //     return navigator.cpuClass ? navigator.cpuClass : NOT_SPECIFIED_KEYWORD;
    //   },
    //
    //   isInCompatibilityMode: function () {
    //     if (!this.isInternetExplorer()) {
    //       return false;
    //     }
    //
    //     let tridentVersion = this.getTridentVersion();
    //
    //     return this.getApplicationVersion() === tridentVersion;
    //
    // },
    //
    // getTridentVersion: function () {
    //   if (!this.isInternetExplorer()) {
    //     return 0;
    //   }
    //
    //   let tridentVersion = navigator.userAgent.match(TridentRegEx);
    //
    //   return tridentVersion &&
    //   tridentVersion[0] &&
    //   tridentVersion[0].length > 8 ? tridentVersion[0].substring(8)
    //     : 0;
    // },
    //Helper Method to determine correctly if an object is an array or not.
    // We DO NOT want to rely on the Array.isArray method as not all browsers implement it.
    isArray: function isArray(argument) {
      if (argument) {
        // Use compiler's own isArray when available
        if (Array.isArray) {
          return Array.isArray(argument);
        } // Retain references to variables for performance
        // optimization


        var objectToStringFunction = Object.prototype.toString;
        var arrayToStringResult = objectToStringFunction.call([]);
        return objectToStringFunction.call(argument) === arrayToStringResult;
      }

      return false;
    }
  });

  _exports.default = _default;
});