define("megaportal-frontend-uq-components/models/news", ["exports", "megaportal-frontend-uq-components/config/environment", "fetch"], function (_exports, _environment, _fetch2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UQNews = Ember.Object.extend({});
  UQNews.reopenClass({
    fetch: function fetch() {
      var limit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 15;
      return (0, _fetch2.default)("".concat(_environment.default.NEWS_AND_EVENTS_URL, "/news?limit=").concat(limit)).then(function (response) {
        return response.json();
      });
    },
    fetchToday: function fetchToday() {
      return (0, _fetch2.default)("".concat(_environment.default.NEWS_AND_EVENTS_URL, "/today")).then(function (response) {
        return response.json();
      });
    }
  });
  var _default = UQNews;
  _exports.default = _default;
});