define("megaportal-frontend-uq-components/routes/apps/my-requests/make-request/view", ["exports", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin", "megaportal-frontend-uq-components/models/unitask"], function (_exports, _myuqAuthenticatedRouteMixin, _unitask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    model: function model(params) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _unitask.default.fetchProcess(params.processName).then(function (process) {
          if (Ember.isEmpty(process)) {
            reject(new Error('Process not found for user.'));
          } else {
            resolve(process);
          }
        });
      });
    }
  });

  _exports.default = _default;
});