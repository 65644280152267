define("megaportal-frontend-uq-components/templates/components/calendar-view/current-time-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uY5fR6NP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"current-time-line\"],[12,\"style\",[23,\"style\"]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"current-time-line__time\"],[9],[1,[23,\"updatedTime\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"current-time-line__indicator\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/calendar-view/current-time-line.hbs"
    }
  });

  _exports.default = _default;
});