define("megaportal-frontend-uq-components/components/calendar-view/dashboard-column", ["exports", "megaportal-frontend-uq-components/templates/components/calendar-view/dashboard-column"], function (_exports, _dashboardColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dashboardColumn.default,
    tagName: "div",
    classNames: ["column-day"]
  });

  _exports.default = _default;
});