define("megaportal-frontend-uq-components/helpers/date-ordinal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateOrdinal = dateOrdinal;
  _exports.default = void 0;

  function dateOrdinal(params
  /*, hash*/
  ) {
    return (0, _moment.default)(params[0]).format("Do").replace(/[^A-Za-z]/g, "");
  }

  var _default = Ember.Helper.helper(dateOrdinal);

  _exports.default = _default;
});