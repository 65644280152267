define("megaportal-frontend-uq-components/routes/apps/courses/view", ["exports", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin"], function (_exports, _myuqAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findRecord('course', "".concat(params.class_nbr, ",").concat(params.strm));
    }
  });

  _exports.default = _default;
});