define("megaportal-frontend-uq-components/routes/apps/events/index", ["exports", "megaportal-frontend-uq-components/models/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        todaysEvents: _events.default.fetchToday()
      }).then(function (data) {
        return {
          todaysEvents: data.todaysEvents.sort(function (a, b) {
            return a.StartTime > b.StartTime ? 1 : b.StartTime > a.StartTime ? -1 : 0;
          })
        };
      });
    }
  });

  _exports.default = _default;
});