define("megaportal-frontend-uq-components/templates/components/drop-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "izLOlP53",
    "block": "{\"symbols\":[\"option\",\"key\",\"&default\"],\"statements\":[[15,3,[[25,[\"filter\"]]]],[0,\"\\n\"],[7,\"select\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"select\"],[[\"value\"],[\"target.value\"]]]],[12,\"class\",[30,[[29,\"unless\",[[25,[\"class\"]],[25,[\"className\"]]],null]]]],[9],[0,\"\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"options\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,[24,1,[]],[[13,\"value\",[30,[[24,1,[]]]]],[13,\"selected\",[29,\"is-equal\",[[24,1,[]],[25,[\"filter\"]]],null]]],[[],[]],{\"statements\":[[1,[29,\"capitalize-first-letter\",[[24,2,[]]],null],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/drop-down.hbs"
    }
  });

  _exports.default = _default;
});