define("megaportal-frontend-uq-components/reopens/component", [], function () {
  "use strict";

  Ember.Component.reopen({
    init: function init() {
      this._super();

      var self = this;
      Object.keys(this).forEach(function (key) {
        if (key.substr(0, 5) === 'aria-') {
          self.get('attributeBindings').pushObject(key);
        }

        if (key === 'role') {
          self.get('attributeBindings').pushObject(key);
        }
      });
    }
  });
});