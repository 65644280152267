define("megaportal-frontend-uq-components/components/notification-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var capitalizeFirstLetter = function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  var _default = Ember.Component.extend({
    notificationService: Ember.inject.service(),
    tagName: 'div',
    type: Ember.computed('notification', function () {
      return 'panel--alert-' + this.notification.type;
    }),
    alertDescription: Ember.computed('notification', function () {
      var notification = this.notification;
      return 'alertDescription' + capitalizeFirstLetter(notification.type);
    }),
    ariaLive: Ember.computed('notification', function () {
      var notification = this.notification;
      return notification.type === 'success' ? 'polite' : 'assertive';
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);
    },
    actions: {
      close: function close() {
        this.notificationService.removeNotification(this.notification);
      }
    }
  });

  _exports.default = _default;
});