define("megaportal-frontend-uq-components/features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.allFeatures = void 0;

  /*
   * Please provide feature details here.
   */
  var allFeatures = {
    // identity: {
    //   name: 'Identity',
    //   description: 'User identity functionality',
    //   restricted: true,
    //   link: 'https://jira.its.uq.edu.au/jira/browse/ITSADSSD-7493'
    // },
    AIAssistant: {
      name: 'my.UQ AI assistant',
      description: "Have a question? Ask UQ Assist",
      restricted: true
    },
    // ictePlus: {
    //   name: 'ICTE Plus',
    //   description: "Enables ICTE Plus link for ICTE students",
    //   link: 'https://jira.its.uq.edu.au/jira/browse/ITSADSSD-14112',
    //   restricted: true
    // },
    ecpLinks: {
      name: 'Electronic Course Profile Links',
      description: "Electronic Course Profile Links",
      restricted: true
    }
  };
  _exports.allFeatures = allFeatures;
});