define("megaportal-frontend-uq-components/routes/apps/courses/index", ["exports", "megaportal-frontend-uq-components/models/account", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin"], function (_exports, _account, _myuqAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash(_account.default.fetchCipCourses());
    }
  });

  _exports.default = _default;
});