define("megaportal-frontend-uq-components/routes/dashboard", ["exports", "megaportal-frontend-uq-components/models/news", "megaportal-frontend-uq-components/models/events", "megaportal-frontend-uq-components/models/account", "megaportal-frontend-uq-components/models/notifications"], function (_exports, _news, _events, _account, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  //As we are using hashSettled, an ember error will not be thrown (and caught) by our error route,
  //which is as per design, so the dashboard always loads. However, if we get a 401 or 403 (unauthorized)
  //kill the users session.
  var checkStatuses = function checkStatuses(model, transition) {
    for (var _i = 0, _Object$entries = Object.entries(model); _i < _Object$entries.length; _i++) {
      var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 1),
          _Object$entries$_i$ = _Object$entries$_i[0],
          state = _Object$entries$_i$.state,
          reason = _Object$entries$_i$.reason;

      if (state === 'rejected' && (reason.status === 401 || reason.status === 403)) {
        transition.send('error', reason, transition);
      }
    }
  };

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    authManager: Ember.inject.service(),
    timetableService: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return this.authManager.waitForAccount().then(function () {
        return Ember.RSVP.hashSettled({
          notifications: _notifications.default.fetch(),
          news: _news.default.fetch(5),
          events: _events.default.fetch(5),
          courses: _this.get('session.isAuthenticated') && _this.get('authManager.isStudent') ? _account.default.fetchCipCoursesCurrent(_this.get("authManager.enrolmentType")) : null
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      return checkStatuses(model, transition);
    }
  });

  _exports.default = _default;
});