define("megaportal-frontend-uq-components/adapters/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var timetableModels = ['week', 'semester', 'course'];

  var _default = _emberData.default.RESTAdapter.extend({
    authManager: Ember.inject.service(),
    configService: Ember.inject.service(),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var returnValue = this._super.apply(this, arguments); // Make sure timetable requests are sent to the right endpoint


      if (timetableModels.includes(modelName)) {
        var timetableUrl = this.configService.TIMETABLE_URL;
        return "".concat(timetableUrl).concat(returnValue);
      }

      return returnValue;
    }
  });

  _exports.default = _default;
});