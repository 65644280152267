define("megaportal-frontend-uq-components/models/unitask", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Unitask = Ember.Object.extend({});
  Unitask.reopenClass({
    fetchProcesses: function fetchProcesses(loadFromCache) {
      var _this = this;

      if (loadFromCache === undefined) {
        loadFromCache = false;
      }

      return new Promise(function (resolve, reject) {
        if (loadFromCache && _this._processes) {
          resolve(_this._processes);
          return;
        }

        _jquery.default.ajax('/api/unitask/processes', {
          dataType: 'json'
        }).then(function (data) {
          if (!data.processes) {
            reject(new Error('Could not find processes'));
          }

          _this._processes = data.processes;
          resolve(data.processes);
        }, function (xhr, status) {
          var statusCode = xhr.status || 0;
          var err = null;

          if (xhr.responseJSON && xhr.responseJSON.exception) {
            err = new Error(xhr.responseJSON.exception);
          } else {
            err = new Error(xhr.statusText || status);
          }

          err.status = statusCode;
          reject(err);
        });
      });
    },
    checkActiveAccount: function checkActiveAccount() {
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax('/api/unitask/active-account', {
          dataType: 'json'
        }).then(function (data) {
          resolve(data);
        }, function (xhr, status) {
          var statusCode = xhr.status || 0;
          var err = null;

          if (xhr.responseJSON && xhr.responseJSON.exception) {
            err = new Error(xhr.responseJSON.exception);
          } else {
            err = new Error(xhr.statusText || status);
          }

          err.status = statusCode;
          reject(err);
        });
      });
    },
    fetchActiveCases: function fetchActiveCases() {
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax('/api/unitask/active-cases', {
          dataType: 'json'
        }).then(function (data) {
          if (!data.activeCases) {
            reject(new Error('Could not find active cases'));
          }

          resolve({
            cases: data.activeCases,
            totalCases: data.activeCases.length
          });
        }, function (xhr, status) {
          var statusCode = xhr.status || 0;
          var err = null;

          if (xhr.responseJSON && xhr.responseJSON.exception) {
            err = new Error(xhr.responseJSON.exception);
          } else {
            err = new Error(xhr.statusText || status);
          }

          err.status = statusCode;
          reject(err);
        });
      });
    },
    fetchArchivedCases: function fetchArchivedCases(pageIndex, itemsPerPage) {
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax("/api/unitask/archived-cases/".concat(pageIndex, "/").concat(itemsPerPage), {
          dataType: 'json'
        }).then(function (data) {
          if (!data.archivedCases) {
            reject(new Error('Could not find archived cases'));
          }

          resolve({
            cases: data.archivedCases,
            totalCases: data.archivedCasesTotal
          });
        }, function (xhr, status) {
          var statusCode = xhr.status || 0;
          var err = null;

          if (xhr.responseJSON && xhr.responseJSON.exception) {
            err = new Error(xhr.responseJSON.exception);
          } else {
            err = new Error(xhr.statusText || status);
          }

          err.status = statusCode;
          reject(err);
        });
      });
    },
    fetchTasks: function fetchTasks(limit) {
      var data = {};

      if (typeof limit === 'number') {
        data.c = limit;
      }

      return _jquery.default.ajax('/api/unitask/tasks', {
        dataType: 'json',
        data: data
      }).then(function (resp) {
        return resp;
      }, function (xhr, status) {
        var statusCode = xhr.status || 0;
        var err = null;

        if (xhr.responseJSON && xhr.responseJSON.exception) {
          err = new Error(xhr.responseJSON.exception);
        } else {
          err = new Error(xhr.statusText || status);
        }

        err.status = statusCode;
        return err;
      });
    },
    fetchTask: function fetchTask(taskId) {
      return _jquery.default.ajax('/api/unitask/task/' + taskId, {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    },
    fetchCommonAttributesForCase: function fetchCommonAttributesForCase(caseId) {
      return _jquery.default.ajax('/api/unitask/case/' + caseId + '/common_attributes', {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    },
    fetchOverview: function fetchOverview() {
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax('/api/unitask/cases-and-processes', {
          dataType: 'json'
        }).then(function (data) {
          resolve(data);
        }, function (xhr, status) {
          var statusCode = xhr.status || 0;
          var err = null;

          if (xhr.responseJSON && xhr.responseJSON.exception) {
            err = new Error(xhr.responseJSON.exception);
          } else {
            err = new Error(xhr.statusText || status);
          }

          err.status = statusCode;
          reject(err);
        });
      });
    },
    fetchProcess: function fetchProcess(processName) {
      return _jquery.default.ajax("/api/unitask/process/".concat(processName), {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    },
    fetchCase: function fetchCase(caseId) {
      return _jquery.default.ajax("/api/unitask/case/".concat(caseId), {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    }
  });
  var _default = Unitask;
  _exports.default = _default;
});