define("megaportal-frontend-uq-components/services/freetime-service", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    createFreeTimeBlocks: function createFreeTimeBlocks(data) {
      var resp = [];

      if (Array.isArray(data)) {
        data.forEach(function (entry, index) {
          resp.push(entry);

          if (data[index + 1]) {
            var startNext = (0, _moment.default)(data[index + 1].startTime),
                diff = _moment.default.duration((0, _moment.default)(startNext).diff((0, _moment.default)(entry.endTime))).asMinutes(),
                currentStart = (0, _moment.default)(entry.endTime).add(10, 'minutes'),
                currentEnd = (0, _moment.default)(entry.endTime).add(60, 'minutes');

            while (diff > 10) {
              diff -= 60;
              resp.push({
                "streamType": "Free Time",
                "startTime": currentStart.format('YYYY-MM-DD HH:mm:ss'),
                "endTime": currentEnd.format('YYYY-MM-DD HH:mm:ss')
              });

              if (diff > 10) {
                currentStart.add(60, 'minutes');
                currentEnd.add(60, 'minutes');
              }
            }
          }
        });
        return resp;
      }

      return resp;
    }
  });

  _exports.default = _default;
});