define("megaportal-frontend-uq-components/models/transport", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transport = Ember.Object.extend({});
  Transport.reopenClass({
    findUpcomingDepartures: function findUpcomingDepartures() {
      return _jquery.default.ajax('/api/transport', {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    },
    fetchById: function fetchById(departure_id) {
      return _jquery.default.ajax('/api/transport/' + departure_id, {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    }
  });
  var _default = Transport;
  _exports.default = _default;
});