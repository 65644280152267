define("megaportal-frontend-uq-components/components/link-to-external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    attributeBindings: ['href', 'target', 'rel'],
    target: '_blank',
    tagName: 'a',
    href: '',
    class: '',
    click: function click() {
      var url = this.href;
      this.metrics.trackEvent({
        'category': 'outbound',
        'action': 'click',
        'label': url
      });
    }
  });

  _exports.default = _default;
});