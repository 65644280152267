define("megaportal-frontend-uq-components/helpers/mail-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mailTo = mailTo;
  _exports.default = void 0;

  function mailTo(params
  /*, hash*/
  ) {
    var address = params[0];
    var cleanAddress = Ember.Handlebars.Utils.escapeExpression(address);
    var link = '<a href="mailto:' + cleanAddress + '">' + cleanAddress + '</a>';
    return Ember.String.htmlSafe(link);
  }

  var _default = Ember.Helper.helper(mailTo);

  _exports.default = _default;
});