define("megaportal-frontend-uq-components/services/transition-history-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service('-routing'),
    transitions: [],
    addTransition: function addTransition(transition) {
      this.transitions.pushObject(transition);
    },
    getPreviousTransition: function getPreviousTransition() {
      var transitions = this.transitions; //-2 as -1 would be current location

      return transitions[transitions.length - 2];
    },
    removeCurrentTransition: function removeCurrentTransition() {
      var transitions = this.transitions; //Remove last two entries, as current entry will be re-added on transition completion

      transitions.removeAt(transitions.length - 2, 2);
    },
    transitionToPreviousRoute: function transitionToPreviousRoute() {
      window.history.go(-1);
      /*try{
        var previousRoute = this.getPreviousTransition();
        this.removeCurrentTransition();
        if(previousRoute===undefined) {throw new Error("No Back route found.");}
        this.get('router').transitionTo(previousRoute);
      }catch(e){
        //this is the default if we get any errors (e.g. history index not existing)
        this.get('router').transitionTo("application");
      }*/
    }
  });

  _exports.default = _default;
});