define("megaportal-frontend-uq-components/templates/components/inline-iframe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HIWkOz9F",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"iframe\"],[11,\"id\",\"MyUQinlineIframe\"],[12,\"src\",[30,[[23,\"url\"]]]],[11,\"style\",\"width:100%;height:100vh;\"],[11,\"class\",\"inline-iframe\"],[11,\"frameborder\",\"0\"],[12,\"onload\",[29,\"action\",[[24,0,[]],\"stopLoading\"],null]],[11,\"target\",\"this\"],[9],[10],[0,\"\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/inline-iframe.hbs"
    }
  });

  _exports.default = _default;
});