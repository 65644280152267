define("megaportal-frontend-uq-components/templates/components/assessment-criteria", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TAUh0oVs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"panel assessment-item\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"panel__title\"],[9],[1,[25,[\"assessment\",\"assess_title\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"column medium-4 small-8\"],[9],[0,\"\\n\\n      \"],[7,\"p\"],[9],[7,\"strong\"],[9],[0,\"Course weight:\"],[10],[10],[0,\"\\n      \"],[7,\"p\"],[9],[7,\"strong\"],[9],[0,\"Your Weight: \"],[10],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column medium-2 small-4\"],[9],[0,\"\\n\\n      \"],[7,\"p\"],[9],[1,[25,[\"assessment\",\"weighting\"]],false],[0,\"%\"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[1,[23,\"courseContribution\"],false],[0,\"%\"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"column medium-4 small-8\"],[9],[0,\"\\n\\n      \"],[7,\"p\"],[9],[7,\"strong\"],[9],[0,\"Your Result: \"],[10],[10],[0,\"\\n      \"],[7,\"p\"],[9],[7,\"strong\"],[9],[0,\"Your Grade: \"],[10],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"column medium-2 small-4\"],[9],[0,\"\\n\\n      \"],[1,[29,\"input\",null,[[\"type\",\"class\",\"value\",\"maxlength\",\"focus-out\"],[\"text\",\"results-input\",[25,[\"userPercentage\"]],\"3\",[29,\"action\",[[24,0,[]],\"resultChanged\"],null]]]],false],[0,\"\\n      \"],[7,\"p\"],[9],[1,[23,\"result\"],false],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/assessment-criteria.hbs"
    }
  });

  _exports.default = _default;
});