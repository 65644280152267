define("megaportal-frontend-uq-components/services/ftp-service", ["exports", "jquery", "fetch"], function (_exports, _jquery, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MYFILES_BASE_URL = '/api/myfiles';
  var MAX_UPLOAD_FILE_SIZE = 33554432; // 32 megabytes

  var AUTO_REFRESH_INTERVAL = 120000; // 2 minutes

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    notificationService: Ember.inject.service(),
    token: null,
    maxUploadSize: MAX_UPLOAD_FILE_SIZE,
    breadcrumbs: [],
    currentDirectoryEntries: [],
    currentSelectedEntries: [],
    path: '',
    directoryTree: null,
    // If 0, then the directory does not refresh
    autoRefreshTimer: AUTO_REFRESH_INTERVAL,
    // set so that the component may clear the auto refresh interval if necessary
    autoRefreshInterval: null,
    filesSelected: false,
    filesSelectedIncludesDirectories: false,
    // Mkdir mode
    mkdirEntry: false,
    // Rename mode
    renameEntry: false,
    showUploadPanel: false,
    showDeletePanel: false,
    showMovePanel: false,
    selectedRecursiveEntry: null,
    completedFileUploads: [],
    // Loading screen lock overlay
    loading: false,
    // Observer automatically updates path from breadcrumbs
    directoryChanged: Ember.observer('breadcrumbs.[]', function () {
      var breadcrumbs = this.breadcrumbs;
      var path = '';
      breadcrumbs.forEach(function (entry) {
        path += '/' + entry.entryName;
      });
      path += '/';
      this.set('path', path);
      this.fetchByPath();
    }),
    // Observer automatically updates filesSelected
    selectedEntriesHasChanged: Ember.observer('currentSelectedEntries.[]', function () {
      this.set('filesSelectedIncludesDirectories', this.splitSelectedEntries().directories.length);

      switch (this.currentSelectedEntries.length) {
        case 0:
          this.set('filesSelected', false);
          break;

        case 1:
          this.set('filesSelected', this.currentSelectedEntries[0].entryName);
          break;

        default:
          this.set('filesSelected', this.currentSelectedEntries.length + ' files');
          break;
      }
    }).on('init'),

    /**
     * Service Setup
     */
    setup: function setup() {
      var _this = this;

      this._super.apply(this, arguments);

      var autoRefreshTimer = this.autoRefreshTimer;

      if (autoRefreshTimer) {
        var interval = this.autoRefreshInterval;

        if (!interval) {
          interval = window.setInterval(function () {
            _this.fetchByPath(false);
          }, autoRefreshTimer);
          this.set('autoRefreshInterval', interval);
        }
      }
    },

    /**
     * Set the x-val-session token
     *
     * @param token
     */
    setToken: function setToken(token) {
      this.set('token', token);
    },

    /**
     * Sort directory order
     *
     * @param {Array} data
     * @returns {Array}
     */
    orderByDirectories: function orderByDirectories(data) {
      data.sort(function (a, b) {
        return a.entryType < b.entryType;
      });
      return data;
    },
    jwtDecode: function jwtDecode(token, options) {
      if (typeof token !== "string") {
        throw new Error("Invalid token specified: must be a string");
      }

      options || (options = {});
      var pos = options.header === true ? 0 : 1;
      var part = token.split(".")[pos];

      if (typeof part !== "string") {
        throw new Error("Invalid token specified: missing part #".concat(pos + 1));
      }

      var decoded;

      try {
        decoded = atob(part);
      } catch (e) {
        throw new Error("Invalid token specified: invalid base64 for part #".concat(pos + 1, " (").concat(e.message, ")"));
      }

      try {
        return JSON.parse(decoded);
      } catch (e) {
        throw new Error("Invalid token specified: invalid json for part #".concat(pos + 1, " (").concat(e.message, ")"));
      }
    },

    /**
     * Fetch all entries in the current directory.
     *
     * @param {boolean} [showLoader=true] Whether to show loading indicator/screen lock
     * @returns {void}
     */
    fetchByPath: function fetchByPath() {
      var _this2 = this;

      var showLoader = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (showLoader) {
        this.set('loading', true);
      }

      this.validateStoredJwt();
      var url = MYFILES_BASE_URL;
      var requestUrl = this.path.length ? "".concat(url, "?url=").concat(encodeURIComponent(this.path)) : url;
      (0, _fetch.default)(requestUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.bearerToken()),
          'X-Val-Session': this.token
        }
      }).then(function (response) {
        if (!response.ok) {
          throw new Error('Could not fetch directory contents.');
        }

        return response.json();
      }).then(function (responseJson) {
        _this2.set('currentSelectedEntries', []);

        _this2.set('currentDirectoryEntries', _this2.orderByDirectories(responseJson));

        _this2.set('loading', false);
      }).catch(
      /** @param {Error} error */
      function (error) {
        // if we can't fetch the contents of a directory, make sure nothing is displayed,
        // bearing in mind that the page auto-refreshes,
        // so there might be entries from a successful earlier fetch
        _this2.set('currentSelectedEntries', []);

        _this2.set('currentDirectoryEntries', []);

        _this2.sendErrorNotification(error.message || error.toString());

        _this2.set('loading', false);
      });
    },

    /**
     * Create a new directory.
     *
     * @param {string} newDirectoryName
     * @returns {void}
     */
    mkdir: function mkdir(newDirectoryName) {
      var _this3 = this;

      this.set('loading', true);
      this.validateStoredJwt(); // Error out if directory already exists

      if (this.existsInDirectory(newDirectoryName)) {
        this.sendErrorNotification("The directory \"".concat(newDirectoryName, "\" already exists in this path."));
        this.set('loading', false);
        return;
      }

      (0, _fetch.default)("".concat(MYFILES_BASE_URL, "/mkdir"), {
        method: 'POST',
        headers: {
          'Authorization': "Bearer ".concat(this.bearerToken()),
          'X-Val-Session': this.token
        },
        body: JSON.stringify({
          currentDirectory: this.path,
          directoryName: newDirectoryName
        })
      }).then(function (response) {
        if (!response.ok) {
          throw new Error('Could not create directory.');
        }

        return response.json();
      }).then(function (responseJson) {
        _this3.set('currentDirectoryEntries', _this3.orderByDirectories(responseJson));

        _this3.sendSuccessNotification("Successfully created new folder \"".concat(newDirectoryName, "\""));

        _this3.set('mkdirEntry', false);

        _this3.set('loading', false);
      }).catch(
      /** @param {Error} error */
      function (error) {
        _this3.sendErrorNotification(error.message || error.toString());

        _this3.set('mkdirEntry', false);

        _this3.set('loading', false);
      });
    },

    /**
     * Delete files and/or directories.
     *
     * @returns {void}
     */
    delete: function _delete() {
      var _this4 = this;

      this.set('loading', true);
      this.validateStoredJwt();
      var entriesToRemove = this.splitSelectedEntries();
      var url = "".concat(MYFILES_BASE_URL, "/mixedRemove?files=").concat(this.encodeArrayPath(entriesToRemove.files)) + "&directories=".concat(this.encodeArrayPath(entriesToRemove.directories)) + "&path=".concat(this.path);
      (0, _fetch.default)(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.bearerToken()),
          'X-Val-Session': this.token
        }
      }).then(function (response) {
        if (!response.ok) {
          throw new Error('Could not delete selection.');
        }

        return response.json();
      }).then(function (responseJson) {
        _this4.sendSuccessNotification("Successfully removed ".concat(_this4.currentSelectedEntries.length, " item(s) from the current folder."));

        _this4.set('currentSelectedEntries', []);

        _this4.set('currentDirectoryEntries', _this4.orderByDirectories(responseJson));

        _this4.set('showDeletePanel', false);

        _this4.set('loading', false);
      }).catch(
      /** @param {Error} error */
      function (error) {
        _this4.sendErrorNotification(error.message || error.toString());

        _this4.set('showDeletePanel', false);

        _this4.set('loading', false);
      });
    },
    encodeArrayPath: function encodeArrayPath(data) {
      var _this5 = this;

      var parts = [];
      var encodedString;

      if (data instanceof Array) {
        data.forEach(function (value) {
          parts.push(_this5.b64EncodeUnicode(value));
        });
        encodedString = parts.join(',');
      } else {
        encodedString = this.b64EncodeUnicode(data);
      } // Now that we have a base64 encoded string, do a URI encode to handle special characters (,/?:@&=+$#)


      return encodeURIComponent(encodedString);
    },
    b64EncodeUnicode: function b64EncodeUnicode(str) {
      // first we use encodeURIComponent to get percent-encoded UTF-8,
      // then we convert the percent encodings into raw bytes which
      // can be fed into btoa.
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
    },

    /**
     * Download one or more files.
     *
     * @returns {void}
     */
    download: function download() {
      var _this6 = this;

      this.set('loading', true);
      this.validateStoredJwt();
      var downloads = this.splitSelectedEntries();
      var url = "".concat(MYFILES_BASE_URL, "/download") + '?path=' + this.path + '&files=' + this.encodeArrayPath(downloads.files) + '&directories=' + this.encodeArrayPath(downloads.directories);
      (0, _fetch.default)(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization': "Bearer ".concat(this.bearerToken()),
          'X-Val-Session': this.token
        }
      }).then(function (response) {
        if (!response.ok) {
          throw new Error('Could not download selection.');
        }

        return response.blob();
      }).then(function (blob) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE11
          window.navigator.msSaveOrOpenBlob(blob, 'MyFiles.zip');
        } else {
          // HTML5
          var _url = window.URL.createObjectURL(blob);

          var a = document.createElement('a');
          a.href = _url;
          a.download = 'MyFiles.zip';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(a.href);
          a.remove();
        }

        _this6.set('currentSelectedEntries', []);

        _this6.set('loading', false);
      }).catch(
      /** @param {Error} error */
      function (error) {
        _this6.set('currentSelectedEntries', []);

        _this6.sendErrorNotification(error.message || error.toString());

        _this6.set('loading', false);
      });
    },

    /**
     * Upload one or more files.
     *
     * @param {FileList} files
     * @returns {void}
     */
    upload: function upload(files) {
      var _this7 = this;

      this.set('loading', true);
      this.validateStoredJwt();
      var url = "".concat(MYFILES_BASE_URL, "/uploadMultiple");
      var formData = new FormData(); // Maximum file size pre-flight check

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = files[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var file = _step.value;

          if (file.size < this.maxUploadSize) {
            formData.append(file.name, file, file.name);
          } else {
            this.sendErrorNotification("\"".concat(file.name, "\" is too big to upload. Please limit your file size to 32MB or less."));
            this.set('showUploadPanel', false);
            this.set('loading', false);
            return;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      formData.append('currentDir', this.path);
      (0, _fetch.default)(url, {
        method: 'POST',
        headers: {
          'Authorization': "Bearer ".concat(this.bearerToken()),
          'X-Val-Session': this.token
        },
        body: formData
      }).then(function (response) {
        if (!response.ok) {
          throw new Error('Could not upload file(s).');
        }

        return response.json();
      }).then(function (responseJson) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = files[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var file = _step2.value;

            _this7.completedFileUploads.pushObject(file);
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        _this7.set('currentDirectoryEntries', _this7.orderByDirectories(responseJson));

        _this7.set('loading', false);
      }).catch(
      /** @param {Error} error */
      function (error) {
        _this7.sendErrorNotification(error.message || error.toString());

        _this7.set('showUploadPanel', false);

        _this7.set('loading', false);
      });
    },

    /**
     * Rename a file.
     *
     * @param entry
     * @param {string} newName
     * @returns {void}
     */
    rename: function rename(entry, newName) {
      var _this8 = this;

      this.set('loading', true);
      this.validateStoredJwt(); // Don't allow files with filename length over 100 characters

      if (newName.length > 100) {
        this.sendErrorNotification('Please limit your new filename to 100 characters or less.');
        this.set('loading', false);
        return;
      }

      (0, _fetch.default)("".concat(MYFILES_BASE_URL, "/rename"), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.bearerToken()),
          'X-Val-Session': this.token
        },
        body: JSON.stringify({
          currentPath: this.path,
          currentName: entry.entryName,
          newName: newName
        })
      }).then(function (response) {
        if (!response.ok) {
          throw new Error('Could not rename file.');
        }

        return response.json();
      }).then(function (responseJson) {
        if (newName.length > 30) {
          newName = newName.substr(0, 30) + '...';
        }

        if (entry.entryName.length > 30) {
          Ember.set(entry, 'entryName', entry.entryName.substr(0, 30) + '...');
        }

        _this8.sendSuccessNotification("Successfully renamed \"".concat(entry.entryName, "\" to \"").concat(newName, "\""));

        _this8.set('renameEntry', false);

        _this8.set('currentSelectedEntries', []);

        _this8.set('currentDirectoryEntries', _this8.orderByDirectories(responseJson));

        _this8.set('loading', false);
      }).catch(
      /** @param {Error} error */
      function (error) {
        _this8.set('currentSelectedEntries', []);

        _this8.sendErrorNotification(error.message || error.toString());

        _this8.set('renameEntry', false);

        _this8.set('loading', false);
      });
    },

    /**
     * Get Tree View
     *
     * Retrieves a recursive directory listing
     * @returns {void}
     */
    getTreeView: function getTreeView() {
      var _this9 = this;

      this.set('loading', true);
      this.validateStoredJwt();
      (0, _fetch.default)("".concat(MYFILES_BASE_URL, "/getTreeView"), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.bearerToken()),
          'X-Val-Session': this.token
        }
      }).then(function (response) {
        return response.json();
      }).then(function (resp) {
        _this9.set('showMovePanel', true);

        _this9.currentSelectedEntries.forEach(function (entry) {
          _jquery.default.each(resp, function (key, directory) {
            _this9.addNonSelectableClasses(key, directory, entry.entryName);
          });
        });

        _this9.set('directoryTree', [{
          name: 'MyFiles',
          path: '',
          children: resp
        }]);

        _this9.set('loading', false);
      });
    },

    /**
     * Add non-selectable classes
     *
     * Recursively checks directories to match selected names and extends object with non-selectable property.
     *
     * @param key
     * @param directory
     * @param selectedName
     */
    addNonSelectableClasses: function addNonSelectableClasses(key, directory, selectedName) {
      var _this10 = this;

      if (String(directory.name) === String(selectedName)) {
        _jquery.default.extend(directory['children'], {
          nonselectable: true
        });
      }

      if (directory['children']) {
        _jquery.default.each(directory['children'], function (key, directory) {
          _this10.addNonSelectableClasses(key, directory, selectedName);
        });
      }
    },

    /**
     * Move one or more files.
     *
     * @returns {void}
     */
    move: function move() {
      var _this11 = this;

      this.set('loading', true);
      this.validateStoredJwt();
      (0, _fetch.default)("".concat(MYFILES_BASE_URL, "/move"), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer ".concat(this.bearerToken()),
          'X-Val-Session': this.token
        },
        body: JSON.stringify({
          files: this.currentSelectedEntries,
          currentPath: this.path,
          newPath: this.selectedRecursiveEntry.path
        })
      }).then(function (response) {
        if (!response.ok) {
          throw new Error('Could not move file(s).');
        }

        return response.json();
      }).then(function (responseJson) {
        _this11.sendSuccessNotification("Successfully moved item to \"".concat(_this11.selectedRecursiveEntry.name, "\""));

        _this11.set('showMovePanel', false);

        _this11.set('currentSelectedEntries', []);

        _this11.set('currentDirectoryEntries', _this11.orderByDirectories(responseJson));

        _this11.set('loading', false);
      }).catch(
      /** @param {Error} error */
      function (error) {
        _this11.set('currentSelectedEntries', []);

        _this11.sendErrorNotification(error.message || error.toString());

        _this11.set('showMovePanel', false);

        _this11.set('loading', false);
      });
    },

    /**
     * Split selected entries
     * @returns {{files: Array, directories: Array}}
     */
    splitSelectedEntries: function splitSelectedEntries() {
      var files = [];
      var directories = [];
      var selectedEntries = this.currentSelectedEntries;

      if (selectedEntries instanceof Array) {
        selectedEntries.forEach(function (entry) {
          if (entry.entryType === 'folder') {
            directories.push(entry.entryName);
          } else {
            files.push(entry.entryName);
          }
        });
      }

      return {
        files: files,
        directories: directories
      };
    },

    /**
     * Check if file or directory name exists in the current directory.
     *
     * @param {string} filename
     * @returns {boolean}
     */
    existsInDirectory: function existsInDirectory(filename) {
      return this.currentDirectoryEntries.some(function (entry) {
        return entry.entryName === filename;
      });
    },

    /**
     * Remove stored JWT from local storage if expired.
     *
     * @returns {void}
     */
    validateStoredJwt: function validateStoredJwt() {
      var jwt = localStorage.getItem('authHeader');

      if (jwt) {
        var token = this.jwtDecode(jwt); // remove cached token if expired (currently 48 hours)

        if (token.exp < Date.now() / 1000) {
          localStorage.removeItem('authHeader');
        }
      }
    },

    /**
     * Helper for getting a bearer token from either local storage or the session.
     *
     * @return {string}
     */
    bearerToken: function bearerToken() {
      return localStorage.getItem('authHeader') || this.session.session.content.authenticated.token;
    },

    /**
     * Helper for posting a success notification in the relevant channel.
     *
     * @param {string} message
     */
    sendSuccessNotification: function sendSuccessNotification(message) {
      this.notificationService.newNotification('success', message, 'myFiles');
    },

    /**
     * Helper for posting an error notification in the relevant channel.
     *
     * @param {string} message
     */
    sendErrorNotification: function sendErrorNotification(message) {
      this.notificationService.newNotification('error', message, 'myFiles');
    }
  });

  _exports.default = _default;
});