define("megaportal-frontend-uq-components/templates/components/calendar-view/mobile/dashboard-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JjrZM583",
    "block": "{\"symbols\":[\"event\"],\"statements\":[[7,\"div\"],[11,\"class\",\"day-events-container-mobile\"],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"firstEvent\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"panel\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"panel__content\"],[9],[0,\"\\n          You have no upcoming scheduled classes today.\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"firstEvent\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"panel\"],[9],[0,\"\\n      \"],[7,\"h3\"],[11,\"class\",\"panel__title\"],[9],[0,\"Now\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[29,\"calendar-view/mobile/event-block\",null,[[\"event\"],[[25,[\"firstEvent\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,[\"otherEvents\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"panel\"],[9],[0,\"\\n      \"],[7,\"h3\"],[11,\"class\",\"panel__title\"],[9],[0,\"Next\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"otherEvents\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"calendar-view/mobile/event-block\",null,[[\"event\"],[[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/calendar-view/mobile/dashboard-column.hbs"
    }
  });

  _exports.default = _default;
});