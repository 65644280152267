define("megaportal-frontend-uq-components/templates/components/ims-logout-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cIB23+S6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"modal__panel\"],[9],[0,\"\\n\\n  \"],[7,\"a\"],[11,\"class\",\"close icon icon-close\"],[9],[3,\"action\",[[24,0,[]],\"close\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n\\n  \"],[7,\"h3\"],[9],[0,\"Log out\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"Are you sure you want to log out of this internet session?\"],[10],[0,\"\\n\\n  \"],[7,\"button\"],[11,\"class\",\"button button--tertiary\"],[9],[0,\"Log out\"],[3,\"action\",[[24,0,[]],\"logout\"]],[10],[0,\"   \"],[7,\"button\"],[11,\"class\",\"button button--primary\"],[9],[0,\"Cancel\"],[3,\"action\",[[24,0,[]],\"close\"]],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"],[15,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/ims-logout-modal.hbs"
    }
  });

  _exports.default = _default;
});