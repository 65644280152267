define("megaportal-frontend-uq-components/templates/components/calendar-view/time-gutter-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2j4BP7xG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"time-gutter-indicator \",[25,[\"time\",\"value\"]]]]],[9],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"shouldDisplay\"]]],null,{\"statements\":[[0,\"\\n        \"],[1,[25,[\"time\",\"label\"]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"    \\n\"],[10],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/calendar-view/time-gutter-indicator.hbs"
    }
  });

  _exports.default = _default;
});