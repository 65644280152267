define("megaportal-frontend-uq-components/helpers/sanitize-html", ["exports", "sanitize-html"], function (_exports, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sanitize = sanitize;
  _exports.default = void 0;

  function sanitize(param) {
    // console.log('### sanitize helper function - about to sanitize param: ', param);
    var clean = '';

    if (param) {
      clean = (0, _sanitizeHtml.default)(param);
    }

    return clean;
  }

  var _default = Ember.Helper.helper(sanitize);

  _exports.default = _default;
});