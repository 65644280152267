define("megaportal-frontend-uq-components/components/course-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    courseCode: '',
    actions: {
      courseChanged: function courseChanged(value) {
        var _this = this;

        var courses = this.model;

        if (!(courses instanceof Array)) {
          courses = Object.values(this.model);
        }

        courses.forEach(function (entry) {
          if (entry.course_code === value) {
            Ember.run.scheduleOnce('afterRender', _this, function () {
              _this.sendAction('courseChanged', value, entry.term_code, entry.class_nbr);
            });
            return true;
          }
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      var courses = this.model;

      if (!(courses instanceof Array)) {
        courses = Object.values(this.model);
      }

      if (courses && courses[0]) {
        this.send('courseChanged', courses[0].course_code);
      }
    }
  });

  _exports.default = _default;
});