define("megaportal-frontend-uq-components/templates/components/ftp-client-upload-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vK5gEf+r",
    "block": "{\"symbols\":[\"file\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"upload-panel\"],[9],[0,\"\\n\\t\"],[7,\"a\"],[11,\"class\",\"close icon icon-close\"],[9],[3,\"action\",[[24,0,[]],\"cancel\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n\"],[4,\"unless\",[[25,[\"ftpService\",\"completedFileUploads\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"h3\"],[9],[0,\"File upload\"],[10],[0,\"\\n\\t\"],[7,\"p\"],[9],[0,\"\\n\\tYou can select more than one file to upload at a time. You can also drag and drop files to upload.\\n\\t\"],[10],[0,\"\\n\\t\"],[7,\"button\"],[11,\"class\",\"button button--tertiary\"],[9],[0,\"Select files\"],[3,\"action\",[[24,0,[]],\"selectFiles\"]],[10],[7,\"button\"],[11,\"class\",\"button button-secondary\"],[9],[0,\"Cancel\"],[3,\"action\",[[24,0,[]],\"cancel\"]],[10],[0,\"\\n\\t\\t\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\"],[7,\"h3\"],[9],[0,\"Successfully uploaded files!\"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"ftpService\",\"completedFileUploads\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"p\"],[9],[7,\"a\"],[11,\"class\",\" successful-entry icon icon-file icon--linked\"],[9],[10],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[7,\"button\"],[11,\"class\",\"button button--tertiary\"],[9],[0,\"Done\"],[3,\"action\",[[24,0,[]],\"cancel\"]],[10],[7,\"button\"],[11,\"class\",\"button button-secondary\"],[9],[0,\"Add more files\"],[3,\"action\",[[24,0,[]],\"selectFiles\"]],[10],[0,\"\\n\\t\\t\\n\\t\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[7,\"form\"],[11,\"enctype\",\"multipart/form-data\"],[9],[0,\"\\n\\t\"],[7,\"input\"],[11,\"class\",\"ftp-client-upload-hidden\"],[11,\"name\",\"files[]\"],[11,\"multiple\",\"\"],[11,\"type\",\"file\"],[9],[3,\"action\",[[24,0,[]],\"uploadFiles\"],[[\"on\"],[\"change\"]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[15,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/ftp-client-upload-modal.hbs"
    }
  });

  _exports.default = _default;
});