define("megaportal-frontend-uq-components/templates/components/fullscreen-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wOqQRJop",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"spinner-overlay\"],[11,\"role\",\"presentation\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"loader__container\"],[9],[7,\"div\"],[11,\"class\",\"loader loader--light\"],[9],[0,\"Loading...\"],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/fullscreen-loader.hbs"
    }
  });

  _exports.default = _default;
});