define("megaportal-frontend-uq-components/helpers/if-not-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ifNotNull = ifNotNull;
  _exports.default = void 0;

  function ifNotNull(params
  /*, hash*/
  ) {
    if (params[0] && params[0] !== '0') {
      return params;
    }

    return '--';
  }

  var _default = Ember.Helper.helper(ifNotNull);

  _exports.default = _default;
});