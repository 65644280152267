define("megaportal-frontend-uq-components/app", ["exports", "jquery", "megaportal-frontend-uq-components/resolver", "ember-load-initializers", "megaportal-frontend-uq-components/config/environment"], function (_exports, _jquery, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    engines: {
      megaportalFrontendEngineTimetable: {
        dependencies: {
          services: ['auth-manager', 'config-service', 'feature-service', 'media', 'timetable-service', 'session'],
          externalRoutes: {
            'dashboard': 'dashboard'
          }
        }
      },
      megaportalFrontendEngineMyprofile: {
        dependencies: {
          services: ['config-service', 'feature-service', 'auth-manager', 'identity-service', 'session'],
          externalRoutes: {
            'login': 'login'
          }
        }
      }
    },
    ready: function ready() {
      (0, _jquery.default)('#megaportal-app-loading').remove();
    }
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});