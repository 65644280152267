define("megaportal-frontend-uq-components/routes/apps/my-requests/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notificationService: Ember.inject.service(),
    setupController: function setupController(controller, error) {
      //Remove any route names that might have been set for failed route.
      sessionStorage.removeItem('routeName');
      this.set("notificationService.loading", false);
      return this._super(controller, error);
    }
  });

  _exports.default = _default;
});