define("megaportal-frontend-uq-components/routes/apps/my-requests/task", ["exports", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin", "megaportal-frontend-uq-components/models/unitask"], function (_exports, _myuqAuthenticatedRouteMixin, _unitask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    model: function model(params) {
      return _unitask.default.fetchTask(params.id);
    },
    actions: {
      error: function error() {
        //if we get an error, probably an invalid task number, return to the task listing.
        this.transitionTo("apps.my-requests");
      }
    }
  });

  _exports.default = _default;
});