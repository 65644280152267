define("megaportal-frontend-uq-components/components/private-browsing-warning", ["exports", "jquery", "megaportal-frontend-uq-components/utils/fastboot", "megaportal-frontend-uq-components/utils/safe-local-storage"], function (_exports, _jquery, _fastboot, _safeLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      if (!(0, _fastboot.isFastBoot)() && !(0, _safeLocalStorage.checkLocalStorage)()) {
        if (window.navigator.userAgent.includes('Safari')) {
          if ((0, _jquery.default)(document).foundation) {
            (0, _jquery.default)('#privateBrowsingModal').foundation('reveal', 'open');
          }
        }
      }
    },
    actions: {
      closeModal: function closeModal() {
        if ((0, _jquery.default)(document).foundation) {
          (0, _jquery.default)('#privateBrowsingModal').foundation('reveal', 'close');
        }
      }
    }
  });

  _exports.default = _default;
});