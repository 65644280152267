define("megaportal-frontend-uq-components/components/inline-iframe", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notificationService: Ember.inject.service(),
    previousiFrameHeight: 0,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)("#MyUQinlineIframe").iFrameResize({
          checkOrigin: ['https://task-dev.bpd.aws.uq.edu.au', 'https://task-test.bpd.aws.uq.edu.au', 'https://task-staging.bpd.aws.uq.edu.au', 'https://unitask.uq.edu.au', 'https://portal.my.uq.edu.au', 'https://its-ss-megaportal-dev.aws.uq.edu.au', 'https://its-ss-megaportal-test.aws.uq.edu.au', 'https://its-ss-megaportal-staging.aws.uq.edu.au', 'https://cahp.uq.edu.au'],
          log: false,
          // Enable console logging
          //heightCalculationMethod: 'bodyOffset', //not suitable
          //heightCalculationMethod: 'bodyScroll',
          //heightCalculationMethod: 'documentElementOffset', //not suitable
          //heightCalculationMethod: 'documentElementScroll',
          //heightCalculationMethod: 'max',
          //heightCalculationMethod: 'min', //not suitable
          //heightCalculationMethod: 'grow', //not ideal
          heightCalculationMethod: 'lowestElement',
          //heightCalculationMethod: 'taggedElement', //have not tested
          onResized: function onResized(iframe) {
            // Callback fn when resize is received
            // console.table(messageData);
            if ((0, _jquery.default)(document).foundation) {
              (0, _jquery.default)(document).foundation('equalizer', 'reflow');
            }

            var previousiFrameHeight = self.get("previousiFrameHeight");
            self.set("previousiFrameHeight", iframe.height); //if the iframe height has suddenly reduced by 90% or more, lets scroll to the top also

            if (previousiFrameHeight / iframe.height > 10) {
              self.send("scrollTo", 0, 0);
            }
          },
          onScroll: function onScroll(coords) {
            self.send("scrollTo", coords.x, coords.y);
            return false;
          }
        });
      });
    },
    actions: {
      scrollTo: function scrollTo(x, y) {
        (0, _jquery.default)('html, body').animate({
          scrollTop: y,
          scrollLeft: x
        });
      },
      stopLoading: function stopLoading() {
        this.set("notificationService.loading", false);
      }
    }
  });

  _exports.default = _default;
});