define("megaportal-frontend-uq-components/templates/components/ftp-client-entry-recursive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d3rcQVBT",
    "block": "{\"symbols\":[\"childDirectory\",\"&default\"],\"statements\":[[7,\"li\"],[11,\"class\",\"ftp-client-entry-recursive\"],[9],[0,\"\\n\\t\"],[7,\"span\"],[9],[7,\"a\"],[11,\"class\",\"entry-type icon icon-folder icon--linked\"],[9],[10],[1,[25,[\"directory\",\"name\"]],false],[3,\"action\",[[24,0,[]],\"selectThis\"],[[\"on\"],[\"mouseUp\"]]],[10],[0,\"\\n\\t\"],[7,\"ul\"],[9],[0,\"\\n\\t\\t\"],[2,\"for each of the root directory entries, in turn create the same component, recursively\"],[0,\"\\n\"],[4,\"each\",[[25,[\"directory\",\"children\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[29,\"ftp-client-entry-recursive\",null,[[\"directory\"],[[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[15,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/ftp-client-entry-recursive.hbs"
    }
  });

  _exports.default = _default;
});