define("megaportal-frontend-uq-components/components/calendar-view/time-gutter-indicator", ["exports", "megaportal-frontend-uq-components/templates/components/calendar-view/time-gutter-indicator"], function (_exports, _timeGutterIndicator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _timeGutterIndicator.default,
    shouldDisplay: Ember.computed('hiddenIndicator', function () {
      return this.get("time.value") != this.hiddenIndicator;
    })
  });

  _exports.default = _default;
});