define("megaportal-frontend-uq-components/routes/apps/events/view", ["exports", "megaportal-frontend-uq-components/models/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var id = _ref.id;
      // Redirect to new site if someone managed to get here.
      // Does cause a render flash, but ideally no one will get here anyway.
      return window.location.href = "https://www.uq.edu.au/uq-events/"; // return Events.fetchById(id);
    }
  });

  _exports.default = _default;
});