define("megaportal-frontend-uq-components/templates/components/course-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ccop99ZT",
    "block": "{\"symbols\":[\"course\",\"index\",\"&default\"],\"statements\":[[7,\"select\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"courseChanged\"],[[\"value\"],[\"target.value\"]]]],[12,\"class\",[30,[[29,\"unless\",[[25,[\"class\"]],\"course-dropdown\"],null]]]],[9],[0,\"\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"option\"],[12,\"value\",[24,1,[\"course_code\"]]],[9],[1,[24,1,[\"course_code\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"],[15,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/course-dropdown.hbs"
    }
  });

  _exports.default = _default;
});