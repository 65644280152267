define("megaportal-frontend-uq-components/routes/email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    authManager: Ember.inject.service(),
    featureService: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return this.authManager.waitForAccount().then(function () {
        return Ember.RSVP.hash({
          accountData: _this.authManager.accountData,
          availableFeatures: _this.featureService.availableFeatures
        });
      });
    }
  });

  _exports.default = _default;
});