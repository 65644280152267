define("megaportal-frontend-uq-components/router", ["exports", "jquery", "megaportal-frontend-uq-components/config/environment", "megaportal-frontend-uq-components/engines", "megaportal-frontend-uq-components/metaTags"], function (_exports, _jquery, _environment, _engines, _metaTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    transitionHistoryService: Ember.inject.service(),
    headData: Ember.inject.service(),
    authManager: Ember.inject.service(),
    updateTitleAndDescription: function (_ref) {
      var targetName = _ref.targetName;
      var headData = this.get('headData'); // Find best matching metaTags
      // (e.g. if targetName is apps.myfiles.login, closest match may be apps.myfiles)

      var metaDataName = Object.keys(_metaTags.default) // Sort by string length so first match will be closest match
      .sort(function (nameA, nameB) {
        return nameB.length - nameA.length;
      }).find(function (tagName) {
        return targetName.includes(tagName);
      });

      if (metaDataName === undefined) {
        metaDataName = 'default';
      }

      var pageMetaData = _metaTags.default[metaDataName];
      headData.set('title', pageMetaData.title);
      headData.set('description', pageMetaData.description);
    }.on('willTransition'),
    reflowEqualizer: function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        setTimeout(function () {
          if ((0, _jquery.default)(document).foundation) {
            (0, _jquery.default)(document).foundation('equalizer', 'reflow');
          }
        }, 200);
      });
    }.on('didTransition'),
    updateFoundation: function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        //Enable Foundation
        if ((0, _jquery.default)(document).foundation) {
          (0, _jquery.default)(document).foundation({
            tab: {
              callback: function callback() {
                if ((0, _jquery.default)(document).foundation) {
                  (0, _jquery.default)(document).foundation('equalizer', 'reflow');
                }
              }
            },
            abide: {
              timeout: 200
            }
          });
        } //Scroll to top


        window.scrollTo(0, 0); //Do a reflow

        if ((0, _jquery.default)(document).foundation) {
          (0, _jquery.default)(document).foundation('equalizer', 'reflow');
        }
      });
    }.on("didTransition"),
    metrics: Ember.inject.service(),
    notifyGoogleAnalytics: function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        return _this.authManager.waitForAccount().then(function () {
          var page = _this.url;

          var title = _this.getWithDefault('currentRouteName', 'unknown');

          var dimension1 = _this.get('authManager.userTypeForReporting'); //Dimension 1 is user type custom dimension
          //console.log("GoogleAnalyticsTrackPageView", { page, title , dimension1});


          Ember.get(_this, 'metrics').trackPage({
            page: page,
            title: title,
            dimension1: dimension1
          });
        });
      });
    }.on('didTransition')
  });
  Router.map(function () {
    var _this2 = this;

    this.route('apps', function () {
      this.route('courses', function () {
        this.route('view', {
          path: "/:id/:strm/:class_nbr"
        });
      }); // TODO: Remove this in https://jira.its.uq.edu.au/jira/browse/ITSADSSD-22369
      // this.route('course-cuttoffs', function() {
      //   this.route('upload');
      //   this.route('list');
      //   this.route('view', { path: "/:id/:strm/:class_nbr" });
      // });

      this.route('ims', function () {
        this.route('view');
      });
      this.route('library');
      this.route('settings', function () {
        this.route('features');
        this.route('features-admin');
      });
      this.route('events', function () {
        this.route('view', {
          path: "/:id"
        });
      });
      this.route('notifications', function () {
        this.route('alert', {
          path: "alert/:id"
        });
      });
      this.route('my-requests', function () {
        this.route('task', {
          path: "task/:id"
        });
        this.route('case', {
          path: "case/:id"
        }, function () {
          this.route('backwards_compatible_archived_flag', {
            path: "/:archived"
          });
        });
        this.route('make-request', function () {
          this.route('view', {
            path: "/:processName"
          });
        });
        this.route('process', function () {
          this.route('view', {
            path: "/:processName"
          });
        });
      });
      this.route('myfiles', function () {
        this.route('login');
      });
      this.route('staff-courses', function () {});
      this.route('library-error');
    });
    this.route('dashboard');
    this.route('login');
    this.route('email');
    this.route('myfiles', function () {
      this.route('staff');
    });
    this.route('logout');
    this.route('catchAll', {
      path: '*:'
    });

    _engines.default.forEach(function (_ref2) {
      var name = _ref2.name,
          as = _ref2.as,
          path = _ref2.path;

      _this2.mount(name, {
        as: as,
        path: path
      });
    });

    this.route('my-requests', function () {});
  });
  var _default = Router;
  _exports.default = _default;
});