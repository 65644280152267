define("megaportal-frontend-uq-components/services/notification-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    loading: false,
    router: Ember.inject.service('-routing'),
    // Concurrent notifications by channel. It will hold a stack of notifications from all subsidiary apps in myUQ.
    channels: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('channels', {
        global: []
      });
    },

    /**
     * @param {'success'|'info'|'warning'|'error'} type
     * @param {string} message
     * @param {string} [channel='global'] Notification channel
     */
    newNotification: function newNotification(type, message) {
      var channel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'global';

      // create new channel if it doesn't exist
      if (typeof this.channels[channel] === 'undefined') {
        this.set("channels.".concat(channel), []);
      }

      this.channels[channel].pushObject({
        type: type,
        body: message,
        channel: channel
      });
    },
    removeNotification: function removeNotification(notification) {
      this.channels[notification.channel].removeObject(notification);
    },

    /**
     * Clear an entire channel of messages.
     *
     * @param {string} [channel='global'] Notification channel to clear
     */
    clearMessages: function clearMessages() {
      var channel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'global';
      this.set("channels.".concat(channel), []);
    }
  });

  _exports.default = _default;
});