define("megaportal-frontend-uq-components/templates/components/modal-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GAk351i1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"modal-message-overlay\"],[11,\"role\",\"presentation\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-message-panel\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"close-modal\"],[9],[0,\"❌\"],[3,\"action\",[[24,0,[]],\"closeMessage\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n    \"],[7,\"h3\"],[9],[0,\"Thank you for submitting your request.\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[15,1],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/modal-message.hbs"
    }
  });

  _exports.default = _default;
});