define("megaportal-frontend-uq-components/routes/apps/settings/features-admin", ["exports", "jquery", "megaportal-frontend-uq-components/config/environment", "megaportal-frontend-uq-components/models/feature", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin"], function (_exports, _jquery, _environment, _feature, _myuqAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    authManager: Ember.inject.service(),
    featureService: Ember.inject.service(),
    model: function model() {
      return {
        features: this.featureService.features,
        featureUsers: this.featureService.featureUsers,
        featureUsersCount: this.featureService.featureUsersCount
      };
    },
    featuresChangeObserver: Ember.observer('model.features.[]', function () {
      this.reflow();
    }),
    reflow: function reflow() {
      Ember.run.next(function () {
        if ((0, _jquery.default)(document).foundation) {
          (0, _jquery.default)(document).foundation('equalizer', 'reflow');
        }
      });
      setTimeout(function () {
        Ember.run.next(function () {
          if ((0, _jquery.default)(document).foundation) {
            (0, _jquery.default)(document).foundation('equalizer', 'reflow');
          }
        });
      }, 1000);
    },
    actions: {
      deploy: function deploy(featureId) {
        var _this = this;

        var feature = this.featureService.features.unreleasedFeatures[featureId];

        _feature.default.createFeature(Object.assign({
          stage: _environment.default.STAGE,
          featureId: featureId
        }, feature)).then(function () {
          _this.featureService.loadFeatures().then(function () {
            _this.reflow();
          });
        });
      },
      restrict: function restrict(featureId) {
        var _this2 = this;

        var feature = this.featureService.features.unrestrictedFeatures[featureId];

        _feature.default.restrictFeature(_environment.default.STAGE, feature).then(function () {
          _this2.featureService.loadFeatures().then(function () {
            _this2.reflow();
          });
        });
      },
      unrestrict: function unrestrict(featureId) {
        var _this3 = this;

        var feature = this.featureService.features.restrictedFeatures[featureId];

        _feature.default.unrestrictFeature(_environment.default.STAGE, feature).then(function () {
          _this3.featureService.loadFeatures().then(function () {
            _this3.reflow();
          });
        });
      },
      undeploy: function undeploy(featureId) {
        var _this4 = this;

        _feature.default.deleteFeature(_environment.default.STAGE, featureId).then(function () {
          _this4.featureService.loadFeatures().then(function () {
            _this4.reflow();
          });
        });
      },
      remove: function remove(featureId) {
        var _this5 = this;

        _feature.default.deleteFeature(_environment.default.STAGE, featureId).then(function () {
          _this5.featureService.loadFeatures().then(function () {
            _this5.reflow();
          });
        });
      },
      removeUserFeature: function removeUserFeature(data) {
        var _this6 = this;

        _feature.default.deleteUserFeature(data).then(function () {
          _this6.featureService.findFeatureUsers(data.featureId).then(function () {
            _this6.reflow();
          });
        });
      },
      fetchUsersOfFeature: function fetchUsersOfFeature(featureId) {
        var _this7 = this;

        this.featureService.findFeatureUsers(featureId).then(function () {
          _this7.reflow();
        });
      },
      loadFeatureUsersCount: function loadFeatureUsersCount() {
        var _this8 = this;

        if (typeof this.get('featureService.featureUsersCount') === 'undefined' || Object.keys(this.get('featureService.featureUsersCount')).length === 0) {
          this.featureService.loadFeatureUsersCount().then(function () {
            _this8.reflow();
          });
        } else {
          this.reflow();
        }
      }
    }
  });

  _exports.default = _default;
});