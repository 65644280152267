define("megaportal-frontend-uq-components/components/list-pagination", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    viewFilter: 'active',
    relatedTo: 'all',
    itemsPerPage: 50,
    // Number of cases to display per page
    pageIndex: 1,
    // Number of pages to skip, changed by back/next buttons
    itemsPerPageOptions: [5, 20, 50, 100],
    pagedCases: Ember.computed('sortedCases', 'itemsPerPage', 'pageIndex', function () {
      // parseInt() required as the value is a string coming from an <option/>
      var itemsPerPage = parseInt(this.itemsPerPage, 10);
      var offset = itemsPerPage * (this.pageIndex - 1);
      return this.sortedCases.slice(offset, offset + itemsPerPage);
    }),
    sortedProcesses: Ember.computed('model.overview.processes', function () {
      var processes = this.get("model.overview.processes");

      if (_jquery.default.isEmptyObject(processes)) {
        return [];
      }

      var sortedProcesses = processes.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      return sortedProcesses;
    }),
    // Pagination: number of pages in total (1-based)
    pageCount: Ember.computed('sortedCases', 'itemsPerPage', function () {
      var sortedCases = this.sortedCases.length;
      var itemsPerPage = this.itemsPerPage;

      if (sortedCases % itemsPerPage === 0 && sortedCases !== 0) {
        return sortedCases / itemsPerPage;
      }

      return Math.floor(sortedCases / itemsPerPage) + 1;
    }),
    // Pagination: array of valid page numbers (1-based)
    // This is because the template can't do a for loop, only iterate over an
    // array, so we'll supply an array of valid page numbers.
    // The returned array is a list of boolean, true if that index is the current
    // page.
    allPageNumbers: Ember.computed('pageCount', 'pageIndex', function () {
      var pageIndex = this.pageIndex;
      var pageCount = this.pageCount;
      var pages = {};

      for (var i = 1; i <= pageCount; i++) {
        pages[i] = i === pageIndex;
      }

      return pages;
    }),
    // Pagination: true if this is the first page (to disable 'prev' link)
    isFirstPage: Ember.computed('pageIndex', function () {
      return this.pageIndex <= 1;
    }),
    // Pagination: true if this is the last page (to disable 'next' link)
    isLastPage: Ember.computed('pageIndex', 'pageCount', function () {
      return this.pageIndex >= this.pageCount;
    }),
    actions: {
      // Change the number of items shown on each page
      setItemsPerPage: function setItemsPerPage(newValue) {
        this.set('itemsPerPage', parseInt(newValue, 10));
        this.send('jumpPage', 1);
      },
      // Show the next page of cases
      nextPage: function nextPage() {
        var offset = parseInt(this.pageIndex);
        this.send('jumpPage', offset + 1);
      },
      // Show the previous page of cases
      prevPage: function prevPage() {
        var offset = parseInt(this.pageIndex);
        this.send('jumpPage', offset - 1);
      },
      // Go to a specific page
      jumpPage: function jumpPage(destPage) {
        var limit = this.pageCount;
        destPage = Math.max(1, destPage);
        destPage = Math.min(destPage, limit);
        this.set('pageIndex', destPage); // Adjust page height now there are more/fewer elements, but do it
        // 'next', after the DOM has finished being changed.

        Ember.run.next(function () {
          if ((0, _jquery.default)(document).foundation) {
            (0, _jquery.default)(document).foundation('equalizer', 'reflow');
          }
        });
      }
    }
  });

  _exports.default = _default;
});