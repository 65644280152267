define("megaportal-frontend-uq-components/components/notifications-widget", ["exports", "megaportal-frontend-uq-components/models/notifications", "megaportal-frontend-uq-components/models/unitask"], function (_exports, _notifications, _unitask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var sortingSafeDate = function sortingSafeDate(a) {
    return Ember.isEmpty(a) ? new Date().setYear(2040) : Date.parse(a);
  };

  var sortByDueDate = function sortByDueDate(a, b) {
    return sortingSafeDate(Ember.get(a, 'dueDate')) - sortingSafeDate(Ember.get(b, 'dueDate'));
  };

  var isOverdue = function isOverdue(dueDate) {
    return new Date() > Date.parse(dueDate);
  };

  var UNITASK_MAX_GET = 20;

  var _default = Ember.Component.extend({
    authManager: Ember.inject.service(),
    featureService: Ember.inject.service(),
    media: Ember.inject.service(),
    session: Ember.inject.service('session'),
    tasks: false,
    notifications: false,
    loading: true,
    init: function init() {
      this.loadNotifications();
      return this._super.apply(this, arguments);
    },
    // If user is logged in, load notifications
    loadNotifications: function loadNotifications() {
      var _this = this;

      // Only get data if user is logged in, and all auth data is loaded
      this.authManager.waitForAccount().then(function () {
        if (_this.get('authManager.isAuthenticated')) {
          if (_this.get('authManager.accountData')) {
            // Fetch tasks
            _unitask.default.fetchTasks(UNITASK_MAX_GET + 1).then(function (tasksPayload) {
              _this.set('tasks', tasksPayload);

              _this.completeLoading();
            }); // Fetch notifications


            if (!_this.get('authManager.isStaff')) {
              _notifications.default.fetch(20).then(function (notificationsPayload) {
                _this.set('notifications', notificationsPayload);

                _this.completeLoading();
              });
            } else {
              _this.set('notifications', null);

              _this.completeLoading();
            }
          }
        } else {
          _this.set('tasks', null);

          _this.set('notifications', null);

          _this.completeLoading();
        }
      });
    },
    // Reload notifications when auth state is changed
    onAuthChange: Ember.observer('authManager.accountData', function () {
      this.loadNotifications();
    }),
    completeLoading: function completeLoading() {
      if (this.get('tasks') !== false && this.get('notifications') !== false) {
        this.set('loading', false); // Call a Foundation update after adding notifications

        setTimeout(function () {
          if ($(document).foundation) {
            $(document).foundation('equalizer', 'reflow');
          }
        }, 200);
      }
    },
    unitaskError: Ember.computed('tasks', function () {
      var tasks = this.get('tasks');

      if (!(tasks instanceof Error)) {
        return false;
      }

      return tasks.status;
    }),
    sortedTasks: Ember.computed('tasks', function () {
      if (this.get('unitaskError')) {
        return false;
      }

      var tasks = this.get('tasks'); // Don't show if more than 2 tasks

      if (!Array.isArray(tasks) || tasks.length > 2) {
        return [];
      }

      return tasks.sort(sortByDueDate);
    }),
    groupedTasks: Ember.computed('tasks', function () {
      if (this.get('unitaskError')) {
        return false;
      }

      var tasks = this.get('tasks'); // Don't show if 2 or fewer tasks

      if (!Array.isArray(tasks) || tasks.length <= 2) {
        return null;
      }
      /* Group tasks */


      var overdueTasks = [];
      var normalTasks = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = tasks[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var task = _step.value;

          if (isOverdue(task.dueDate)) {
            overdueTasks.push(task);
          } else {
            normalTasks.push(task);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var taskCount = tasks.length;
      var countString = taskCount > UNITASK_MAX_GET ? "more than ".concat(UNITASK_MAX_GET) : taskCount.toString();
      var statusArr = [];

      if (overdueTasks.length > 0) {
        if (taskCount > UNITASK_MAX_GET) {
          // We don't know exactly how many overdue tasks there are
          statusArr.push('Some tasks are overdue');
        } else {
          // We do know exactly how many overdue tasks there are
          statusArr.push(overdueTasks.length + ' overdue');
        }
      }

      return {
        count: countString,
        hasPriorityTasks: overdueTasks.length > 0,
        statusText: statusArr.join(', '),
        normalCount: normalTasks.length
      };
    })
  });

  _exports.default = _default;
});