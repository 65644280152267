define("megaportal-frontend-uq-components/models/terms", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Terms = Ember.Object.extend({});
  Terms.reopenClass({
    fetch: function fetch() {
      return _jquery.default.ajax('/api/cip/terms', {
        dataType: 'json'
      }).then(function (resp) {
        return resp;
      });
    }
  });
  var _default = Terms;
  _exports.default = _default;
});