define("megaportal-frontend-uq-components/components/assessment-criteria", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userPercentage: null,
    courseContribution: 0,
    result: 0,
    actions: {
      resultChanged: function resultChanged() {
        this.sendAction('resultsChanged', this.results);
      }
    },
    changeResult: Ember.observer('userPercentage', function () {
      //called when keyup on input field
      var userPercentage = parseInt(this.userPercentage),
          //convert this to a int
      cutOffs = [0, 24, 47, 50, 65, 75, 85],
          //grade cutoffs
      resultInGradeForm = 0;

      if (isNaN(userPercentage)) {
        userPercentage = 0;
      }

      if (userPercentage > 100) {
        userPercentage = 100;
      }

      this.set('courseContribution', userPercentage * this.get('assessment.weighting') / 100);

      for (var i = 0; i < cutOffs.length; i++) {
        if (userPercentage < cutOffs[i + 1]) {
          //if the result is less than the next index
          resultInGradeForm = i + 1; //assign the grade to the aformentioned index

          break; //stop the loop
        } else if (userPercentage >= cutOffs[6]) {
          //if it is 85 or greater
          resultInGradeForm = 7;
          break;
        }
      }

      this.set('result', resultInGradeForm); //set the result property to be the new result
      //Now modify the result with the weighting of this assessment to send away to parent Component

      var thisUniqueId = this.get('assessment.unique_id'),
          resultsArray = this.results;
      resultsArray.forEach(function (entry) {
        if (entry.unique_id === thisUniqueId) {
          entry.set('percentage', userPercentage);
        }
      });
    }).on('init'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('userPercentage', this.get('assessment.percentage'));
    }
  });

  _exports.default = _default;
});