define("megaportal-frontend-uq-components/controllers/apps/settings/features", ["exports", "megaportal-frontend-uq-components/models/feature"], function (_exports, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    featureService: Ember.inject.service(),
    actions: {
      toggleFeature: function toggleFeature(feature) {
        var _this = this;

        Ember.set(feature, 'enabled', !feature.enabled);
        feature = JSON.parse(JSON.stringify(feature));

        _feature.default.toggleUserFeature(feature).then(function () {
          _this.featureService.loadUserFeatures(feature.username).then(function () {
            _this.target.send('doReflow');
          });
        });
      }
    }
  });

  _exports.default = _default;
});