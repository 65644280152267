define("megaportal-frontend-uq-components/controllers/apps/my-requests/make-request/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    searchValue: '',
    application: Ember.inject.controller(),
    authManager: Ember.inject.service(),
    //auth manager
    isStaff: Ember.computed.alias('authManager.isStaff'),
    results: Ember.computed('searchValue', function () {
      var searchValue = this.searchValue,
          _this$get = this.get('model'),
          processes = _this$get.processes;

      if (searchValue) {
        return processes.filter(function (_ref) {
          var title = _ref.title;
          return title.toLowerCase().includes(searchValue.toLowerCase());
        });
      }

      return processes;
    })
  });

  _exports.default = _default;
});