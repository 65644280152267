define("megaportal-frontend-uq-components/engines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var engines = [{
    name: 'megaportal-frontend-engine-myprofile',
    as: 'apps.myprofile',
    path: 'apps/myprofile'
  }, {
    name: 'megaportal-frontend-engine-timetable',
    as: 'apps.timetable',
    path: 'apps/cip-timetable'
  }];
  var _default = engines;
  _exports.default = _default;
});