define("megaportal-frontend-uq-components/templates/apps/course-cuttoffs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ke6fWBrk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"apps-header\",null,[[\"iconClasses\"],[\"icon icon-courses icon--prefix\"]],{\"statements\":[[0,\"Courses\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"large-12 xlarge-8 column\"],[9],[0,\"\\n    \"],[1,[29,\"inline-iframe\",null,[[\"url\"],[[25,[\"iframeUrl\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[23,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/apps/course-cuttoffs/index.hbs"
    }
  });

  _exports.default = _default;
});