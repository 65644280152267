define("megaportal-frontend-uq-components/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // ember-responsive plugin breakpoints
  var _default = {
    mobile: '(max-width: 640px)',
    tablet: '(min-width: 640px) and (max-width: 1024px), (min-width: 640px) and (orientation: portrait)',
    mobileOrTablet: '(max-width: 1024px), (min-width: 640px) and (orientation: portrait)',
    laptop: '(min-width: 1024px) and (max-width: 1440px) and (orientation: landscape)',
    laptopOrDesktop: '(min-width: 1024px) and (orientation: landscape)',
    desktop: '(min-width: 1440px) and (orientation: landscape)',
    jumbo: '(min-width: 1440px) and (orientation: landscape)'
  };
  _exports.default = _default;
});