define("megaportal-frontend-uq-components/models/events", ["exports", "megaportal-frontend-uq-components/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UQEvent = Ember.Object.extend({});
  var fixedEvents = [{
    "ItemType": "EventItem",
    "Title": "See what's on at UQ",
    "Creator": "UQ Events",
    "Content": "Events for the UQ community, including the public, industry, researchers, staff and students",
    "Link": "https://www.uq.edu.au/uq-events/"
  }, {
    "ItemType": "EventItem",
    "Title": "Academic Calendar",
    "Creator": "UQ Events",
    "Content": "Find key study dates for the year, including semester dates, exam periods and holidays",
    "Link": "https://about.uq.edu.au/academic-calendar"
  }];
  UQEvent.reopenClass({
    fetch: function fetch() {
      return fixedEvents;
    },
    fetchToday: function fetchToday() {
      return fixedEvents;
    },
    fetchById: function fetchById() {
      return fixedEvents;
    }
  });
  var _default = UQEvent;
  _exports.default = _default;
});