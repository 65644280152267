define("megaportal-frontend-uq-components/controllers/apps/myprofile", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // After template render, call the action to select the first available tab
    init: function init() {
      this._super();

      Ember.run.schedule("afterRender", this, function () {
        this.send("selectTab", 0);
      });
    },
    actions: {
      selectTab: function selectTab(tabNumber) {
        (0, _jquery.default)('.my-profile.tabs-section').find('ul li').removeClass('active');
        (0, _jquery.default)('.my-profile.tabs-section').find('ul li:eq(' + tabNumber + ')').addClass('active');
        (0, _jquery.default)('.my-profile.tabs-section').find('section.content').removeClass('active');
        (0, _jquery.default)('.my-profile.tabs-section').find('section.content:eq(' + tabNumber + ')').addClass('active');
      }
    }
  });

  _exports.default = _default;
});