define("megaportal-frontend-uq-components/helpers/short-date-range", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shortDateRange = shortDateRange;
  _exports.default = void 0;

  function shortDateRange(params) {
    var start = params[0];
    var end = params[1];
    var range;
    range = (0, _moment.default)(start).twix(end);

    if (range.length('hours') === 23) {
      range = (0, _moment.default)(start).twix(end, {
        allDay: true
      });
    }

    var formatted = range.format({
      implicitMinutes: false,
      groupMeridiems: true,
      showDayOfWeek: true,
      monthFormat: 'MMMM'
    }); //replace hyphen with 'to' for screenreaders

    formatted = formatted.replace("-", "to");
    return formatted;
  }

  var _default = Ember.Helper.helper(shortDateRange);

  _exports.default = _default;
});