define("megaportal-frontend-uq-components/templates/components/private-browsing-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W6CiERtM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"privateBrowsingModal\"],[11,\"class\",\"reveal-modal\"],[11,\"data-reveal\",\"\"],[11,\"aria-labelledby\",\"modalTitle\"],[11,\"aria-hidden\",\"true\"],[11,\"role\",\"dialog\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"id\",\"modalTitle\"],[9],[0,\"Warning\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"MyUQ works best with Private Browsing mode disabled.\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"Please disable Private Browsing mode if you encounter any issues.\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[7,\"a\"],[11,\"class\",\"secondary button\"],[9],[0,\"OK\"],[3,\"action\",[[24,0,[]],\"closeModal\"]],[10],[10],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"close-reveal-modal\"],[11,\"aria-label\",\"Close\"],[9],[0,\"×\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/private-browsing-warning.hbs"
    }
  });

  _exports.default = _default;
});