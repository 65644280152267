define("megaportal-frontend-uq-components/templates/components/analytics-learn-uq-activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hFzGjrsj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"one-chart-filters\"],[9],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[30,[\"btn-activity daily btn-left left button tiny \",[29,\"if\",[[25,[\"frequencyIsDaily\"]],\"btn-selected\"],null]]]],[9],[0,\"Daily\"],[3,\"action\",[[24,0,[]],\"changeFrequency\",\"daily\"]],[10],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[30,[\"btn-activity weekly btn-right left button tiny \",[29,\"if\",[[25,[\"frequencyIsWeekly\"]],\"btn-selected\"],null]]]],[9],[0,\"Weekly\"],[3,\"action\",[[24,0,[]],\"changeFrequency\",\"weekly\"]],[10],[0,\"\\n\\n    \"],[7,\"button\"],[11,\"class\",\"btn-activity--title right button tiny btn-selected\"],[9],[0,\"Course: \"],[1,[25,[\"course\",\"course_code\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[2,\" Create a Google Charts chart \"],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"one-chart-area\"],[9],[0,\"\\n    \"],[1,[29,\"google-charts\",null,[[\"chartType\",\"chartData\",\"chartOptions\",\"chartDidRender\"],[\"column\",[25,[\"chartData\"]],[25,[\"chartOptions\"]],\"chartDidRender\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/analytics-learn-uq-activity.hbs"
    }
  });

  _exports.default = _default;
});