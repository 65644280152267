define("megaportal-frontend-uq-components/controllers/apps/library", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    inArrears: Ember.computed('totalFines', function () {
      return Number(this.get('model.totalFines')) > 0;
    })
  });

  _exports.default = _default;
});