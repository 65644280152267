define("megaportal-frontend-uq-components/components/calendar-view/event-block-type", ["exports", "megaportal-frontend-uq-components/templates/components/calendar-view/event-block-type"], function (_exports, _eventBlockType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _eventBlockType.default,
    media: Ember.inject.service(),
    classTypeTitleShort: Ember.computed('event.classTypeTitle', function () {
      var classTypeTitle = this.event.classTypeTitle;

      if (!classTypeTitle) {
        return '';
      }

      return classTypeTitle[0];
    })
  });

  _exports.default = _default;
});