define("megaportal-frontend-uq-components/controllers/apps/notifications/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    hasNotifications: Ember.computed.or('sortedTasks.length', 'model.notifications.length'),
    sortedTasks: Ember.computed('model.tasks', function () {
      var tasks = this.get('model.tasks');

      if (!Array.isArray(tasks)) {
        return [];
      }

      return tasks.sort(function (a, b) {
        function sortingSafeDate(a) {
          if (Ember.isEmpty(a)) {
            return new Date().setYear(2040);
          } else {
            return Date.parse(a);
          }
        }

        return sortingSafeDate(Ember.get(a, 'dueDate')) - sortingSafeDate(Ember.get(b, 'dueDate'));
      });
    })
  });

  _exports.default = _default;
});