define("megaportal-frontend-uq-components/components/course-profile", ["exports", "megaportal-frontend-uq-components/templates/components/course-profile", "jquery", "megaportal-frontend-uq-components/links", "megaportal-frontend-uq-components/helpers/sanitize-html"], function (_exports, _courseProfile, _jquery, _links, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ecpLinkUrl = _links.Links["electronic-course-profiles"].url;

  var _default = Ember.Component.extend({
    layout: _courseProfile.default,
    configService: Ember.inject.service(),
    featureService: Ember.inject.service(),
    authManager: Ember.inject.service(),
    activeTab: 'resources',
    ecpLinkUrl: "".concat(ecpLinkUrl, "student_section_loader/section_1/"),
    uniqueCourseContributors: null,

    get showHeadshotsTab() {
      return this.authManager.isStaff;
    },

    get showHeadshotsComponent() {
      return this.course.details.is_current_session;
    },

    didReceiveAttrs: function didReceiveAttrs() {
      console.log("Executing didReceiveAttrs in course profile component");

      this._super.apply(this, arguments); // Set up the blackboard URL


      var learnUqConfig = this.configService.LEARN_UQ;
      var courseCode = this.get('course.courseCode');
      this.course.set('blackboardUrl', "".concat(learnUqConfig.ssoUri).concat(courseCode)); // Sometimes the same contact will be listed twice, because they have two different roles
      // We'll combine these contacts for readability

      var combinedContacts = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        var _loop = function _loop() {
          var contact = _step.value;
          // Find already saved contact that matches new contact
          var matchedContact = combinedContacts.find(function (combinedContact) {
            return combinedContact.staff_id === contact.staff_id;
          });

          if (matchedContact) {
            matchedContact.staff_type_title += " / ".concat(contact.staff_type_title);
          } else {
            combinedContacts.push(contact);
          }
        };

        for (var _iterator = this.get('course.courseContributors')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          _loop();
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.uniqueCourseContributors = combinedContacts; // clean html

      if (this.course && this.course.details && this.course.details.summary) {
        var cleanSummary = (0, _sanitizeHtml.sanitize)(this.course.details.summary);
        this.course.set('cleanSummary', cleanSummary);
      }
    },
    actions: {
      toggleActiveTab: function toggleActiveTab(tabName) {
        this.set('activeTab', tabName);
        Ember.run.scheduleOnce('afterRender', this, function () {
          if ((0, _jquery.default)(document).foundation) {
            (0, _jquery.default)(document).foundation('equalizer', 'reflow');
          }
        });
      }
    }
  });

  _exports.default = _default;
});