define("megaportal-frontend-uq-components/templates/components/ftp-client-move-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pucagUcK",
    "block": "{\"symbols\":[\"childDirectory\",\"&default\"],\"statements\":[[7,\"ul\"],[9],[0,\"\\n\"],[2,\" create the initial root directory entries \"],[0,\"\\n\"],[4,\"each\",[[25,[\"ftpService\",\"directoryTree\"]]],null,{\"statements\":[[0,\"\\t\\n\\t\"],[1,[29,\"ftp-client-entry-recursive\",null,[[\"directory\"],[[24,1,[]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[15,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/ftp-client-move-tree.hbs"
    }
  });

  _exports.default = _default;
});