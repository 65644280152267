define("megaportal-frontend-uq-components/templates/components/give-feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8M6KmXXs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"section__content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panels-list\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"panel\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"column small-6\"],[9],[0,\"\\n          \"],[7,\"h3\"],[11,\"class\",\"panel__title\"],[9],[0,\"Enjoying my.UQ so far?\"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"panel__content\"],[9],[0,\"Send us feedback about how we can improve.\"],[10],[0,\"\\n          \"],[7,\"a\"],[11,\"class\",\"button\"],[11,\"aria-label\",\"Feedback?\"],[11,\"target\",\"_blank\"],[11,\"href\",\"https://my.uq.edu.au/feedback?r=https://portal.my.uq.edu.au/\"],[9],[0,\"\\n            Feedback\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"column small-6 panel__aside\"],[9],[0,\"\\n          \"],[7,\"img\"],[11,\"src\",\"assets/images/app-screenshot.png\"],[11,\"alt\",\"App screenshot\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/give-feedback.hbs"
    }
  });

  _exports.default = _default;
});