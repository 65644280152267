define("megaportal-frontend-uq-components/helpers/format-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;

  function formatDate(params
  /*, hash*/
  ) {
    var date = params[0];
    var format = params[1];
    return (0, _moment.default)(date).format(format);
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});