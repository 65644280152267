define("megaportal-frontend-uq-components/templates/components/crm-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uYTSnOv2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"section crmHelpSection\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"section__header\"],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"section__title\"],[9],[0,\"Would you like some help?\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"section__content\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"authManager\",\"isStudent\"]]],null,{\"statements\":[[0,\"            \"],[4,\"link-to-external\",null,[[\"href\",\"class\"],[\"https://support.my.uq.edu.au/app/ask\",\"button button--tertiary expand\"]],{\"statements\":[[0,\"Ask a Question\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"div\"],[11,\"id\",\"myCrmDiv\"],[9],[0,\"\\n            Common Questions\\n            \"],[7,\"div\"],[11,\"id\",\"myCrmDivQuestionsAnswers\"],[9],[0,\"\\n\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[15,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/crm-help.hbs"
    }
  });

  _exports.default = _default;
});