define("megaportal-frontend-uq-components/routes/apps/my-requests/process/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      this.transitionTo('apps.my-requests.make-request.index');
    }
  });

  _exports.default = _default;
});