define("megaportal-frontend-uq-components/templates/components/ftp-client-move-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v7l37jkN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"move-panel\"],[9],[0,\"\\n\\t\\n\\t\"],[7,\"a\"],[11,\"class\",\"close icon icon-close\"],[9],[3,\"action\",[[24,0,[]],\"close\"],[[\"on\"],[\"click\"]]],[10],[0,\"\\n\\t\\n\\t\"],[7,\"h3\"],[9],[0,\"Move file or folder?\"],[10],[0,\"\\n\\t\"],[7,\"p\"],[9],[0,\"Choose a folder to move the selected item(s) to.\"],[10],[0,\"\\n\\n\\t\"],[1,[23,\"ftp-client-move-tree\"],false],[0,\"\\n\\t\"],[7,\"button\"],[11,\"class\",\"button button--tertiary\"],[9],[0,\"Move\"],[3,\"action\",[[24,0,[]],\"move\"]],[10],[7,\"button\"],[11,\"class\",\"button button--primary\"],[9],[0,\"Cancel\"],[3,\"action\",[[24,0,[]],\"close\"]],[10],[0,\"\\n\\t\\n\"],[10],[0,\"\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/ftp-client-move-modal.hbs"
    }
  });

  _exports.default = _default;
});