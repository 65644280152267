define("megaportal-frontend-uq-components/models/version", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Version = Ember.Object.extend({});
  Version.reopenClass({
    fetch: function fetch() {
      return _jquery.default.ajax('/version', {
        dataType: 'text'
      }).then(function (resp) {
        return resp;
      });
    }
  });
  var _default = Version;
  _exports.default = _default;
});