define("megaportal-frontend-uq-components/components/ftp-client-delete-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'ftp-client-delete-modal',
    ftpService: Ember.inject.service(),
    promptTitle: Ember.computed('ftpService.currentSelectedEntries', function () {
      /*
      		THIS ALGORITHM DETERMINES WHAT THE PROMPT SHOULD BE BASED ON THE NUMBER OF FILES
      	AND FOLDERS SELECTED. THE OPTIONS ARE:
      		Are you sure you wish to delete +
      		Directories:          Files:            Outcome:
      	  0					    1				This file?
      	  0                   > 1				These files?
      	  1                     0				This folder?
      	> 1					    0				These folders?
      	> 1					  > 1				These files and these folders?
      	> 1					    1				This file and these folders?
      	  1					  > 1				These files and this folder?
      	*/
      var //a full list of everything the user wishes to delete. This is what we will separate into
      //their appropriate catagories
      currentSelectedEntries = this.ftpService.currentSelectedEntries,
          //We declare these so that we can push the separated entry types into each respective array
      selectedDirectories = [],
          selectedFiles = [],
          //Regardless of how many files and folders are being deleted, we will always begin the
      //prompt with this message. The end dynamically changes depending on what
      //we have selected, so we declare it here so we can change it
      //in the switch later
      prompt = 'Are you sure you wish to delete ',
          promptEnd = ''; //here we iterate over each selected entry. First we isolate the entryType property,
      //and check is against the two types (file or folder). Once we have
      //determined where it belongs, we push it into its correct
      //array

      currentSelectedEntries.forEach(function (entry) {
        var entryType = entry.entryType;

        if (entryType === 'file') {
          selectedFiles.push(entry);
        } else if (entryType === 'folder') {
          selectedDirectories.push(entry);
        }
      });
      var //We only really care about how many entries are in each array, not the contents
      //of the entries themselves, so we declare them as variables here
      numberOfDirectories = selectedDirectories.length,
          numberOfFiles = selectedFiles.length; //here is the 'checker' which determines the correct prompt ending based on
      //the length of the arrays. It is pretty unreadable here, so instead
      //please refer to the commentary above that breaks down what
      //the appropriate outcomes should be

      switch (true) {
        case !numberOfDirectories && numberOfFiles === 1:
          promptEnd = 'this file?';
          break;

        case !numberOfDirectories && numberOfFiles > 1:
          promptEnd = 'these files?';
          break;

        case numberOfDirectories === 1 && !numberOfFiles:
          promptEnd = 'this folder?';
          break;

        case numberOfDirectories > 1 && !numberOfFiles:
          promptEnd = 'these folders?';
          break;

        case numberOfDirectories > 1 && numberOfFiles > 1:
          promptEnd = 'these files and these folders?';
          break;

        case numberOfDirectories > 1 && numberOfFiles === 1:
          promptEnd = 'this file and these folders?';
          break;

        case numberOfDirectories === 1 && numberOfFiles > 1:
          promptEnd = 'these files and this folder?';
          break;

        default:
          /**/
          break;
      } //once the prompt end is determined, we want to append it to the beginning prompt and return it
      //so that it can be rendered in the DOM


      prompt += promptEnd; //In the event that some number of directories are selected, we want to throw a warning that all the contents
      //will be deleted as well

      switch (true) {
        case numberOfDirectories === 1:
          prompt += ' Note that this folder will have all of its contents deleted as well.';
          break;

        case numberOfDirectories > 1:
          prompt += ' Note that these folders will have all of their contents deleted as well.';
          break;
      }

      return prompt;
    }),
    actions: {
      //begins upload when the files in the hidden input actually change
      delete: function _delete() {
        this.ftpService.delete();
      },
      cancel: function cancel() {
        //this hides the panel in the DOM
        this.ftpService.set('showDeletePanel', false);
      }
    }
  });

  _exports.default = _default;
});