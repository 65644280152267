define("megaportal-frontend-uq-components/templates/components/ftp-client-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wFDJnNn2",
    "block": "{\"symbols\":[\"breadcrumb\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"column small-12 large-8 xlarge-8 ftp-client__breadcrumb\"],[9],[0,\"\\n\\n\\t\"],[7,\"a\"],[11,\"class\",\"ftp-client-toolbar-icon-firstcrumb icon icon-folder-open\"],[9],[0,\"\\n\\t\\t\\n\\t\\tMy Files\\n\\t\"],[3,\"action\",[[24,0,[]],\"toDirectory\",\"/\"],[[\"on\"],[\"mouseUp\"]]],[10],[0,\" \\n\\t\\n\"],[4,\"each\",[[25,[\"ftpService\",\"breadcrumbs\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\"],[11,\"class\",\"icon icon-chevron-right\"],[9],[10],[0,\" \"],[7,\"a\"],[9],[1,[24,1,[\"entryName\"]],false],[3,\"action\",[[24,0,[]],\"toDirectory\",[24,1,[\"entryName\"]]],[[\"on\"],[\"mouseUp\"]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"column small-6 medium-3 large-2 xlarge-2 ftp-client__action--upload\"],[9],[0,\"\\n\\t\"],[7,\"a\"],[11,\"class\",\"ftp-client-toolbar-icon icon icon-upload\"],[9],[10],[0,\"\\n\\t\"],[7,\"a\"],[11,\"class\",\"upload--link\"],[9],[0,\"\\n\\t\\tUpload\\n\\t\"],[3,\"action\",[[24,0,[]],\"upload\"],[[\"on\"],[\"mouseUp\"]]],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"column small-6 medium-9 large-2 xlarge-2 ftp-client__action--new-folder\"],[9],[0,\"\\n\\t\"],[7,\"a\"],[11,\"class\",\"ftp-client-toolbar-icon icon icon-folder\"],[9],[10],[0,\"\\n\\t\"],[7,\"a\"],[11,\"class\",\"mkdir--link\"],[9],[0,\"\\n\\t\\tNew Folder\\n\\t\"],[3,\"action\",[[24,0,[]],\"mkdir\"],[[\"on\"],[\"mouseUp\"]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[15,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/ftp-client-toolbar.hbs"
    }
  });

  _exports.default = _default;
});