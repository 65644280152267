define("megaportal-frontend-uq-components/helpers/link-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkMarker = linkMarker;
  _exports.default = void 0;

  function linkMarker(params) {
    if (!params[0]) {
      return '';
    } else {
      return Ember.String.htmlSafe('<span class="link-marker"> > </span>');
    }
  }

  var _default = Ember.Helper.helper(linkMarker);

  _exports.default = _default;
});