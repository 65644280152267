define("megaportal-frontend-uq-components/components/single-unitask-notification", ["exports", "jquery", "megaportal-frontend-uq-components/models/unitask"], function (_exports, _jquery, _unitask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authManager: Ember.inject.service(),
    relatedTo: Ember.computed({
      get: function get(key) {
        var _this = this;

        _unitask.default.fetchCommonAttributesForCase(this.get("task.caseId")).then(function (resp) {
          var username = _this.authManager.username;
          var value;

          if (username === resp.partyUsername) {
            value = 'Me';
          } else {
            value = resp.relatedTo;
          }

          _this.set('relatedTo', value);
        });

        return '';
      },
      set: function set(key, value) {
        return value;
      }
    })
  });

  _exports.default = _default;
});