define("megaportal-frontend-uq-components/components/ftp-client-entry", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //DOM setup
    tagName: 'div',
    classNames: ['row', 'ftp-client-entry'],
    classNameBindings: ['isSelected:ftp-client-entry-selected'],
    ftpService: Ember.inject.service(),
    notificationService: Ember.inject.service(),
    //this has to be a property because computed functions will only return a value
    //on init. We need a property that when a change in selected entries is observed,
    //it gets set
    isSelected: false,
    rename: false,
    entryBeingRenamed: Ember.observer('ftpService.renameEntry', function () {
      //this observer is triggered when the user wants to rename 'something'. For
      //each of the ftp client entries, we set their rename property to be either
      //true or false according to whether or not it is selected. If it is set to
      //true, a DOM change occurs allowing the user to rename the selected item.
      this.set('rename', this.isSelected);
    }),
    entryChanged: Ember.observer('ftpService.currentSelectedEntries.[]', function () {
      var thisEntry = this.entry,
          currentSelectedEntries = this.ftpService.get('currentSelectedEntries');
      this.set('isSelected', _jquery.default.inArray(thisEntry, currentSelectedEntries) > -1);
    }).on('init'),
    //uses case structure to determine whether or not to add this to selected entries
    click: function click(event) {
      //this seems like a complete hack and that is because it is.
      //there is an issue with using click as a component method instead of an action which
      //prevents the span from actioning the cancelRename action when clicked. I think it
      //catches the event using this method first and prevents the default. We use this workaround.
      if (event.target.innerText === ' Cancel') {
        this.send('cancelRename');
      } else if (event.target.innerText === ' Accept') {
        this.send('rename');
      }

      if (event.ctrlKey || event.metaKey) {
        if (_jquery.default.inArray(this.entry, this.ftpService.get('currentSelectedEntries')) <= -1) {
          this.ftpService.get('currentSelectedEntries').pushObject(this.entry);
        } else {
          this.ftpService.get('currentSelectedEntries').removeObject(this.entry);
        }
      } else {
        this.ftpService.set('currentSelectedEntries', [this.entry]);
      }
    },
    actions: {
      toDirectory: function toDirectory() {
        if (this.entry.entryType === 'folder') {
          this.ftpService.get('breadcrumbs').pushObject(this.entry);
        }
      },
      rename: function rename() {
        this.set('rename', false);
        this.ftpService.set('renameEntry', false);
        this.ftpService.rename(this.entry, this.newName);
      },
      cancelRename: function cancelRename() {
        this.set('rename', false);
        this.ftpService.set('renameEntry', false);
      }
    }
  });

  _exports.default = _default;
});