define("megaportal-frontend-uq-components/routes/apps/library-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController() {
      var applicationController = this.controllerFor('application');
      applicationController.set('loading', false);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});