define("megaportal-frontend-uq-components/models/feature", ["exports", "megaportal-frontend-uq-components/utils/authorized-fetch", "megaportal-frontend-uq-components/config/environment"], function (_exports, _authorizedFetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Feature = Ember.Object.extend({});
  Feature.reopenClass({
    fetchUserFeatures: function fetchUserFeatures(stage, username) {
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/users-features/").concat(encodeURIComponent(stage), "/").concat(encodeURIComponent(username))).then(function (response) {
        return response.json();
      });
    },
    createUserFeature: function createUserFeature(stage, username, featureId) {
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/users-features"), {
        method: 'POST',
        body: JSON.stringify({
          stage: stage,
          username: username,
          featureId: featureId,
          enabled: true
        })
      });
    },
    fetchAllFeatures: function fetchAllFeatures(stage) {
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/features/").concat(encodeURIComponent(stage))).then(function (response) {
        return response.json();
      });
    },
    fetchAllUsersFeatures: function fetchAllUsersFeatures(featureId) {
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/users-features/users/all/").concat(encodeURIComponent(featureId))).then(function (response) {
        return response.json();
      });
    },
    fetchIsFeaturesAdmin: function fetchIsFeaturesAdmin() {
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/features-admin")).then(function (response) {
        return response.json();
      });
    },
    fetchUsersOfFeaturesCount: function fetchUsersOfFeaturesCount() {
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/users-features/users/count/all")).then(function (response) {
        return response.json();
      });
    },
    createFeature: function createFeature(feature) {
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/features"), {
        method: 'POST',
        body: JSON.stringify(feature)
      });
    },
    deleteFeature: function deleteFeature(stage, featureId) {
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/features/").concat(encodeURIComponent(stage), "/").concat(encodeURIComponent(featureId)), {
        method: 'DELETE'
      });
    },
    restrictFeature: function restrictFeature(stage, feature) {
      var restrictedFeature = JSON.parse(JSON.stringify(feature));
      restrictedFeature.restricted = true;
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/features"), {
        method: 'PATCH',
        body: JSON.stringify(restrictedFeature)
      });
    },
    unrestrictFeature: function unrestrictFeature(stage, feature) {
      var unrestrictedFeature = JSON.parse(JSON.stringify(feature));
      unrestrictedFeature.restricted = false;
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/features"), {
        method: 'PATCH',
        body: JSON.stringify(unrestrictedFeature)
      });
    },
    toggleUserFeature: function toggleUserFeature(userFeature) {
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/users-features"), {
        method: 'PATCH',
        body: JSON.stringify(userFeature)
      });
    },
    deleteUserFeature: function deleteUserFeature(userFeature) {
      return (0, _authorizedFetch.default)("".concat(_environment.default.FEATURES_URL, "/users-features/").concat(encodeURIComponent(userFeature.stage), "/").concat(encodeURIComponent(userFeature.username), "/").concat(encodeURIComponent(userFeature.featureId)), {
        method: 'DELETE'
      });
    }
  });
  var _default = Feature;
  _exports.default = _default;
});