define("megaportal-frontend-uq-components/components/calendar-view/current-time-line", ["exports", "moment", "megaportal-frontend-uq-components/templates/components/calendar-view/current-time-line"], function (_exports, _moment, _currentTimeLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ZERO_TIME = 0;
  var HOURS_BLOCK_HEIGHT = 60;

  var _default = Ember.Component.extend({
    layout: _currentTimeLine.default,
    tagName: "div",
    intervalId: null,
    currentTime: (0, _moment.default)(),
    updatedTime: Ember.computed("currentTime", function () {
      return this.currentTime.format("h:mmA");
    }),
    style: Ember.computed("currentTime", function () {
      var topValue = _moment.default.duration(this.currentTime.diff((0, _moment.default)("700", "Hmm"))).asHours() * HOURS_BLOCK_HEIGHT + ZERO_TIME - 24;
      return Ember.String.htmlSafe("top: " + topValue + "px;");
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var updateTimeLine = function updateTimeLine() {
        _this.set("currentTime", (0, _moment.default)());

        var thisTime = _this.currentTime.format("Hmm");

        var roundedTime = thisTime.replace(/^(\d{1,2})(\d{2})$/, function (matches, hours, mins) {
          if (parseInt(mins, 10) >= 30) {
            hours = parseInt(hours, 10) + 1;
          }

          return "".concat(hours, "00");
        });

        _this.updateHiddenIndicator(roundedTime);
      };

      updateTimeLine();
      var intervalId = setInterval(updateTimeLine, 1000);
      this.set("intervalId", intervalId);
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this.intervalId);
    }
  });

  _exports.default = _default;
});