define("megaportal-frontend-uq-components/components/unitask-notification", ["exports", "jquery", "megaportal-frontend-uq-components/models/unitask"], function (_exports, _jquery, _unitask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authManager: Ember.inject.service(),
    tagName: 'div',
    relatedTo: '',
    commonAttributesSetter: Ember.on('init', Ember.observer('task', function () {
      var controller = this;

      _unitask.default.fetchCommonAttributesForCase(this.get("task.caseId")).then(function (resp) {
        var username = controller.get("authManager.username");

        if (username === resp.partyUsername) {
          controller.set("relatedTo", "Me");
        } else {
          controller.set("relatedTo", resp.relatedTo);
        } //Do a reflow


        Ember.run.scheduleOnce('afterRender', this, function () {
          if ((0, _jquery.default)(document).foundation) {
            (0, _jquery.default)(document).foundation('equalizer', 'reflow');
          }
        });
      });
    }))
  });

  _exports.default = _default;
});