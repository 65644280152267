define("megaportal-frontend-uq-components/controllers/dashboard", ["exports", "megaportal-frontend-uq-components/links", "moment"], function (_exports, _links, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authManager: Ember.inject.service(),
    media: Ember.inject.service(),
    session: Ember.inject.service('session'),
    featureService: Ember.inject.service(),
    timetableService: Ember.inject.service(),
    timetableLink: _links.Links["my-timetable"].url,
    showTimetable: Ember.computed('session.isAuthenticated', 'authManager.isStudent', function () {
      return this.get('session.isAuthenticated') && this.get('authManager.isStudent');
    }),
    moreThanOneActiveSemester: Ember.computed('model.courses.value.length', function () {
      return this.get('model.courses.value.length') > 1 ? true : false;
    })
  });

  _exports.default = _default;
});