define("megaportal-frontend-uq-components/components/unitask-form", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SCROLL_SPEED = 1500;

  var _default = Ember.Component.extend({
    formUrl: '',
    crmQuery: '',
    crmQuestions: 0,
    notificationService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    crmContentsMetronome: function () {
      var interval = 1000;
      Ember.run.later(this, function () {
        //stop running if they have left the window...
        if (this.isDestroyed || this.isDestroying) {
          return;
        } //set the number of questions, so our event handler can do something


        this.set("crmQuestions", (0, _jquery.default)(".rn_Content .rn_List .rn_Item").length);
        this.crmContentsMetronome();
      }, interval);
    }.on('init'),
    showCrmBlock: Ember.computed("crmQuestions", function () {
      return this.crmQuestions > 0;
    }),
    didInstertElement: function didInstertElement() {
      this.set('notificationService.loading', true);
    },
    actions: {
      scrollToHelp: function scrollToHelp() {
        var _$$offset = (0, _jquery.default)('div.crmHelpSection').offset(),
            scrollTop = _$$offset.top,
            pageHeader = (0, _jquery.default)('div.page__header');

        scrollTop -= pageHeader.css('display') != 'none' ? pageHeader.height() : 0;
        (0, _jquery.default)('html, body').animate({
          scrollTop: scrollTop
        }, SCROLL_SPEED);
      }
    },
    showCrmBlockMonitor: Ember.observer("showCrmBlock", function () {
      //If the showCrmBlock has changed, we need to fire off a reflow, so that
      //the blocks height is set correctly.
      Ember.run.scheduleOnce("afterRender", function () {
        if ((0, _jquery.default)(document).foundation) {
          (0, _jquery.default)(document).foundation('equalizer', 'reflow');
        }
      });
    })
  });

  _exports.default = _default;
});