define("megaportal-frontend-uq-components/templates/components/calendar-view/dashboard-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "77tu8ykB",
    "block": "{\"symbols\":[\"event\"],\"statements\":[[7,\"div\"],[11,\"class\",\"day-events-container\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[25,[\"events\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"          \"],[4,\"calendar-view/event-block\",null,[[\"event\"],[[24,1,[]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/calendar-view/dashboard-column.hbs"
    }
  });

  _exports.default = _default;
});