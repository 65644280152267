define("megaportal-frontend-uq-components/services/timetable-service", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var DATE_FORMAT = "YYYY-MM-DD";
  var WEEK_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    authManager: Ember.inject.service(),
    configService: Ember.inject.service(),
    session: Ember.inject.service(),
    quickCache: {},
    currentView: 'day',
    setView: function setView(view) {
      this.set('currentView', view);
    },
    _enumerateDaysBetweenDates: function _enumerateDaysBetweenDates(startDate, endDate) {
      var currDate = (0, _moment.default)(startDate).startOf('day');
      var lastDate = (0, _moment.default)(endDate).startOf('day');
      var dates = [startDate];

      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().format("YYYY-MM-DD"));
      }

      dates.push(endDate);
      return dates;
    },
    _makeDateObject: function _makeDateObject(date) {
      return {
        dateFull: date,
        date: (0, _moment.default)(date, DATE_FORMAT).format("D"),
        monthName: (0, _moment.default)(date, DATE_FORMAT).format("MMMM"),
        events: [],
        isCurrentDate: date === (0, _moment.default)().format(DATE_FORMAT),
        isActive: (0, _moment.default)().startOf("day").month() === (0, _moment.default)(date, DATE_FORMAT).month() && (0, _moment.default)().startOf("day") <= (0, _moment.default)(date).startOf("day")
      };
    },
    getEvents: function getEvents(eventDate, classNumber, classGroup, termCode) {
      // Get matching events
      var eventPromise = this.getTimetable(eventDate, 'day').then(function (timetablePayload) {
        try {
          // Return filtered list of matching events
          var events = timetablePayload.currentDay.events.filter(function (event) {
            return event.classDate === eventDate && event.classNumber === parseInt(classNumber) && event.classGroup === classGroup && event.termCode === parseInt(termCode);
          });
          return events;
        } catch (e) {
          // Return null on error
          return null;
        }
      }).catch(function (err) {
        console.log('### Timetable Service getEvents err: ', err);
      });
      return eventPromise;
    },
    getTimetable: function getTimetable(date) {
      var _this = this;

      var view = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'month';
      var cacheKey = "".concat(date, "-").concat(view);
      var cached = this.quickCache[cacheKey];

      if (cached) {
        return cached;
      } //  Moment dates to query on etc.


      var momentStart = (0, _moment.default)(date, DATE_FORMAT); //  Start and end of week that the current date exists in

      var startOfWeek = momentStart.clone().startOf("isoWeek").format(DATE_FORMAT);
      var endOfWeek = momentStart.clone().endOf("isoWeek").format(DATE_FORMAT); //  Start and end of week either side of the month (between 4 - 6 weeks)

      var startOfMonth = momentStart.clone().startOf('month').format(DATE_FORMAT);
      var endOfMonth = momentStart.clone().endOf('month').format(DATE_FORMAT);
      var startOfMonthWeek = (0, _moment.default)(startOfMonth, DATE_FORMAT).startOf("isoWeek").format(DATE_FORMAT);
      var endOfMonthWeek = (0, _moment.default)(endOfMonth, DATE_FORMAT).endOf("isoWeek").format(DATE_FORMAT); //  Get a list of dates between a given date range

      var datesMonth = this._enumerateDaysBetweenDates(startOfMonthWeek, endOfMonthWeek);

      var datesWeek = this._enumerateDaysBetweenDates(startOfWeek, endOfWeek); //  query for whole month data + the tail end of the month either side
      //  TODO had to override the ember data store and instead go straight to ajax.
      //  This is because I am having an issue with the model relations
      //  Replace this technical debt soon.


      var timespanStart, timespanEnd;

      switch (view) {
        case 'day':
          timespanStart = timespanEnd = date;
          break;

        case 'week':
          timespanStart = startOfWeek;
          timespanEnd = endOfWeek;
          break;

        case 'month':
          timespanStart = startOfMonthWeek;
          timespanEnd = endOfMonthWeek;
          break;
      }

      var currentDay = this._makeDateObject(date); //  take the dateranges for month and week and convert into a Map (ensures key order)


      var monthDays = datesMonth.reduce(function (m, date) {
        return m.set(date, _this._makeDateObject(date));
      }, new Map());
      var weekDays = datesWeek.reduce(function (d, date) {
        return d.set(date, _this._makeDateObject(date));
      }, new Map()); // These variables will be set during the promises

      var monthDaysChunked = [];
      var weeksMonth = null;
      var week = null;
      var semesters = null;
      var signedUrl = null;
      var status = null;
      var timetableResponse = null;
      var weeksMonthPromise = this.store.query('week', {
        start: startOfMonthWeek
      }).then(function (v) {
        return weeksMonth = v;
      });
      var weekPromise = this.store.query('week', {
        start: date
      }).then(function (v) {
        return week = v;
      });

      var signedUrlPromise = _jquery.default.ajax({
        type: 'GET',
        url: this.configService.TIMETABLE_URL + '/timetable/signed',
        headers: {
          Authorization: "Bearer " + this.get("session.data.authenticated.token")
        }
      }).then(function (response) {
        // Add webcal:// to start of link, may have to add hostname as well
        if (response.signedUrl) {
          if (response.signedUrl.startsWith('/')) {
            response.signedUrl = "".concat(window.location.hostname).concat(response.signedUrl);
          }

          if (!response.signedUrl.startsWith('http') && !response.signedUrl.startsWith('webcal:')) {
            response.signedUrl = "webcal://".concat(response.signedUrl);
          }
        }

        signedUrl = response;
      });

      var timetablePromise = _jquery.default.ajax("".concat(this.configService.TIMETABLE_URL, "/timetable?start=").concat(timespanStart, "&end=").concat(timespanEnd), {
        type: 'GET',
        dataType: 'json',
        headers: {
          Authorization: "Bearer ".concat(this.get("session.data.authenticated.token"))
        }
      }).then(function (v) {
        return timetableResponse = v.timetable;
      }).catch(function (err) {
        var errMsg = 'N/A';

        if (err && err.responseJSON) {
          errMsg = err.responseJSON.message;
        }

        throw {
          code: 500,
          message: "Timetable Bad request - Err: ".concat(errMsg)
        };
      });

      var allPromises = [weeksMonthPromise, weekPromise, signedUrlPromise, timetablePromise]; // Only get semesters if we don't have them already

      var savedSemesters = this.store.peekAll('semester');

      if (savedSemesters.get('length')) {
        semesters = savedSemesters;
      } else {
        var semesterPromise = this.store.findAll('semester').then(function (v) {
          return semesters = v;
        });
        allPromises.push(semesterPromise);
      } // Fire off all requests simultaneously, then do all computations afterwards


      var returnPromise = Promise.all(allPromises).then(function () {
        // console.log(`### DEBUG - timetableResponse: `, timetableResponse);
        var events = timetableResponse.events;
        status = timetableResponse.status; //  create day object. This is just for today
        //  filter in today's events into the object just created

        currentDay.events = events.filter(function (event) {
          return event.classDate === currentDay.dateFull;
        });
        currentDay.day = (0, _moment.default)(date).format('dddd'); //  for each event, map onto the objects just created based on the key

        events.forEach(function (event) {
          if (!monthDays.get(event.classDate)) {
            return;
          }

          monthDays.get(event.classDate).events.push(event);

          if (weekDays.get(event.classDate)) {
            weekDays.get(event.classDate).events.push(event);
          }
        }); //  convert back from map into array (order ensured) by just taking the values

        monthDays = Array.from(monthDays.values());
        weekDays = Array.from(weekDays.values()); //  chunk month days into weeks to render in view

        for (var i = 0; i < 6; i++) {
          monthDaysChunked[i] = monthDays.slice(i * 7, i * 7 + 7);
        }

        weekDays = weekDays.slice(0, 7);

        for (var _i = 0, _Object$keys = Object.keys(weekDays); _i < _Object$keys.length; _i++) {
          var _i2 = _Object$keys[_i];
          weekDays[_i2].day = WEEK_DAYS[_i2];
        }

        var append = function append(event, layout, group) {
          // Push empty slot (null) where required
          if (!layout.includes(null)) {
            layout.push(null);
          } // Replace first empty slot (null) with event


          event.i = layout.indexOf(null);
          layout.splice(event.i, 1, event); // Push event into layout group, and update width of all events in layout group

          group.push(event);
          group.map(function (event) {
            return event.w = layout.length;
          });
        };

        var remove = function remove(event, layout, group) {
          // Check if event in layout
          if (!layout.includes(event)) {
            return;
          } // Replace event with empty slot (null)


          layout.splice(event.i, 1, null); // Check if layout has any events

          if (layout.filter(function (event) {
            return !!event;
          }).length === 0) {
            // Update width of all events in layout group
            group.map(function (event) {
              return event.w === layout.length;
            }); // Reset layout and layout group

            layout.length = 0;
            group.length = 0;
          }
        };

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          var _loop = function _loop() {
            var weekDay = _step.value;
            var events = weekDay.events; // Create event start and end timelines

            var start = events.map(function (event) {
              return {
                event: event,
                type: 'start',
                time: event.startTime
              };
            });
            var end = events.map(function (event) {
              return {
                event: event,
                type: 'end',
                time: event.endTime
              };
            }); // Concat timelines and sort by time, type (end, start), title

            var timeline = [].concat(start, end).sort(function (a, b) {
              if (a.time !== b.time) {
                return Math.sign(a.time - b.time);
              }

              if (a.type !== b.type) {
                return a.type.localeCompare(b.type);
              }

              if (a.event.title !== b.event.title) {
                return a.event.title.localeCompare(b.event.title);
              }

              return 0;
            }); // Prepare current layout and layout group

            var layout = [];
            var group = []; // Iterate through event timeline

            timeline.forEach(function (_ref) {
              var event = _ref.event,
                  type = _ref.type;

              if (type === 'start') {
                append(event, layout, group);
              } else {
                remove(event, layout, group);
              }
            });
            Ember.set(weekDay, 'events', events);
          };

          for (var _iterator = weekDays[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            _loop();
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }).then(function () {
        // Fix week labels ('Week X' instead of just 'X')
        function fixWeekLabel(week) {
          if (!week) {
            return '';
          }

          var weekName = week.toJSON().weekName;

          if (!weekName) {
            return '';
          } else if (weekName.length > 2) {
            return weekName;
          }

          return "Week ".concat(weekName);
        }

        var weekLabels = _toConsumableArray(weeksMonth.map(fixWeekLabel));

        var weekLabel = fixWeekLabel(week.toArray()[0]); // Remove weekends if there are no events (UQAPP-651)

        if (!weekDays[5].events.length && !weekDays[6].events.length) {
          weekDays.splice(5, 2);
        }

        semesters = semesters.map(function (semester) {
          return semester.toJSON();
        }); // Get name of current semester (if it exists)

        var currentSemester = '';

        if (semesters.length) {
          currentSemester = semesters[0].title;
        }

        var payload = {
          //  TODO set up a proper error handle here.
          weekLabels: weekLabels,
          currentMonthWeek: weekLabels[0],
          currentWeek: weekLabel,
          currentDay: currentDay,
          weekDays: weekDays,
          semesters: semesters,
          currentSemester: currentSemester,
          monthDays: monthDaysChunked,
          beginDate: date,
          signedUrlResponse: signedUrl,
          timespan: {
            start: timespanStart,
            end: timespanEnd
          },
          status: status
        };
        return payload;
      });
      this.quickCache[cacheKey] = returnPromise;
      return returnPromise;
    }
  });

  _exports.default = _default;
});