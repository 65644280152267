define("megaportal-frontend-uq-components/components/finals-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Panel = Ember.Component.extend({
    /*
      Assign correct attributes to the component so that Foundation can use them as tab panels
    */
    tagName: 'section',
    attributeBindings: ['role', 'id'],
    role: "tabpanel",
    classNames: ['content'],
    classNameBindings: ['checkIfActive:active'],
    results: [],
    //an null array to hold the result objects generated by each assessment criteria
    percentage: 0,
    desiredGrade: 85,
    preGraphMessage: 'For this grade, you must achieve',
    postGraphMessage: 'across the remainder of your courses assessment.',
    checkIfActive: Ember.computed('index', function () {
      //computed and assigned to  the component via the classNameBindings property
      if (!this.index) {
        return true;
      } else {
        return false;
      } // in the event that index is 0, assign this the active class

    }),
    actions: {
      changeDesiredGrade: function changeDesiredGrade(value) {
        this.set('desiredGrade', parseInt(value));
      },
      resultChanged: function resultChanged(results) {
        this.sendAction('resultsChanged', results);
      }
    },
    displayPercentage: Ember.observer('results.@each.percentage', 'desiredGrade', function () {
      //listens for changes to the results property. executed on init, as well as in the child component
      Ember.run.once(this, 'updatePercentage');
    }).on('init'),
    reRender: Ember.observer('examdata', function () {
      //TODO make this a function
      var examData = this.examdata,
          resultArray = []; //create a blank array to hold result entries

      examData.forEach(function (entry) {
        resultArray.pushObject(Ember.Object.create({
          unique_id: entry.unique_id,
          percentage: entry.result,
          weighting: entry.weighting,
          assess_title: entry.assess_title
        })); // push a new result object
      });
      this.set('results', resultArray); //set the local property to the newly compiled results array
    }),
    updatePercentage: function updatePercentage() {
      var percentageInfo = Panel.calculatePercentage(this.results, this.desiredGrade);
      this.set('percentage', percentageInfo[0]);
      this.set('preGraphMessage', percentageInfo[1]);
      this.set('postGraphMessage', percentageInfo[2]);
    },
    init: function init() {
      //on init
      this._super.apply(this, arguments); //infer use of super class


      var examData = this.examdata,
          resultArray = []; //create a blank array to hold result entries

      examData.forEach(function (entry) {
        resultArray.pushObject(Ember.Object.create({
          unique_id: entry.unique_id,
          percentage: entry.result,
          weighting: entry.weighting,
          assess_title: entry.assess_title
        })); // push a new result object
      });
      this.set('results', resultArray); //set the local property to the newly compiled results array
    }
  });
  Panel.reopenClass({
    calculatePercentage: function calculatePercentage(results, desiredGrade) {
      var percentage = 0,
          percentageNeeded = 0,
          incompleteAssessment = [],
          preGraphMessage = 'For this grade, you must achieve',
          postGraphMessage = 'across the remainder of your courses assessment.';
      results.forEach(function (obj) {
        //iterate over the results property and create a running total of the percentage
        if (!obj.percentage) {
          incompleteAssessment.push(obj);
        }

        percentage += obj.percentage * (obj.weighting / 100);
      });
      percentageNeeded = Math.floor(desiredGrade - percentage);

      if (incompleteAssessment.length === 1 && percentageNeeded <= 100) {
        percentageNeeded = Math.floor(percentageNeeded / incompleteAssessment[0].weighting * 100);

        if (percentageNeeded >= 100) {
          preGraphMessage = 'Sorry, you are unable to achieve this grade.';
          postGraphMessage = '';
        } else {
          postGraphMessage = 'on the final piece of assessment.';
        }
      } else if (!incompleteAssessment.length && percentageNeeded >= 0) {
        preGraphMessage = 'Sorry, you did not achieve this grade.';
        postGraphMessage = '';
      }

      if (percentageNeeded <= 0) {
        return ["0%", 'Congratulations, you have achieved this result.', ''];
      } else {
        return [percentageNeeded + "%", preGraphMessage, postGraphMessage];
      }
    }
  });
  var _default = Panel;
  _exports.default = _default;
});