define("megaportal-frontend-uq-components/models/location", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Location = Ember.Object.extend({});
  Location.reopenClass({
    findById: function findById(id) {
      return _jquery.default.ajax('/api/locations/' + id, {
        dataType: 'json'
      }).then(function (resp) {
        return Location.create(resp);
      });
    },
    nearbyFoodOutlets: function nearbyFoodOutlets(lat, lon) {
      return _jquery.default.ajax('/api/locations/nearby/food-outlets', {
        data: {
          q: lat + "," + lon
        },
        dataType: 'json'
      }).then(function (resp) {
        return resp.map(function (location) {
          return Location.create(location);
        });
      });
    },
    nearbyStudySpots: function nearbyStudySpots(lat, lon) {
      return _jquery.default.ajax('/api/locations/nearby/study', {
        data: {
          q: lat + "," + lon
        },
        dataType: 'json'
      }).then(function (resp) {
        return resp.map(function (location) {
          return Location.create(location);
        });
      });
    },
    //TODO: change this function and the backend to return a proper list of all locations
    allLocationsOfType: function allLocationsOfType(type) {
      var lat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '-27.496928';
      var lon = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '153.013072';
      return _jquery.default.ajax('/api/locations/nearby/' + type, {
        data: {
          q: lat + "," + lon,
          distance: '9999999999m'
        },
        dataType: 'json'
      }).then(function (resp) {
        return resp.map(function (location) {
          return Location.create(location);
        });
      });
    }
  });
  var _default = Location;
  _exports.default = _default;
});