define("megaportal-frontend-uq-components/routes/apps/myfiles/login", ["exports", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin"], function (_exports, _myuqAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    messageHandler: null,
    ftpService: Ember.inject.service(),
    activate: function activate() {
      var _this = this;

      var ftpService = this.ftpService;
      this.set('messageHandler', function (e) {
        if (e.origin.endsWith('uq.edu.au')) {
          var payload = e.data;

          try {
            payload = JSON.parse(payload);
          } catch (err) {// ignore failed attempt to parse
          }

          if (payload.action === 'loading') {
            _this.set('ftpService.loading', payload.data);
          } else if (payload.action === 'response') {
            ftpService.setToken(JSON.parse(payload.data).token);
            ftpService.setup();

            _this.transitionTo('apps.myfiles.index');
          }
        }
      }); // old browser compatibility

      if (window.addEventListener) {
        window.addEventListener('message', this.messageHandler, false);
      } else if (window.attachEvent) {
        window.attachEvent('on' + 'message', this.messageHandler);
      }
    },
    deactivate: function deactivate() {
      window.removeEventListener('message', this.messageHandler);
    }
  });

  _exports.default = _default;
});