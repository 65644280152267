define("megaportal-frontend-uq-components/models/config", ["exports", "jquery", "megaportal-frontend-uq-components/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Config = Ember.Object.extend({});
  Config.reopenClass({
    fetch: function fetch() {
      if (_environment.default.environment === 'test') {
        // test has a Cloudfront Distribution so Lambda's API's endpoints are redirections, and therefore
        // their URLs are relatives
        return {
          FEATURES_URL: '/features',
          NEWS_AND_EVENTS_URL: '/news-api',
          SINET_URL: 'https://sa92prep.dev.sinet.uq.edu.au',
          // SINET_URL: 'https://sso-sinet-test.ads.aws.uq.edu.au',
          // SINET_URL: 'https://sso-sinet.ads.aws.uq.edu.au',
          // SINET_URL: 'https://sa92ptst.dev.sinet.uq.edu.au',
          TIMETABLE_URL: '/timetable',
          FAVOURITES_URL: '/favourites/',
          IMS_API_URL: 'https://prod.api.ims.its.uq.edu.au',
          MY_FILES_DOMAIN: '',
          IDENTITY_URL: ''
        };
      } else if (_environment.default.environment === 'development') {
        // dev / sandbox do not have a Cloudfront distribution, there is no redirection for the Lambda
        // endpoints so all of them are direct/absolute paths.
        // @TODO These urls should be updated to sandbox as long as the lambdas get uploaded on sandbox
        return {
          FAVOURITES_URL: 'https://myuq-api.ads-test.aws.uq.edu.au/favourites',
          // TEST
          // FAVOURITES_URL: 'https://myuq-api.sandbox.aws.uq.edu.au/favourites',// SANDBOX
          FEATURES_URL: 'https://myuq-api.ads-test.aws.uq.edu.au/features',
          // TEST
          // FEATURES_URL: 'https://myuq-api.sandbox.aws.uq.edu.au/features', // SANDBOX
          IDENTITY_URL: 'https://myuq-api.ads-test.aws.uq.edu.au/identity',
          // TEST
          // IDENTITY_URL: 'https://myuq-api.sandbox.aws.uq.edu.au/identity', // SANDBOX
          TIMETABLE_URL: 'https://myuq-api.ads-test.aws.uq.edu.au/timetable',
          // TEST
          // TIMETABLE_URL: 'https://myuq-api.sandbox.aws.uq.edu.au/timetable', // SANDBOX
          NEWS_AND_EVENTS_URL: 'https://myuq-api.ads-test.aws.uq.edu.au/news-api',
          IMS_API_URL: 'https://prod.api.ims.its.uq.edu.au',
          SINET_URL: 'https://sa92prep.dev.sinet.uq.edu.au',
          // SINET_URL: 'https://sso-sinet-test.ads.aws.uq.edu.au',
          // SINET_URL: 'https://sa92ptst.dev.sinet.uq.edu.au',
          MY_FILES_DOMAIN: ''
        };
      } else {
        // Otherwise it's a normal flow for config, and it's brought from the config lambda
        return _jquery.default.ajax('/api/config', {
          dataType: 'json'
        }).then(function (resp) {
          return resp;
        });
      }
    }
  });
  var _default = Config;
  _exports.default = _default;
});