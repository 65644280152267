define("megaportal-frontend-uq-components/components/ftp-client-upload-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'ftp-client-upload-modal',
    ftpService: Ember.inject.service(),
    actions: {
      //shows system file browser to select files to upload
      selectFiles: function selectFiles() {
        (0, _jquery.default)('input.ftp-client-upload-hidden').click();
      },
      //begins upload when the files in the hidden input actually change
      uploadFiles: function uploadFiles() {
        this.ftpService.upload((0, _jquery.default)('input.ftp-client-upload-hidden')[0].files);
      },
      cancel: function cancel() {
        //this hides the panel in the DOM
        this.ftpService.set('showUploadPanel', false); //this cleans the completed uploads so that the next time the user opens the panel, it is empty.

        this.ftpService.set('completedFileUploads', []);
      }
    }
  });

  _exports.default = _default;
});