define("megaportal-frontend-uq-components/routes/apps/ims/view", ["exports", "jquery", "megaportal-frontend-uq-components/models/ims", "megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin"], function (_exports, _jquery, _ims, _myuqAuthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_myuqAuthenticatedRouteMixin.default, {
    imsService: Ember.inject.service(),
    metrics: Ember.inject.service(),
    authManager: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        activeSessions: this.imsService.getToken().then(function (token) {
          return _ims.default.fetchActiveSessions(token).then(function (data) {
            return data.sort(function (a, b) {
              // sort by most recent time first
              var aTime = moment(a.start_time).toDate().getTime();
              var bTime = moment(b.start_time).toDate().getTime();
              return bTime - aTime;
            });
          });
        })
      });
    },
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      return this.imsService.getToken().then(function (token) {
        _ims.default.fetchSessionDurationPreference(token).then(function (duration) {
          _this.set('imsService.sessionDuration', duration.session_duration);
        });
      });
    },
    actions: {
      sessionLogout: function sessionLogout(session) {
        var _this2 = this;

        this.imsService.showLogoutDialog(session).then(function (data) {
          if (data.refresh) {
            _this2.metrics.trackEvent({
              category: 'InternetSessions',
              action: 'Log out',
              label: session.source,
              value: 1,
              dimension1: true
            });

            setTimeout(function () {
              _this2.refresh();
            }, 500);
          }
        });
      },
      toggleChangeSessionDuration: function toggleChangeSessionDuration() {
        this.imsService.toggleChangeSessionDuration();
        Ember.run.scheduleOnce('afterRender', this, function () {
          if ((0, _jquery.default)(document).foundation) {
            (0, _jquery.default)(document).foundation('equalizer', 'reflow');
          }
        });
      },
      saveDuration: function saveDuration() {
        var _this3 = this;

        var imsService = this.imsService;
        imsService.changeSessionDuration().then(function (response) {
          _this3.metrics.trackEvent({
            category: 'InternetSessions',
            action: 'Edit',
            label: imsService.get('sessionDuration'),
            value: 1,
            dimension1: true
          });

          Ember.run.scheduleOnce('afterRender', _this3, function () {
            if ((0, _jquery.default)(document).foundation) {
              (0, _jquery.default)(document).foundation('equalizer', 'reflow');
            }
          });
          return response;
        });
      }
    }
  });

  _exports.default = _default;
});