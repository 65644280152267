define("megaportal-frontend-uq-components/components/request-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authManager: Ember.inject.service(),
    tagName: 'div',
    classNames: ['request'],
    classNameBindings: ["isCritical:request__critical"],
    isCritical: Ember.computed('isCritical', function () {
      return this.request.isCritical;
    }),
    archivedStatus: Ember.computed('archived', function () {
      return this.request.archived ? "archived" : "";
    })
  });

  _exports.default = _default;
});