define("megaportal-frontend-uq-components/templates/apps/my-requests/make-request/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O8AuLCu5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"apps-header\",null,[[\"iconClasses\"],[\"icon icon-pencil-square-o icon--prefix\"]],{\"statements\":[[0,\"My Requests\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[29,\"unitask-form\",null,[[\"formUrl\",\"crmQuery\",\"breadcrumb\"],[[25,[\"model\",\"formUrl\"]],[25,[\"model\",\"crmQuery\"]],\"view\"]]],false],[0,\"\\n\\n\"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/apps/my-requests/make-request/view.hbs"
    }
  });

  _exports.default = _default;
});