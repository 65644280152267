define("megaportal-frontend-uq-components/components/calendar-view/mobile/dashboard-widget", ["exports", "megaportal-frontend-uq-components/templates/components/calendar-view/mobile/dashboard-widget", "megaportal-frontend-uq-components/components/calendar-view/constants", "moment"], function (_exports, _dashboardWidget, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dashboardWidget.default,
    timeGutterIndictators: _constants.timeGutterIndictators,
    dayTitles: _constants.dayTitles,
    viewTypes: _constants.viewTypes,
    timetableService: Ember.inject.service(),
    loading: true,
    hiddenIndicator: 0,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      // Get timetable
      this.timetableService.getTimetable((0, _moment.default)().format('YYYY-MM-DD')).then(function (timetablePayload) {
        _this.set('timetable', timetablePayload);

        _this.set('loading', false);
      });
      return this._super.apply(this, arguments);
    },
    events: Ember.computed('timetable', function () {
      var timetable = this.timetable;

      if (!timetable) {
        return [];
      }

      return timetable.currentDay.events.sort(function (eventA, eventB) {
        return eventA.startTime - eventB.startTime;
      }).filter(function (event) {
        var now = (0, _moment.default)().format('HHmm');
        return parseInt(now) <= parseInt(event.endTime);
      });
    }),
    firstEvent: Ember.computed('events', function () {
      var events = this.events;

      if (!events.length) {
        return false;
      }

      return events[0];
    }),
    otherEvents: Ember.computed('events', function () {
      var events = this.events;

      if (!events.length) {
        return [];
      }

      return events.slice(1);
    }),
    actions: {}
  });

  _exports.default = _default;
});