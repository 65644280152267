define("megaportal-frontend-uq-components/services/config-service", ["exports", "megaportal-frontend-uq-components/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Config service is used for passing environmental constants to engines
   */
  var _default = Ember.Service.extend({
    STAGE: _environment.default.STAGE,
    SINET_URL: _environment.default.SINET_URL,
    NEWS_AND_EVENTS_URL: _environment.default.NEWS_AND_EVENTS_URL,
    TIMETABLE_URL: _environment.default.TIMETABLE_URL,
    LEARN_UQ: _environment.default.learnUq,
    MY_FILES_DOMAIN: _environment.default.MY_FILES_DOMAIN,
    IDENTITY_URL: _environment.default.IDENTITY_URL,
    MULTI_FACTOR_AUTHENTICATION_VIEW_AND_MANAGE_URL: _environment.default.MULTI_FACTOR_AUTHENTICATION_VIEW_AND_MANAGE_URL
  });

  _exports.default = _default;
});