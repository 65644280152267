define("megaportal-frontend-uq-components/mixins/myuq-authenticated-route-mixin", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    routing: Ember.inject.service('-routing'),
    beforeModel: function beforeModel(transition) {
      var routing = this.routing;
      var models = Object.keys(transition.params).map(function (key) {
        return transition.params[key];
      }).filter(function (param) {
        return Object.keys(param).length;
      });
      var url = routing.generateURL(transition.targetName, models, transition.queryParams).substring(1);

      if (typeof Storage !== 'undefined') {
        sessionStorage.setItem('routeName', url);
      }

      this._super.apply(this, arguments);
    },
    actions: {
      willTransition: function willTransition() {
        if (typeof Storage !== 'undefined') {
          sessionStorage.removeItem('routeName');
        }

        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});