define("megaportal-frontend-uq-components/routes/apps/notifications/index", ["exports", "megaportal-frontend-uq-components/models/notifications", "megaportal-frontend-uq-components/models/unitask"], function (_exports, _notifications, _unitask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    authManager: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return this.authManager.waitForAccount().then(function () {
        return Ember.RSVP.hash({
          notifications: _notifications.default.fetch(),
          tasks: _this.get('session.isAuthenticated') ? _unitask.default.fetchTasks() : null
        });
      });
    }
  });

  _exports.default = _default;
});