define("megaportal-frontend-uq-components/utils/cache-fetch", ["exports", "megaportal-frontend-uq-components/utils/safe-local-storage"], function (_exports, _safeLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchCache = fetchCache;
  _exports.getCache = getCache;
  _exports.setCache = setCache;
  _exports.removeCacheItem = removeCacheItem;
  _exports.clearCache = clearCache;

  /**
   * Hit a localStorage cache first to speed up long running requests
   * @param cacheParams
   * @param fn
   * @returns {*}
   */
  function fetchCache(cacheParams, fn) {
    if ((0, _safeLocalStorage.checkLocalStorage)()) {
      var userCache = getCache(cacheParams); // only run if within TTL

      if (userCache !== null && userCache.time + cacheParams.ttl * 1000 > Date.now()) {
        setTimeout(function () {
          fn().then(function (data) {
            setCache(cacheParams, data);
          });
        }, 10);
        return Promise.resolve(userCache.data);
      } else {
        removeCacheItem(cacheParams);
      }
    }

    return fn().then(function (data) {
      setCache(cacheParams, data);
      return data;
    });
  }

  function getCache(cacheParams) {
    var userCache;

    try {
      userCache = JSON.parse(window.localStorage.getItem(cacheParams.username));
    } catch (e) {
      userCache = {};
    }

    try {
      return userCache[cacheParams.path] || null;
    } catch (e) {
      return null;
    }
  }

  function setCache(cacheParams, data) {
    if (!(0, _safeLocalStorage.checkLocalStorage)()) {
      return;
    }

    var userCache;

    try {
      userCache = JSON.parse(window.localStorage.getItem(cacheParams.username));
    } catch (e) {
      userCache = {};
    }

    if (userCache === null) {
      userCache = {};
    }

    userCache[cacheParams.path] = {
      data: data,
      time: Date.now()
    };
    window.localStorage.setItem(cacheParams.username, JSON.stringify(userCache));
  }

  function removeCacheItem(cacheParams) {
    if (!(0, _safeLocalStorage.checkLocalStorage)()) {
      return;
    }

    var userCache;

    try {
      userCache = JSON.parse(window.localStorage.getItem(cacheParams.username));
    } catch (e) {
      userCache = {};
    }

    if (userCache === null) {
      userCache = {};
    }

    delete userCache[cacheParams.path];
    window.localStorage.setItem(cacheParams.username, JSON.stringify(userCache));
  }

  function clearCache(username) {
    if ((0, _safeLocalStorage.checkLocalStorage)()) {
      window.localStorage.removeItem(username);
    }
  }
});