define("megaportal-frontend-uq-components/mixins/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notificationService: Ember.inject.service(),
    actions: {
      loading: function loading() {
        var _this = this;

        this.set('notificationService.loading', true);

        if (this._router) {
          this._router.one('didTransition', function () {
            _this.set('notificationService.loading', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});