define("megaportal-frontend-uq-components/components/ftp-client-toolbar", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ftp-client-toolbar', 'row', 'large-collapse'],
    ftpService: Ember.inject.service(),
    actions: {
      //adds a folder entrypoint in the DOM
      mkdir: function mkdir() {
        var ftpService = this.ftpService;

        if (!ftpService.get('mkdirEntry')) {
          ftpService.set('mkdirEntry', true); //TODO super hacky. This waits 10 milliseconds so that the element is drawn into the DOM.
          //no promise is returned from Ember.Component.set so a then callback cannot be instanciated
          //maybe find a better way to do this
          //also we nuke the input value here 

          setTimeout(function () {
            (0, _jquery.default)('div.ftp-client-entry-mkdir input').val('').focus();
          }, 10);
        } else {
          //this is a toggle. If they already clicked it, then it should actually hide instead
          ftpService.set('mkdirEntry', false);
        }
      },
      toDirectory: function toDirectory(directoryName) {
        var ftpService = this.ftpService; //if it is the home directory, just nuke the breadcrumbs

        if (directoryName === '/') {
          ftpService.set('breadcrumbs', []);
        } else {
          var //iterate through this to find which breadcrumb the user wishes to nav to
          breadcrumbs = ftpService.get('breadcrumbs'),
              newBreadcrumbs = [],
              lastCrumb = false; //iterate over array

          breadcrumbs.forEach(function (bc) {
            //push the current breadcrumb into the new array
            if (!lastCrumb) {
              newBreadcrumbs.pushObject(bc);
            } //in the event that this is the desired breadcrumb, we don't want to 
            //keep adding breadcrumb objects


            if (bc.entryName === directoryName) {
              lastCrumb = true;
            }
          });
          ftpService.set('breadcrumbs', newBreadcrumbs);
        }
      },
      upload: function upload() {
        var ftpService = this.ftpService;
        ftpService.set('showUploadPanel', true);
      }
    }
  });

  _exports.default = _default;
});