define("megaportal-frontend-uq-components/templates/components/drag-upload-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3k069OmS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"drag-upload-overlay\"],[9],[0,\"\\n\\t\\n\\t\"],[7,\"a\"],[11,\"class\",\"ftp-client-toolbar-icon icon icon-upload icon--linked\"],[9],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"drag-upload-overlay__message\"],[9],[0,\"Drop your files to upload\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/drag-upload-overlay.hbs"
    }
  });

  _exports.default = _default;
});