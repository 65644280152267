define("megaportal-frontend-uq-components/components/drag-upload-overlay", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['drag-upload-overlay'],
    ftpService: Ember.inject.service(),
    //we use this in the animate method to change how long the animation is. 0 will render an instantanious transition
    animTime: 150,
    isHidden: true,
    init: function init() {
      //NOTE
      //This solution SUCKS. This is the problem:

      /*
      		Usually you would associate a mouse event action with the body tag, or the application wrapper.
      	This way, if you wish to drag files to anywhere within the body, or within that wrapper, you can
      	trigger an ember action. However, in this case, that would create a functional dependency within 
      	the application, rendering the componentisation of the ftp-client completely pointless. Everything
      	needs to be contained within the component for it to function correctly. So instead, here we insert
      	a dom fullscreen element from within the component, and then use jQuery to append an event listener 
      	to the body. This then shows the upload. But ONLY on your first visit. If you vaigate away from the 
      	filebrowser, it stops working until a refresh
      	*/
      this._super.apply(this, arguments);

      var body = (0, _jquery.default)('body'),
          self = this;
      body.on( //this change in boolean triggers an animation according to the obserable method below
      'dragenter dragover', function (event) {
        event.preventDefault();
        event.stopPropagation();
        self.set('isHidden', false);
      }).on( //start upload
      'drop', function (event) {
        //here we completely cut out any default actions that your browser will want to make.
        //mainly, this is to stop the file from opening in the actual browser, which is the 
        //default action 9/10
        event.preventDefault();
        event.stopPropagation();
        var files = event.dataTransfer.files; //send this away to the service to deal with

        self.get('ftpService').upload(files); //this will also prevent the overlay showing while the files upload

        self.set('isHidden', true);
      }).on('dragleave', function (event) {
        event.preventDefault();
        event.stopPropagation();
        self.set('isHidden', true);
      });
    },
    //observing the isHidden boolean allows the component to trigger an animation when we change the view
    animate: Ember.observer('isHidden', function () {
      var //using jQuery to reference the DOM element
      ele = (0, _jquery.default)('div.drag-upload-overlay'),
          //prop from component
      animTime = this.animTime,
          //injected
      hide = this.isHidden; //if the component is to be unhidden, stop all previous animations and fadeIn according to the animTime
      //if the component is to be hidden, do the opposite

      if (!hide) {
        ele.stop().fadeIn(animTime);
      } else {
        ele.stop().fadeOut(animTime);
      }
    })
  });

  _exports.default = _default;
});