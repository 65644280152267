define("megaportal-frontend-uq-components/templates/components/course-modes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BIila7rD",
    "block": "{\"symbols\":[\"mode\"],\"statements\":[[4,\"if\",[[29,\"is-equal\",[[25,[\"readyState\"]],\"loading\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"spinner-in-document\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"spinner-contents\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"is-equal\",[[25,[\"readyState\"]],\"error\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"panel__content\"],[9],[0,\"Whoops! Something went wrong while looking up the course modes.\"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"is-equal\",[[25,[\"readyState\"]],\"ready\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flexbox withWrap\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"courses\"]]],null,{\"statements\":[[4,\"link-to\",[\"apps.courses.view\",[24,1,[\"courseCode\"]],[24,1,[\"termCode\"]],[24,1,[\"classNumber\"]]],[[\"class\"],[\"flexchild\"]],{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"staff-course-profile-link\"],[9],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"staff-course-profile-link-inner\"],[9],[1,[24,1,[\"details\",\"mode_title\"]],false],[10],[7,\"span\"],[11,\"class\",\"icon icon-chevron-right\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/course-modes.hbs"
    }
  });

  _exports.default = _default;
});