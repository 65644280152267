define("megaportal-frontend-uq-components/components/calendar-view/event-block", ["exports", "megaportal-frontend-uq-components/templates/components/calendar-view/event-block", "moment"], function (_exports, _eventBlock, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var HOURS_BLOCK_HEIGHT = 60;
  var TOP_SPACER = 12;

  var _default = Ember.Component.extend({
    layout: _eventBlock.default,
    event: null,
    displayModal: false,
    style: Ember.computed("event", function () {
      var event = this.event;

      if (event) {
        return Ember.String.htmlSafe("left: calc(".concat(100 * (event.i / event.w), "% + ").concat(event.i > 0 ? 1 : 0, "px); width: calc(").concat(1 / event.w * 100, "% - ").concat(event.i > 0 ? 1 : 0, "px); height: ").concat(event.blockHeight, "px; top:").concat(event.startPos, "px;"));
      }

      return Ember.String.htmlSafe("");
    }),
    didInsertElement: function didInsertElement() {
      var event = _objectSpread({}, this.event);

      event.startTime = (0, _moment.default)(event.startTime, "Hmm");
      event.endTime = (0, _moment.default)(event.endTime, "Hmm");
      event.startPos = _moment.default.duration(event.startTime.diff((0, _moment.default)("700", "Hmm"))).asHours() * HOURS_BLOCK_HEIGHT + TOP_SPACER;
      event.blockHeight = HOURS_BLOCK_HEIGHT * _moment.default.duration(event.endTime.diff(event.startTime)).asHours();
      event.startTime = event.startTime.format("h:mm A");
      event.endTime = event.endTime.format("h:mm A");
      this.set("event", event);
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty("displayModal");
      }
    }
  });

  _exports.default = _default;
});