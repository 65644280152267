define("megaportal-frontend-uq-components/components/ftp-client-entry-recursive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ftpService: Ember.inject.service(),
    isSelected: false,
    //if a directory is selected when the user wants to move something, we use this to associate a non
    //selectable class with the component. This ensures that there are no pointer events for this directory
    //and the user doesn't move a directory into itself or a child directory of itself. That would be really
    //bad.
    isNonSelectable: Ember.computed('directory', function () {
      return this.directory.nonselectable;
    }),
    //This is a crap implimentation but right now CSS isn't being my friend and first-child doesn't work.
    //I'm a programmer, not a designer. Will fix this later
    isFirst: Ember.computed('directory', function () {
      return this.directory.name === 'MyFiles';
    }),
    classNameBindings: ['isSelected:ftp-client-entry-recursive-selected', 'isNonSelectable:ftp-client-entry-recursive-nonselectable', 'isFirst:ftp-client-entry-recursive-first'],
    actions: {
      selectThis: function selectThis() {
        var ftpService = this.ftpService;
        ftpService.set('selectedRecursiveEntry', this.directory);
      }
    },
    selectedEntryDidChange: Ember.observer('ftpService.selectedRecursiveEntry', function () {
      this.set('isSelected', this.ftpService.selectedRecursiveEntry === this.directory);
    })
  });

  _exports.default = _default;
});