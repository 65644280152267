define("megaportal-frontend-uq-components/controllers/apps/my-requests/process-error-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['errorText'],
    errorText: null
  });

  _exports.default = _default;
});