define("megaportal-frontend-uq-components/templates/components/group-unitask-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7T5c4RJW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"apps.my-requests\"],[[\"class\"],[[29,\"concat\",[\"panel panel--link panel--alert notification--panel\",\" \",[29,\"if\",[[25,[\"isPriority\"]],\"panel--alert-critical\"],null],\" \"],null]]],{\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"column small-9 large-10\"],[9],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"panel__title\"],[9],[0,\"My Requests: you have \"],[1,[23,\"count\"],false],[0,\" tasks\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"statusText\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"panel__content\"],[9],[1,[23,\"statusText\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"column large-2 panel__aside show-for-large-up\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"dashboard-widget__notification-link\"],[9],[4,\"link-to\",[\"apps.my-requests\"],null,{\"statements\":[[0,\"Update\"]],\"parameters\":[]},null],[0,\" \"],[7,\"span\"],[11,\"class\",\"icon icon-chevron-right\"],[9],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"hide-for-large-up\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column small-3 panel__aside\"],[9],[0,\"\\n       \"],[7,\"span\"],[11,\"class\",\"icon icon-chevron-right\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"dashboard-widget__notification-link\"],[9],[4,\"link-to\",[\"apps.my-requests\"],null,{\"statements\":[[0,\"Update\"]],\"parameters\":[]},null],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/group-unitask-notification.hbs"
    }
  });

  _exports.default = _default;
});