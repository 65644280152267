define("megaportal-frontend-uq-components/helpers/human-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanDate = humanDate;
  _exports.default = void 0;

  function humanDate(params
  /*, hash*/
  ) {
    var date = params[0];
    return (0, _moment.default)(date).fromNow();
  }

  var _default = Ember.Helper.helper(humanDate);

  _exports.default = _default;
});