define("megaportal-frontend-uq-components/helpers/short-time-range", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //  curried private function, applies provided format to provided date regardless of the dates type
  var _format = function _format(date) {
    return function (format) {
      return (typeof date === 'string' ? (0, _moment.default)(date) : date).format(format);
    };
  };

  var shortTimeRange = function shortTimeRange(params) {
    var RAW_FORMAT = "hmm",
        DATE_FORMAT = 'dddd MMMM DD',
        TIME_FORMAT = 'h:mma';

    try {
      var //  momentifys the input params according to a RAW_FORMAT if they are of a non string type
      //  we do this because some API endpoints provide a number like 850 or something
      start = (0, _moment.default)(params[0], typeof params[0] !== 'string' ? RAW_FORMAT : null),
          end = (0, _moment.default)(params[1], typeof params[1] !== 'string' ? RAW_FORMAT : null),
          showDate = params[2],
          date = showDate ? _format(start)(DATE_FORMAT) + ', ' : '',
          allDay = start.twix(end).length('hours') === 23;
      return date.concat(allDay ? 'all day' : _format(start)(TIME_FORMAT).concat(' to ', _format(end)(TIME_FORMAT)));
    } catch (e) {
      console.warn('Error at short-time-range: params provided:', params, 'Error thrown: ', e);
      return;
    }
  };

  var _default = Ember.Helper.helper(shortTimeRange);

  _exports.default = _default;
});