define("megaportal-frontend-uq-components/routes/application", ["exports", "jquery", "megaportal-frontend-uq-components/models/account", "ember-simple-auth/mixins/application-route-mixin", "megaportal-frontend-uq-components/mixins/loading", "megaportal-frontend-uq-components/authenticators/sso", "megaportal-frontend-uq-components/utils/cache-fetch"], function (_exports, _jquery, _account, _applicationRouteMixin, _loading, _sso, _cacheFetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _loading.default, {
    session: Ember.inject.service('session'),
    authManager: Ember.inject.service(),
    featureService: Ember.inject.service(),
    notificationService: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      if (typeof Storage !== 'undefined' && sessionStorage.getItem('invalidated') === 'expired') {
        sessionStorage.removeItem('invalidated');
        this.transitionTo('login');
      } else {
        this.authManager.authenticate().catch(function (err) {
          if (err.message === 'invalid token') {
            _this.transitionTo('login');
          } else if (err.message !== 'no token') {
            throw err;
          }
        });
      }
    },
    renderTemplate: function renderTemplate() {
      this.render('application');
    },
    actions: {
      didTransition: function didTransition() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this._super(args); // clear My Request notifications on route transition


        this.notificationService.clearMessages('myrequests');
        return true;
      },
      closeNavigationMenu: function closeNavigationMenu() {
        (0, _jquery.default)('.exit-off-canvas').trigger('click');
      },
      login: function login() {
        var _this2 = this;

        this.authManager.authenticate().catch(function (err) {
          if (err.message === 'no token') {
            _this2.authManager.login();
          } else {
            throw err;
          }
        });
      },
      refreshApplicationModels: function refreshApplicationModels() {
        //Refresh user model to get new token
        this.session.authenticate('authenticator:sso', {}).then(function () {
          this.refresh();
        }.bind(this));
      },
      logout: function logout() {
        // Remove any fetch cache data
        (0, _cacheFetch.clearCache)(this.authManager.get('username'));
        this.session.invalidate({
          reason: 'logout'
        });
      },
      error: function error(_error) {
        //if we get a 401 or a 403, log user out of application
        if (!Ember.isEmpty(_error) && !Ember.isEmpty(_error.status) && (_error.status === 401 || _error.status === 403)) {
          this.transitionTo("login");
        }

        return true;
      }
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this.featureService.loadFeatures();
      var previousRouteName = 'dashboard'; // In case the sessionStorage does not exist

      if (typeof Storage !== 'undefined') {
        previousRouteName = sessionStorage.getItem('routeName');
      }

      this.refresh();

      if (previousRouteName) {
        this.transitionTo(previousRouteName);
      }
    },
    sessionInvalidated: function sessionInvalidated() {
      if (typeof Storage !== 'undefined') {
        if (sessionStorage.getItem('invalidated') === 'logout') {
          sessionStorage.removeItem('invalidated');
          sessionStorage.removeItem('routeName');
          this.transitionTo('logout');
        } else {
          sessionStorage.setItem('invalidated', 'expired');
          Ember.run.scheduleOnce('afterRender', this, function () {
            window.location.replace(_sso.default.serverLogoutEndpoint);
          });
        }
      } else {
        this.transitionTo('logout');
      }
    }
  });

  _exports.default = _default;
});