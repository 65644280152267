define("megaportal-frontend-uq-components/domain/MultiFactorAuthenticationStatus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    status: "inactive",
    isInBypass: false,
    isRequired: true,
    iSEnrolled: false,
    numberOfDevices: 0,
    enforcementDate: null,
    isEnabled: Ember.computed('status', function () {
      return this.status && this.status.toLowerCase() === "active";
    })
  });

  _exports.default = _default;
});