define("megaportal-frontend-uq-components/components/crm-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crmQuery: '',
    authManager: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.authManager.waitForAccount().then(function (r) {
        var crmQuery = _this.crmQuery;
        var crmUrl;

        if (_this.get("authManager.isStudent")) {
          crmUrl = "https://uqcurrent.widget.custhelp.com/ci/ws/get";
        } else if (_this.get("authManager.isStaff")) {
          crmUrl = "https://uqemployee.widget.custhelp.com/ci/ws/get";
        }

        console.log("CRM Query:", crmQuery, crmUrl);
        Ember.run.schedule('afterRender', _this, function () {
          RightNow.Client.Controller.addComponent({
            display_answers_in_overlay: false,
            recommended: false,
            target: "_blank",
            div_id: "myCrmDivQuestionsAnswers",
            q: crmQuery,
            related: false,
            search_box: false,
            instance_id: "skw_0",
            module: "KnowledgeSyndication",
            type: 3
          }, crmUrl); //This doesnt appear to work...

          /*RightNow.Client.Event.evt_widgetLoaded.subscribe(function (event_name, data) {
            alert('arg');
            console.log("searchResponse from CRM");
          });*/
        });
      });
    }
  });

  _exports.default = _default;
});