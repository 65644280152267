define("megaportal-frontend-uq-components/instance-initializers/notification-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    var notificationService = applicationInstance.lookup('service:notification-service');
    window.addEventListener('message', function (event) {
      // let origin = event.origin || event.originalEvent.origin;
      var data = event.data;

      if (data.eventType === 'thank_you') {
        // TODO: check origin comes from unitask.
        var router = applicationInstance.lookup('service:router');
        var transition = router.transitionTo('apps.my-requests');
        transition.then(function () {
          notificationService.newNotification(data.messageType || 'success', data.message || 'Thank you!', 'myrequests');
        });
      }
    }, false);
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});