define("megaportal-frontend-uq-components/components/drop-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      select: function select(newFilter) {
        this.set('filter', newFilter);
      }
    }
  });

  _exports.default = _default;
});