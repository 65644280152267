define("megaportal-frontend-uq-components/templates/apps/myfiles/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j5lQ3zj7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"apps-header\",null,[[\"iconClasses\"],[\"icon icon-folder-open-o icon--prefix\"]],{\"statements\":[[0,\"My Files\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[29,\"fullscreen-loader\",null,[[\"isActive\"],[[25,[\"ftpService\",\"loading\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"column large-8 small-12\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"section\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"section__header\"],[9],[0,\"\\n        \"],[7,\"h1\"],[9],[0,\"My files\"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"section__content\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"For security reasons, please re-enter your password to access My Files.\"],[10],[0,\"\\n        \"],[7,\"iframe\"],[11,\"id\",\"MyUQinlineIframe\"],[12,\"src\",[30,[[25,[\"configService\",\"MY_FILES_DOMAIN\"]],\"/myfiles.html#jwt=\",[23,\"jwt\"],\"&url=\",[23,\"url\"]]]],[11,\"style\",\"width:100%;height:300px;\"],[11,\"class\",\"inline-iframe\"],[11,\"frameborder\",\"0\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/apps/myfiles/login.hbs"
    }
  });

  _exports.default = _default;
});