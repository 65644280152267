define("megaportal-frontend-uq-components/metaTags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var metaTags = {
    "dashboard": {
      "title": 'Dashboard - my.UQ - University of Queensland',
      "description": 'Notifications and current information for you'
    },
    "apps.index": {
      "title": 'Apps - my.UQ - University of Queensland',
      "description": 'Links to most-used UQ applications'
    },
    "apps.myprofile": {
      "title": 'My Profile - my.UQ - University of Queensland',
      "description": 'Information about you and your UQ account'
    },
    "apps.my-requests": {
      "title": 'My Requests - my.UQ - University of Queensland',
      "description": 'Make online requests and view status of requests'
    },
    "apps.myfiles": {
      "title": 'My Files - my.UQ - University of Queensland',
      "description": 'Online access to files on your personal drive'
    },
    "default": {
      "title": 'my.UQ',
      "description": 'The new my.UQ, a better portal for students at the University of Queensland.'
    }
  };
  var _default = metaTags;
  _exports.default = _default;
});