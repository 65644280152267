define("megaportal-frontend-uq-components/components/ftp-client-move-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'ftp-client-move-modal',
    ftpService: Ember.inject.service(),
    actions: {
      close: function close() {
        this.ftpService.set('showMovePanel', false);
      },
      move: function move() {
        this.ftpService.move();
      }
    }
  });

  _exports.default = _default;
});