define("megaportal-frontend-uq-components/components/modal-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["isHidden:modal-message-overlay-hidden"],
    actions: {
      closeMessage: function closeMessage() {
        this.set('isHidden', true);
      }
    }
  });

  _exports.default = _default;
});