define("megaportal-frontend-uq-components/helpers/get-feature", ["exports", "megaportal-frontend-uq-components/features"], function (_exports, _features) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getFeature = getFeature;
  _exports.default = void 0;

  function getFeature(params) {
    if (!params[0]) {
      return '';
    } else {
      return _features.allFeatures[params[0]];
    }
  }

  var _default = Ember.Helper.helper(getFeature);

  _exports.default = _default;
});