define("megaportal-frontend-uq-components/controllers/apps/ims/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authManager: Ember.inject.service(),
    imsService: Ember.inject.service(),
    showLogoutModal: Ember.computed.alias('imsService.showLogoutModal'),
    changingSessionDuration: Ember.computed.alias('imsService.changingSessionDuration'),
    sessionDuration: Ember.computed.alias('imsService.sessionDuration')
  });

  _exports.default = _default;
});