define("megaportal-frontend-uq-components/components/browser-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service('-routing'),
    browserSupport: Ember.inject.service("browser-support-service"),
    currentRoute: Ember.computed('router.currentRouteName', function () {
      return this.router.currentRouteName;
    }),
    currentRouteChanged: Ember.observer('currentRoute', function () {
      // deal with the change
      console.log("currentRoute changed to: ".concat(this.get('currentRoute')));
    }),
    supportMessage: Ember.computed(function () {
      return this.browserSupport.displayOptions.Message;
    }),
    shouldDisplay: Ember.computed("currentRoute", function () {
      return this.browserSupport.enabled && this.isReleventRoute() && !this.isSupported();
    }),
    isSupported: function isSupported() {
      return !this.browserSupport.isInternetExplorer();
    },
    isReleventRoute: function isReleventRoute() {
      var _this = this;

      return this.browserSupport.relevantRoutes.some(function (targetRoute) {
        return _this.currentRoute.includes(targetRoute.Name);
      });
    }
  });

  _exports.default = _default;
});