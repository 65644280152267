define("megaportal-frontend-uq-components/controllers/apps/myfiles/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    ftpService: Ember.inject.service(),
    configService: Ember.inject.service(),
    jwt: Ember.computed('session', function () {
      var content = this.session.session.content;

      if (content && content.authenticated) {
        return content.authenticated.token;
      } else {
        return 'no_token';
      }
    }),
    url: Ember.computed('session', function () {
      return window.location.origin + '/api/myfiles/token';
    })
  });

  _exports.default = _default;
});