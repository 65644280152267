define("megaportal-frontend-uq-components/helpers/from-now-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fromNowTime = fromNowTime;
  _exports.default = void 0;

  function fromNowTime(params
  /*, hash*/
  ) {
    var date = params[0];
    var duration, hours, minutes, message;
    duration = (0, _moment.default)(date).diff((0, _moment.default)());
    hours = parseInt(duration / (1000 * 60 * 60) % 24);
    minutes = parseInt(duration / (1000 * 60) % 60);
    message = '';

    if (duration === 0 || duration < 0) {
      return message;
    }

    if (hours > 0) {
      message += hours + ' hr ';
    }

    if (minutes >= 0) {
      message += minutes + ' min ';
    }

    return Ember.String.htmlSafe('<span class="icon icon-clock icon--prefix"></span>' + message + 'away');
  }

  var _default = Ember.Helper.helper(fromNowTime);

  _exports.default = _default;
});