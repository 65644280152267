define("megaportal-frontend-uq-components/templates/components/calendar-view/mobile/event-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eLRWtsYi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"apps.timetable.timetable.event\",[25,[\"event\",\"classNumber\"]],[25,[\"event\",\"termCode\"]],[25,[\"event\",\"classDate\"]]],[[\"class\"],[\"event-block-link\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"event-block\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"event-block-info\"],[9],[0,\"\\n      \"],[1,[29,\"calendar-view/event-block-type\",null,[[\"event\"],[[25,[\"event\"]]]]],false],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"event-block-title\"],[9],[1,[25,[\"event\",\"courseCode\"]],false],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"event-block-timespan\"],[9],[1,[25,[\"event\",\"startTime\"]],false],[0,\" - \"],[1,[25,[\"event\",\"endTime\"]],false],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"event-block-location\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"event\",\"buildingName\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,[\"event\",\"buildingName\"]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"event\",\"buildingNumber\"]]],null,{\"statements\":[[0,\"            (\"],[1,[25,[\"event\",\"buildingNumber\"]],false],[0,\")\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,[\"event\",\"roomNumber\"]]],null,{\"statements\":[[0,\"            - Room \"],[1,[25,[\"event\",\"roomNumber\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"event-block-arrow\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"icon icon-chevron-right\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/calendar-view/mobile/event-block.hbs"
    }
  });

  _exports.default = _default;
});