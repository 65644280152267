define("megaportal-frontend-uq-components/templates/components/analytics-correlation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j5cOSaEa",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"one-chart-filters\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn-gpa-spread program left button tiny btn-selected\"],[9],[1,[23,\"primaryFilterFormatted\"],false],[0,\": \"],[1,[23,\"selectedCourseFormatted\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"one-chart-area\"],[9],[0,\"\\n            \"],[1,[29,\"google-charts\",null,[[\"chartType\",\"chartData\",\"chartOptions\"],[\"scatter\",[25,[\"model\",\"correlationData\"]],[25,[\"model\",\"correlationOptions\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/analytics-correlation.hbs"
    }
  });

  _exports.default = _default;
});