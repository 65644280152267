define("megaportal-frontend-uq-components/controllers/apps/student-analytics/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedCourse: null,
    currentCourses: Ember.computed('model.courses', function () {
      var courses = this.get('model.courses.enrolments');
      return courses.filterBy('is_current_term', true);
    }),
    allCourses: Ember.computed('model.courses', function () {
      return this.get('model.courses.enrolments');
    }),
    currentPrograms: Ember.computed('model.programs', function () {
      var programs = this.get('model.programs');
      return programs.filterBy('enrolment_status_code', 'AC');
    }),
    defaultCourseSetter: Ember.observer('model.courses', function () {
      //if the courses have updated, lets select a default, as well as on controller startup
      var currentCourses = this.currentCourses;

      if (currentCourses && Array.isArray(currentCourses) && currentCourses.length > 0) {
        this.set("selectedCourse", currentCourses[0]);
      }
    }),
    actions: {
      setSelectedCourse: function setSelectedCourse(course) {
        this.set('selectedCourse', course);
      }
    }
  });

  _exports.default = _default;
});