define("megaportal-frontend-uq-components/helpers/array-includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayIncludes = arrayIncludes;
  _exports.default = void 0;

  function arrayIncludes(params
  /*, hash*/
  ) {
    var sourceArray = params[0];
    var targetItem = params[1]; //console.log(sourceArray,targetItem);  

    var returnValue = sourceArray.contains(targetItem.toString());
    return returnValue;
  }

  var _default = Ember.Helper.helper(arrayIncludes);

  _exports.default = _default;
});