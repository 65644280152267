define("megaportal-frontend-uq-components/utils/safe-local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setSafeLocalStorage = setSafeLocalStorage;
  _exports.getSafeLocalStorage = getSafeLocalStorage;
  _exports.checkLocalStorage = checkLocalStorage;

  /**
   * Helper functions for devices that
   * run out of storage space or have
   * a corrupted localStorage.
   */
  function setSafeLocalStorage(key, value) {
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      console.error(e);
    }
  }

  function getSafeLocalStorage(key) {
    try {
      return window.localStorage.getItem(key);
    } catch (e) {
      console.error(e);
    }
  } // Return false if localStorage is unavailable


  function checkLocalStorage() {
    if (typeof window === 'undefined') {
      return false;
    }

    if (typeof window.localStorage === 'undefined') {
      return false;
    } // Attempt to write a key


    try {
      window.localStorage.setItem('localStorageTest', true);
      window.localStorage.removeItem('localStorageTest');
    } catch (e) {
      return false;
    }

    return true;
  }
});