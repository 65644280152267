define("megaportal-frontend-uq-components/authenticators/sso", ["exports", "jquery", "ember-simple-auth/authenticators/base", "megaportal-frontend-uq-components/config/environment"], function (_exports, _jquery, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var SSO = _base.default.extend({
    restore: function restore(data) {
      // setup ember fetch authorization bearer token
      _environment.default.JWT_TOKEN = data.token || null; // setup jQuery ajax authorization bearer token

      setupDefaultAjaxHeaders(data); // TODO: use a serverless endpoint in the future to test this.

      var authAjaxTest = _jquery.default.ajax({
        url: SSO.serverAuthAliveEndpoint,
        headers: {
          'Authorization': "Bearer " + data.token
        }
      });

      return new Ember.RSVP.Promise(function (resolve, reject) {
        authAjaxTest.then(function () {
          resolve(data);
        }, function () {
          reject(new Error('invalid token'));
        });
      });
    },
    authenticate: function authenticate() {
      var authAjaxTokenCall = _jquery.default.ajax({
        url: SSO.serverAuthEndpoint,
        contentType: 'application/json'
      });

      return new Ember.RSVP.Promise(function (resolve, reject) {
        authAjaxTokenCall.then(function (resp, status, xhr) {
          if (xhr.status === 401 || xhr.status === 403) {
            reject(new Error('invalid token'));
          } else {
            _environment.default.JWT_TOKEN = resp.token || null;
            setupDefaultAjaxHeaders(resp);
            resolve(resp);
          }
        }, function () {
          reject(new Error('no token'));
        });
      });
    },
    invalidate: function invalidate(token, options) {
      if (typeof Storage !== 'undefined' && options && options.reason) {
        sessionStorage.setItem('invalidated', options.reason);
      }

      return this._super.apply(this, arguments);
    }
  });

  SSO.reopenClass({
    serverAuthEndpoint: '/auth/session',
    serverAuthAliveEndpoint: '/api/hello',
    serverLoginEndpoint: '/auth/login',
    serverLogoutEndpoint: '/auth/logout',
    serverLoginLearnUq: '/api/sso/study',
    serverloginSinet: '/api/sso/sinet',
    serverloginEmail: '/api/sso/email'
  });
  var _default = SSO;
  _exports.default = _default;

  function setupDefaultAjaxHeaders(data) {
    _jquery.default.ajaxSetup({
      headers: {
        'Authorization': "Bearer " + data.token
      },
      //cut off all requests after 60 seconds
      timeout: 60000,
      data: {
        //random key associated with session to prevent cross-session caching
        'mp_rsess': Math.random().toString(36).substring(7)
      }
    });
  }
});