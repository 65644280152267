define("megaportal-frontend-uq-components/links", ["exports", "megaportal-frontend-uq-components/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UnauthenticatedLinks = _exports.StaffLinks = _exports.HDRStudentLinks = _exports.StudentLinks = _exports.Links = void 0;

  /**
   * Favourite links and defaults
   *
   * List of default favourites can be found on confluence:
   * https://confluence.its.uq.edu.au/confluence/pages/viewpage.action?spaceKey=SAR&title=Site%20and%20system%20links
   */
  // Load testing cookie - remove once finished with.
  var getCookie = function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  };

  var loadTestingEnabled = getCookie('myuq_load_testing').length > 0;
  var Links = {
    "events": {
      name: "Events",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://www.uq.edu.au/uq-events/",
      icon: "icon-events",
      isExternal: true
    },
    "notifications": {
      name: "Notifications",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "apps.notifications",
      icon: "icon-notifications",
      isExternal: false
    },
    "uq-maps": {
      name: "UQ Maps",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://maps.uq.edu.au/",
      icon: "icon-map-marker",
      isExternal: true
    },
    "library-loans": {
      name: "Library loans",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "apps.library",
      icon: "icon-library-loans",
      isExternal: false
    },
    "settings": {
      name: "Settings",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "apps.settings",
      icon: "icon-settings",
      isExternal: false
    },
    "learn-uq": {
      name: "Learn.UQ",
      isStudentDefault: true,
      isStaffDefault: true,
      url: "https://learn.uq.edu.au",
      icon: "icon-learn-uq",
      isExternal: true
    },
    "library": {
      name: "Library",
      isStudentDefault: true,
      isStaffDefault: false,
      url: "https://auth.library.uq.edu.au/login?return=aHR0cHM6Ly93d3cubGlicmFyeS51cS5lZHUuYXUv",
      icon: "icon-library",
      isExternal: true
    },
    "news": {
      name: "News",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://www.uq.edu.au/news/",
      icon: "icon-news",
      isExternal: true
    },
    "search": {
      name: "Search",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://www.uq.edu.au/search/",
      icon: "icon-search",
      isExternal: true
    },
    "contacts": {
      name: "Contacts",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://www.uq.edu.au/contacts/",
      icon: "icon-contacts",
      isExternal: true
    },
    "email-exo": {
      name: "Email",
      isStudentDefault: false,
      isStaffDefault: true,
      url: "https://outlook.office.com/mail/?realm=uq.edu.au",
      icon: "icon-email",
      isExternal: true
    },
    "email-el": {
      name: "Email",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://outlook.office.com/mail/?realm=uqconnect.edu.au",
      icon: "icon-email",
      isExternal: true
    },
    "email-migrating": {
      name: "Email Unavailable (Migrating)",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://digital-transformation.uq.edu.au/collaboration-technology/microsoft-365-students",
      icon: "icon-email",
      isExternal: true
    },
    "placements": {
      name: "Placements",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://placements.uq.edu.au/",
      icon: "icon-jobs",
      isExternal: true
    },
    "cahp": {
      name: "CAHP",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://cahp.uq.edu.au",
      icon: "icon-communities",
      isExternal: true
    },
    "expenseme-pro": {
      name: "ExpenseMe Pro",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://secure.inlogik.com/uq/",
      icon: "icon-usd",
      isExternal: true
    },
    "workday": {
      name: "Workday",
      isStudentDefault: false,
      isStaffDefault: true,
      url: "https://wd3.myworkday.com/uq/login.htmld",
      icon: "icon-aurion",
      isExternal: true
    },
    "myaurion": {
      name: "MyAurion",
      isStudentDefault: false,
      isStaffDefault: true,
      url: "https://myaurion.hr.uq.edu.au",
      icon: "icon-aurion",
      isExternal: true
    },
    "reportal": {
      name: "UQ Reportal",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://pbi.uq.edu.au/Content/Reportal.aspx",
      icon: "icon-bar-chart",
      isExternal: true
    },
    "unifi": {
      name: "UniFi",
      isStudentDefault: false,
      isStaffDefault: true,
      url: "https://www.unifi.uq.edu.au",
      icon: "icon-unifi",
      isExternal: true
    },
    "unitask": {
      name: "UniTask",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://unitask.uq.edu.au",
      icon: "icon-check-square-o",
      isExternal: true
    },
    "uq-jobs": {
      name: "UQ Careers",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://careers.uq.edu.au/",
      icon: "icon-briefcase",
      isExternal: true
    },
    "mysi-net": {
      name: "mySI-net",
      isStudentDefault: true,
      isStaffDefault: true,
      url: _environment.default.SINET_URL,
      icon: "icon-mysinet",
      isExternal: true
    },
    "mybalance-research-funds": {
      name: "MyBalance Research Funds",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://www.pbi.uq.edu.au/ClientServices/researchfunds/",
      icon: "icon-dollar",
      isExternal: true
    },
    "electronic-course-profiles": {
      name: "Electronic Course Profiles",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://archive.course-profiles.uq.edu.au/",
      icon: "icon-search",
      isExternal: true
    },
    "archibus": {
      name: "ARCHIBUS",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://archibus.uq.edu.au/",
      icon: "icon-desktop",
      isExternal: true
    },
    "academic-portal": {
      name: "Academic Portal",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://academicportal.app.uq.edu.au/",
      icon: "icon-desktop",
      isExternal: true
    },
    "academic-calendar": {
      name: "Academic Calendar",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://about.uq.edu.au/academic-calendar",
      icon: "icon-desktop",
      isExternal: true
    },
    "research-data-manager": {
      name: "Research Data Manager",
      isStudentDefault: true,
      isStaffDefault: false,
      url: "https://rdm.uq.edu.au/",
      icon: "icon-desktop",
      isExternal: true
    },
    "uq-espace": {
      name: "UQ eSpace",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://espace.library.uq.edu.au/",
      icon: "icon-desktop",
      isExternal: true
    },
    "uq-book-it": {
      name: "UQ Book It",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://sciauth-core.scientia.com/issue/oauth?response_type=token&client_id=UQ_53747202078492&redirect_uri=https%3A%2F%2Fuqbookit.uq.edu.au%2F&scope=50D3EB5E-6BD2-44F1-830C-F5725CAF7F49",
      icon: "icon-calendar",
      isExternal: true
    },
    "ims": {
      name: "Internet sessions",
      isStudentDefault: false,
      isStaffDefault: true,
      url: "apps.ims.view",
      icon: "icon-globe",
      isExternal: false
    },
    "systems-training": {
      name: "Systems Training Hub",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://systems-training.its.uq.edu.au/",
      icon: "icon-desktop",
      isExternal: true
    },
    "p3-activity-profile": {
      name: "P3 Activity Profile",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://pbi.uq.edu.au/iap",
      icon: "icon-desktop",
      isExternal: true
    },
    "office365": {
      name: "Microsoft 365",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://portal.office.com/",
      icon: "icon-th-large",
      isExternal: true
    },
    "allocate+": {
      name: "Allocate+",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://my.uq.edu.au/admin-timetable",
      icon: "icon-calendar",
      isExternal: true
    },
    "public-timetable": {
      name: "Public Timetable",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://my.uq.edu.au/public-timetable",
      icon: "icon-calendar",
      isExternal: true
    },
    "my-timetable": {
      name: "My Timetable",
      isStudentDefault: true,
      isStaffDefault: false,
      url: "https://my.uq.edu.au/student-timetable",
      icon: "icon-calendar",
      isExternal: true
    },
    "student-hub": {
      name: "StudentHub",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://studenthub.uq.edu.au/providers/saml/ssopostredirect/2?returnURL=%2f",
      icon: "icon-calendar",
      isExternal: true
    },
    "uqsafe": {
      name: "UQSafe",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://prod.riskcloud.net/?ccode=642",
      icon: "icon-calendar",
      isExternal: true
    },
    "icte-plus": {
      name: "ICTE Plus",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://plus.icte.uq.edu.au/",
      icon: "icon-plus",
      isExternal: true,
      roleDefault: 'ROLE_ICTESTUDENT',
      addedOn: 1576214949 //featureFlag: 'ictePlus'

    },
    "jac": {
      name: "Jac",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://curriculum.uq.edu.au/",
      icon: "icon-desktop",
      isExternal: true
    },
    "myresearch": {
      name: "MyResearch",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://my-research.research.uq.edu.au/",
      icon: "icon-desktop",
      isExternal: true
    },
    "syllabusplus": {
      name: "Syllabus Plus",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://splus.uq.edu.au/Scientia/Portal/Login.aspx",
      icon: "icon-desktop",
      isExternal: true
    },
    "timetabledatacollector": {
      name: "Timetable Data Collector",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://splus.uq.edu.au/timetabledatacollector/login.aspx",
      icon: "icon-desktop",
      isExternal: true
    },
    "labarchivessystem": {
      name: "Digital Research Notebooks",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://au-mynotebook.labarchives.com/login",
      icon: "icon-desktop",
      isExternal: true
    },
    "complaintsAndAppealsManagement": {
      name: "UQ Complaints and Appeals Management",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://uq-advocate.symplicity.com/",
      icon: "icon-desktop",
      isExternal: true
    },
    "courseReadingListsTalis": {
      name: "Course Reading Lists (Talis)",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://uq.rl.talis.com/index.html?lang=en&login=1",
      icon: "icon-desktop",
      isExternal: true
    },
    "procure": {
      name: "UQProcure",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://app02.jaggaer.com/apps/Router/SAMLAuth/UQ",
      icon: "icon-money",
      isExternal: true
    },
    "digitalWorkspace": {
      name: "UQ Digital Workspace",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://uqdigitalworkspace.vmwareidentity.com.au",
      icon: "icon-desktop",
      isExternal: true
    },
    "grcSystem": {
      name: "GRC System",
      isStudentDefault: false,
      isStaffDefault: false,
      url: "https://au.protechtgroup.com/uq/",
      icon: "icon-desktop",
      isExternal: true
    }
  };
  _exports.Links = Links;
  var StudentLinks = ["uq-maps", "events", "library-loans", "notifications", "settings", "learn-uq", "library", "news", "mysi-net", "placements", "search", "contacts", "uq-book-it", "ims", "public-timetable", "my-timetable", "academic-calendar", "student-hub", "uqsafe", "icte-plus", "digitalWorkspace"];
  _exports.StudentLinks = StudentLinks;
  var HDRStudentLinks = ["research-data-manager", "uq-espace"];
  _exports.HDRStudentLinks = HDRStudentLinks;
  var StaffLinks = ["uq-maps", "academic-calendar", "events", "library-loans", "notifications", "settings", "learn-uq", "library", "news", "mysi-net", "placements", "search", "contacts", "uq-jobs", "cahp", "expenseme-pro", "workday", "myaurion", "reportal", "unifi", "unitask", "electronic-course-profiles", "archibus", "academic-portal", "research-data-manager", "uq-espace", "uq-book-it", "mybalance-research-funds", "ims", "systems-training", "p3-activity-profile", "allocate+", "public-timetable", "uqsafe", "jac", "myresearch", "syllabusplus", "timetabledatacollector", "labarchivessystem", "complaintsAndAppealsManagement", "courseReadingListsTalis", "procure", "digitalWorkspace", "grcSystem"];
  _exports.StaffLinks = StaffLinks;
  var UnauthenticatedLinks = ["uq-maps", "events", "library-loans", "notifications", "settings", "learn-uq", "mysi-net", "search", "contacts", "academic-calendar"];
  _exports.UnauthenticatedLinks = UnauthenticatedLinks;
});