define("megaportal-frontend-uq-components/controllers/application", ["exports", "megaportal-frontend-uq-components/config/environment", "megaportal-frontend-uq-components/models/feature", "megaportal-frontend-uq-components/utils/safe-local-storage", "megaportal-frontend-uq-components/links"], function (_exports, _environment, _feature, _safeLocalStorage, _links) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    links: _links.Links,
    session: Ember.inject.service('session'),
    loading: Ember.computed.alias('notificationService.loading'),
    authManager: Ember.inject.service(),
    staffCoursesExist: Ember.computed.alias('authManager.staffCoursesExist'),
    notificationService: Ember.inject.service(),
    transitionHistoryService: Ember.inject.service(),
    featureService: Ember.inject.service(),
    favouriteService: Ember.inject.service(),
    sinetUrl: _environment.default.SINET_URL,
    router: Ember.inject.service(),
    assistantHidden: (0, _safeLocalStorage.getSafeLocalStorage)('ai-assistant-hidden') === "true",
    useNewOffice365Login: Ember.computed('model', function () {
      if (this.get("authManager.mailService") === "EL") {
        return true;
      } //Default, use old mechanism, should only be staff or unauthenticated users who get this, or those who didn't get caught by above rules


      return false;
    }),
    getCurrentRoute: Ember.observer('router.currentRouteName', function () {
      this.transitionHistoryService.addTransition(this.get('router').currentRouteName);
    }),
    currentRoute: Ember.computed('router.currentRouteName', function () {
      return this.get('router').currentRouteName;
    }),
    actions: {
      disableAIAssistant: function disableAIAssistant() {
        var _this = this;

        var feature = JSON.parse(JSON.stringify(this.get('featureService.availableFeatures').AIAssistant));
        feature.enabled = false;

        _feature.default.toggleUserFeature(feature).then(function () {
          _this.featureService.loadUserFeatures(feature.username);
        });
      },
      toggleAIAssistant: function toggleAIAssistant() {
        var hideAssistant = !this.assistantHidden;
        (0, _safeLocalStorage.setSafeLocalStorage)('ai-assistant-hidden', hideAssistant);
        this.set('assistantHidden', hideAssistant);
      }
    }
  });

  _exports.default = _default;
});