define("megaportal-frontend-uq-components/utils/authorized-fetch", ["exports", "fetch", "megaportal-frontend-uq-components/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = authorizedFetch;

  function authorizedFetch(url, options) {
    options = options || {};
    var token = _environment.default.JWT_TOKEN;

    if (token) {
      options.headers = options.headers || {};
      options.headers.Authorization = "Bearer ".concat(token);
    }

    return (0, _fetch.default)(url, options);
  }
});