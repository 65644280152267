define("megaportal-frontend-uq-components/components/calendar-view/dashboard-widget", ["exports", "megaportal-frontend-uq-components/templates/components/calendar-view/dashboard-widget", "megaportal-frontend-uq-components/components/calendar-view/constants", "moment"], function (_exports, _dashboardWidget, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dashboardWidget.default,
    timeGutterIndictators: _constants.timeGutterIndictators,
    dayTitles: _constants.dayTitles,
    viewTypes: _constants.viewTypes,
    timetableService: Ember.inject.service(),
    loading: true,
    hiddenIndicator: 0,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      // Get timetable
      this.timetableService.getTimetable((0, _moment.default)().format('YYYY-MM-DD'), 'day').then(function (timetablePayload) {
        // console.log('### didReceiveAttrs getTimetable payload: ', timetablePayload);
        _this.set('timetable', timetablePayload);

        _this.set('anyEvents', timetablePayload.currentDay.events && timetablePayload.currentDay.events > 0);

        _this.set('loading', false);
      }).catch(function (err) {
        // console.log('### didReceiveAttrs getTimetable payload ERR: ', err);
        console.log(err);

        _this.set('timetable', null);

        _this.set('timetableErr', {
          code: err.code
        });

        _this.set('loading', false);
      });
      return this._super.apply(this, arguments);
    },
    events: Ember.computed('timetable', function () {
      var timetable = this.timetable;

      if (!timetable) {
        return null;
      }

      var events = timetable.currentDay.events;
      return events;
    }),
    eventsPresent: Ember.computed('timetable', function () {
      var timetable = this.timetable;

      if (!timetable) {
        return false;
      }

      var events = timetable.currentDay.events;
      return events !== null;
    }),
    enrolledSemester: Ember.computed('timetable', function () {
      var timespan = this.get('timetable.timespan');
      var semesters = this.get('timetable.semesters'); // Get list of current enrolled semester titles (could be many)

      var currentEnrolledSemesters = semesters.filter(function (semester) {
        return semester.beginDate <= timespan.end && semester.endDate >= timespan.start && semester.hasCourses === true;
      }).map(function (semester) {
        return semester.title;
      });

      if (!currentEnrolledSemesters.length) {
        return false;
      } // Reduce list down to a single string


      return currentEnrolledSemesters.reduce(function (semesterA, semesterB) {
        return "".concat(semesterA, " / ").concat(semesterB);
      });
    }),
    currentSemester: Ember.computed('timetable', function () {
      var enrolledSemester = this.get('enrolledSemester');

      if (enrolledSemester) {
        return enrolledSemester;
      } // Find a current semester and return its/their title


      var timespan = this.get('timetable.timespan');
      var semesters = this.get('timetable.semesters');
      var currentSemester = semesters.filter(function (semester) {
        return semester.beginDate <= timespan.end && semester.endDate >= timespan.start;
      }).map(function (semester) {
        return semester.title;
      });

      if (!currentSemester.length) {
        return false;
      } // Reduce list down to a single string


      return currentSemester.reduce(function (semesterA, semesterB) {
        return "".concat(semesterA, " / ").concat(semesterB);
      });
    }),
    actions: {
      updateHiddenIndicator: function updateHiddenIndicator(value) {
        this.set('hiddenIndicator', value);
      }
    }
  });

  _exports.default = _default;
});