define("megaportal-frontend-uq-components/controllers/apps/events/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    hasLocation: Ember.computed.notEmpty('model.location')
  });

  _exports.default = _default;
});