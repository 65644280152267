define("megaportal-frontend-uq-components/controllers/apps/index", ["exports", "megaportal-frontend-uq-components/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    authManager: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    useNewOffice365Login: Ember.computed.alias('applicationController.useNewOffice365Login'),
    sinetUrl: _environment.default.SINET_URL,
    // sinetUrlContact: configSettings.SINET_URL + "?page=contact",
    sinetUrlContact: _environment.default.SINET_URL + "/psc/ps/EMPLOYEE/SA/c/NUI_FRAMEWORK.PT_LANDINGPAGE.GBL?targetid=contact",
    // sinetUrlAddress: configSettings.SINET_URL + "?page=address",
    sinetUrlAddress: _environment.default.SINET_URL + "/psc/ps/EMPLOYEE/SA/c/NUI_FRAMEWORK.PT_LANDINGPAGE.GBL?targetid=address",
    favouriteService: Ember.inject.service(),
    featureService: Ember.inject.service(),
    actions: {
      toggleFavourite: function toggleFavourite(key) {
        this.favouriteService.toggleFavourite(key);
      }
    }
  });

  _exports.default = _default;
});