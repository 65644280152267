define("megaportal-frontend-uq-components/utils/fastboot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isFastBoot = isFastBoot;

  /**
   * Helper functions for fastboot
   */
  function isFastBoot() {
    if (typeof window === 'undefined') {
      return true;
    }

    return false;
  }
});