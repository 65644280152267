define("megaportal-frontend-uq-components/templates/components/analytics-gpa-spread", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MJ9Gs2mb",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"is-equal\",[[25,[\"primary\"]],\"program\"],null]],null,{\"statements\":[[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"one-chart-filters\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn-gpa-spread program left button tiny btn-selected\"],[9],[1,[23,\"primaryFilterFormatted\"],false],[0,\": \"],[1,[23,\"selectedProgramFormatted\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"one-chart-area\"],[9],[0,\"\\n                \"],[1,[29,\"google-charts\",null,[[\"chartType\",\"chartData\",\"chartOptions\"],[\"bar\",[25,[\"model\",\"gpaProgramData\"]],[25,[\"model\",\"gpaProgramOptions\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[29,\"is-equal\",[[25,[\"primary\"]],\"course\"],null]],null,{\"statements\":[[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"one-chart-filters\"],[9],[0,\"\\n                \"],[7,\"button\"],[11,\"class\",\"btn-gpa-spread course left button tiny btn-selected\"],[9],[1,[23,\"primaryFilterFormatted\"],false],[0,\": \"],[1,[23,\"selectedCourseFormatted\"],false],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"one-chart-area\"],[9],[0,\"\\n                \"],[1,[29,\"google-charts\",null,[[\"chartType\",\"chartData\",\"chartOptions\"],[\"bar\",[25,[\"model\",\"gpaCourseData\"]],[25,[\"model\",\"gpaCourseOptions\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/analytics-gpa-spread.hbs"
    }
  });

  _exports.default = _default;
});