define("megaportal-frontend-uq-components/templates/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/E9PwLpx",
    "block": "{\"symbols\":[],\"statements\":[[0,\"You are being logged out.\\n\"],[7,\"div\"],[11,\"style\",\"display:none\"],[9],[0,\"\\n  \"],[1,[29,\"inline-iframe\",null,[[\"url\"],[\"/api/unitask/logout\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[23,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/logout.hbs"
    }
  });

  _exports.default = _default;
});