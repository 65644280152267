define("megaportal-frontend-uq-components/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqual = isEqual;
  _exports.default = void 0;

  function isEqual(params
  /*, hash*/
  ) {
    var leftSide = params[0];
    var rightSide = params[1];
    return leftSide === rightSide;
  }

  var _default = Ember.Helper.helper(isEqual);

  _exports.default = _default;
});