define("megaportal-frontend-uq-components/templates/apps/myfiles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tDcHAFQD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"apps-header\",null,[[\"iconClasses\"],[\"icon icon-folder-open-o icon--prefix\"]],{\"statements\":[[0,\"My Files\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"column large-12 xlarge-8\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"section\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"section__header\"],[9],[0,\"\\n        \"],[7,\"h1\"],[9],[0,\"My files\"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"section__content\"],[9],[0,\"\\n        \"],[1,[23,\"ftp-client\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/apps/myfiles/index.hbs"
    }
  });

  _exports.default = _default;
});