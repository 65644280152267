define("megaportal-frontend-uq-components/components/google-charts", ["exports", "jquery", "ember-resize/mixins/resize-aware"], function (_exports, _jquery, _resizeAware) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Generate Google Charts.
   *
   * All the usual data formats and options as described in the Google Charts documentation are supported.
   *
   * We have added a few new options:
   *      hAxis.viewWindow._cropLeft: boolean, default false. This forces the graph to crop the left hand side on smaller screens
   *      hAxis.viewWindow._pxPerRow: integer, to set the width of a visual element. Default 40 (i.e. 40px)
   */
  var GoogleCharts = Ember.Component.extend(_resizeAware.default, {
    componentChart: null,
    componentChartData: null,
    componentChartOptions: null,
    //TODO: better way to redraw chart....
    redrawChart: Ember.observer('chartData', function () {//console.log("redrawchart called",this.get("chartData"));
    }),
    willRender: function willRender() {
      var localChartData = this.chartData;

      if (!localChartData || _jquery.default.isEmptyObject(localChartData) || localChartData.length === 0) {
        // If there's no data, create a blank chart
        this.set('chartData', {
          columns: [{
            label: '',
            type: 'number'
          }, {
            label: '',
            type: 'number'
          }],
          rows: []
        });
      }
    },
    // Called after rendering this component
    didRender: function didRender() {
      var chartType, returnObject, localChartData;
      localChartData = this.chartData;

      if (localChartData && !_jquery.default.isEmptyObject(localChartData)) {
        // Fill the data holder
        this.set('componentChartData', localChartData); // Fill the options holder

        this.set('componentChartOptions', this.chartOptions || {}); // Fill the chartType, default is 'lineChart'

        chartType = this.chartType || 'line';
        chartType = chartType[0].toUpperCase() + chartType.substring(1) + 'Chart'; // Initialise Google charts

        google.charts.load('current', {
          packages: ['corechart']
        }); // Set the callback for when initialisation is done. Note that we bind 'this' to the callback to allow
        // access to the graph settings

        google.charts.setOnLoadCallback(function () {
          var data = this._initialiseGoogleChartsData(localChartData);

          this.set('componentChartOptions', this._cropHorizontalAxis(this.componentChartOptions)); // Generate the chart in the correct div

          if (data) {
            var iAm = this.elementId;
            var chartArea = (0, _jquery.default)('#' + iAm).find('.google-charts-area')[0];
            this.set('componentChart', new google.visualization[chartType](chartArea)); //because we are doing raw events to the dom, we need to tell equalizer to reflow once the graphs are drawn.

            google.visualization.events.addListener(this.componentChart, 'ready', function () {
              Ember.run(function () {
                if ((0, _jquery.default)(document).foundation) {
                  (0, _jquery.default)(document).foundation('equalizer', 'reflow');
                }
              });
            });
            this.componentChart.draw(data, this.componentChartOptions);
            returnObject = {
              chart: this.componentChart,
              data: this.componentChartData,
              options: this.componentChartOptions,
              type: chartType,
              el: this.element
            };
          } else {
            returnObject = 'Error in chartData: chartData must be an object or array. Both must be suitable for a Google Charts data table.';
          } // Trigger the chartDidRender action
          //this.sendAction('chartDidRender', returnObject);

        }.bind(this));
      }
    },
    // Called after a debounced window resize
    debouncedDidResize: function debouncedDidResize() {
      if (this.componentChart) {
        // Remake the data (as Google Charts doesn't keep it internally)
        var data = this._initialiseGoogleChartsData(this.componentChartData);

        if (data) {
          // Crop the chart data to fit the area size of the chart
          this.set('componentChartOptions', this._cropHorizontalAxis(this.componentChartOptions)); // Redraw the chart

          this.componentChart.draw(data, this.componentChartOptions);
        }
      }
    },

    /*
     * Internal method to crop the number data 'rows'. On smaller screens we want to remove the
     * data on the left hand side. By default we allow 40px for 1 'row' of data
     * In the chart options add properties
     *                  hAxis.viewWindow._pxPerRow
     *                  hAxis.viewWindow._cropLeft
     */
    _cropHorizontalAxis: function _cropHorizontalAxis(chartOptions) {
      var elWidth = (0, _jquery.default)(this.element).width(),
          numberRows,
          prettyNumberRows,
          startAt,
          widthPerRow; // Find the number of data rows

      if (_jquery.default.isPlainObject(this.chartData)) {
        if (!this.chartData.rows) {
          return chartOptions;
        } // Incorrect data format, so do nothing


        numberRows = this.chartData.rows.length;
      } else {
        numberRows = this.chartData.length - 1;
      } // Define the chart option defaults


      if (chartOptions.hAxis) {
        if (!chartOptions.hAxis.viewWindow) {
          chartOptions.hAxis.viewWindow = {};
        }
      } else {
        chartOptions.hAxis = {};
        chartOptions.hAxis.viewWindow = {};
      }

      chartOptions.hAxis.viewWindow._cropLeft = _jquery.default.type(chartOptions.hAxis.viewWindow._cropLeft) === 'boolean' ? chartOptions.hAxis.viewWindow._cropLeft : false;
      chartOptions.hAxis.viewWindow._pxPerRow = chartOptions.hAxis.viewWindow._pxPerRow || 40;
      chartOptions.hAxis.viewWindow.min = chartOptions.hAxis.viewWindow.min || 0; // Start cropping data on the left hand side

      if (chartOptions.hAxis.viewWindow._cropLeft) {
        // Look for _pxPerRow in the options
        if (chartOptions.hAxis.viewWindow._pxPerRow) {
          widthPerRow = Math.abs(Math.round(chartOptions.hAxis.viewWindow._pxPerRow));
        } // Define the optimal number of rows


        prettyNumberRows = Math.round(elWidth / widthPerRow); // Define the left hand side crop

        startAt = Math.max(0, numberRows - prettyNumberRows); // Update the options hash

        chartOptions.hAxis.viewWindow.min = startAt;
        chartOptions.hAxis.viewWindow._pxPerRow = widthPerRow;
      }

      return chartOptions;
    },

    /*
     * Internal method to initialise the Google Charts data based on the passed in data
     * Return the DataTable format, or false if the passed data doesn't appear suitable
     */
    _initialiseGoogleChartsData: function _initialiseGoogleChartsData(chartData) {
      // Expect the chartData to be an object suitable as a DataTable,
      // or an array that can be converted to a DataTable
      var data = false;

      if (_jquery.default.isPlainObject(chartData)) {
        // Expect the object to be like so:
        // { columns: [{...}, {...}], rows: [[...], [...]]}
        // i.e.: columns is an array of objects each with a column description
        //       rows is an array of arrays with the actual data values
        data = new google.visualization.DataTable(); // Add the columns

        if (chartData.columns) {
          for (var i = 0; i < chartData.columns.length; i++) {
            data.addColumn(chartData.columns[i]);
          }
        } // Add the rows


        if (chartData.rows) {
          data.addRows(chartData.rows);
        }
      } else if (_jquery.default.isArray(chartData)) {
        // Expect the array to be like so:
        // [[column descriptions], [data for row1], [data for row2], ...]
        // i.e. an array of arrays
        data = new google.visualization.arrayToDataTable(chartData);
      }

      return data;
    }
  });
  var _default = GoogleCharts;
  _exports.default = _default;
});