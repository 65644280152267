define("megaportal-frontend-uq-components/templates/components/browser-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aGDBH9rN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,0,[\"shouldDisplay\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"panel panel--alert-warning panel--browser-support\"],[11,\"role\",\"alertdialog\"],[11,\"aria-live\",\"assertive\"],[11,\"aria-labelledby\",\"alertDescriptionWarning\"],[11,\"aria-describedby\",\"alertDescriptionWarning\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"column small-12\"],[11,\"role\",\"document\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"panel__content\"],[11,\"id\",\"alertDescriptionWarning\"],[9],[0,\" \"],[1,[24,0,[\"supportMessage\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/components/browser-support.hbs"
    }
  });

  _exports.default = _default;
});