define("megaportal-frontend-uq-components/controllers/apps/settings/features-admin", ["exports", "megaportal-frontend-uq-components/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TEST_FEATURES_ADMIN_URL = 'https://its-ss-megaportal-test.aws.uq.edu.au/#/apps/settings/features-admin';
  var STAGE_FEATURES_ADMIN_URL = 'https://its-ss-megaportal-staging.aws.uq.edu.au/#/apps/settings/features-admin';
  var PROD_FEATURES_ADMIN_URL = 'https://portal.my.uq.edu.au/#/apps/settings/features-admin';

  var _default = Ember.Controller.extend({
    featureService: Ember.inject.service(),
    addUserInput: '',
    featureUsersToggle: {},
    searchingUsernames: {},
    // TODO: implement
    errorUsernames: {},
    // TODO: implement
    userStages: {
      test: _environment.default.STAGE === 'test',
      staging: _environment.default.STAGE === 'staging',
      prod: _environment.default.STAGE === 'prod'
    },
    actions: {
      switchEnvironment: function switchEnvironment(stage) {
        switch (stage) {
          case 'test':
            window.location = TEST_FEATURES_ADMIN_URL;
            return;

          case 'staging':
            window.location = STAGE_FEATURES_ADMIN_URL;
            return;

          case 'prod':
            window.location = PROD_FEATURES_ADMIN_URL;
            return;
        }
      },
      toggleFeatureUsers: function toggleFeatureUsers(featureId) {
        var toggle = this.featureUsersToggle[featureId] || false;
        this.set("featureUsersToggle.".concat(featureId), !toggle);
        this.target.send('fetchUsersOfFeature', featureId);
      },
      toggleFeatureUserCount: function toggleFeatureUserCount(featureId) {
        var toggle = this.featureUsersToggle[featureId] || false;
        this.set("featureUsersToggle.".concat(featureId), !toggle);
        this.target.send('loadFeatureUsersCount', featureId);
      },
      addUserInputChanged: function addUserInputChanged(event) {
        this.addUserInput = event.target.value;
      },
      addUser: function addUser(featureId) {
        var _this = this;

        this.featureService.addUserFeature(featureId, this.addUserInput).then(function () {
          _this.target.send('fetchUsersOfFeature', featureId);
        });
        var elements = document.querySelectorAll('[data-add-user-feature]');
        elements.forEach(function (element) {
          if (element.getAttribute('data-add-user-feature') === featureId) {
            element.reset();
          }
        });
        this.set('addUserInput', '');
      }
    }
  });

  _exports.default = _default;
});