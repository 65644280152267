define("megaportal-frontend-uq-components/components/application-wrapper", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    willInsertElement: function willInsertElement() {
      (0, _jquery.default)("#application-boot").remove();
    },
    didInsertElement: function didInsertElement() {
      if (typeof window.UQComponents !== 'undefined' && window.UQComponents instanceof Array) {
        window.UQComponents.forEach(function (fn) {
          fn();
        });
      }
    }
  });

  _exports.default = _default;
});