define("megaportal-frontend-uq-components/routes/apps/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    renderTemplate: function renderTemplate() {
      this.render('apps/index');
    }
  });

  _exports.default = _default;
});