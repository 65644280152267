define("megaportal-frontend-uq-components/templates/apps/library-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FnLI64jQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"apps-header\",null,[[\"iconClasses\"],[\"icon icon-library-loans icon--prefix\"]],{\"statements\":[[0,\"My library loans\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"large-12 xlarge-8 column\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"section\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"section__content\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"section__header\"],[9],[0,\"\\n          \"],[7,\"h1\"],[9],[0,\"My library loans\"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"prompt-message\",null,null,{\"statements\":[[0,\"          Library cannot be contacted at current time. Please try again soon.\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[1,[23,\"outlet\"],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "megaportal-frontend-uq-components/templates/apps/library-error.hbs"
    }
  });

  _exports.default = _default;
});