define("megaportal-frontend-uq-components/components/logout-dialog", ["exports", "jquery", "megaportal-frontend-uq-components/utils/cache-fetch"], function (_exports, _jquery, _cacheFetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    authManager: Ember.inject.service(),
    actions: {
      logoutPressed: function logoutPressed() {
        // Remove any fetch cache data
        (0, _cacheFetch.clearCache)(this.authManager.get('username'));
        this.session.invalidate({
          reason: 'logout'
        });
      },
      showLogoutDialog: function showLogoutDialog() {
        if ((0, _jquery.default)(document).foundation) {
          (0, _jquery.default)('#logoutDialogModal').foundation('reveal', 'open');
        }

        (0, _jquery.default)('body').addClass('modal-open');
      },
      closeLogoutDialog: function closeLogoutDialog() {
        if ((0, _jquery.default)(document).foundation) {
          (0, _jquery.default)('#logoutDialogModal').foundation('reveal', 'close');
        }

        (0, _jquery.default)('body').removeClass('modal-open');
      }
    }
  });

  _exports.default = _default;
});