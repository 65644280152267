define("megaportal-frontend-uq-components/models/course", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    //  course info
    courseCode: _emberData.default.attr('string'),
    courseTitle: _emberData.default.attr('string'),
    classNumber: _emberData.default.attr('number'),
    termCode: _emberData.default.attr('string'),
    details: _emberData.default.attr(),
    assessments: _emberData.default.attr(),
    readingLists: _emberData.default.attr(),
    pastExamPapers: _emberData.default.attr(),
    courseContributors: _emberData.default.attr()
  });

  _exports.default = _default;
});